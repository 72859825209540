import { Box, styled, TableCell } from '@mui/material';

export const ThreatUrlCell = styled(TableCell)({
  minWidth: '300px',
  '&:hover': {
    '& .action-buttons': {
      visibility: 'visible',
    },
  },
});

export const ActionButtons = styled(Box)({
  visibility: 'hidden',
  alignSelf: 'center',
  display: 'flex',
  gap: 1,
});
