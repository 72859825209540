import React, { ChangeEvent, useEffect, useState } from 'react';
import { Pagination, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import GoogleIcon from '@mui/icons-material/Google';
import {
  Button,
  DatePickers,
  GradientButton,
  Input,
  Loader,
} from '../../components';
import {
  defaultEndDateTakedown,
  defaultStartDateTakedown,
  disableAfterToday,
  isValidUrl,
} from '../../utils';
import GSBHistoryTable from './GSBHistoryTable';
import {
  GSBHistoryHeader,
  GSBHistoryTitle,
  GSBHistoryFilters,
  GSBHistoryGridContainer,
  GSBHistoryInput,
  GSBHistoryGridItem,
  PaginationStyle,
} from './Style/GSBHistory.style';
import { GSBHistoryPayload } from '../../helpers/types';
import { useGSBHistoryContext } from '../../context/GsbHistory/GsbHistoryContext';
import GSBModel from '../ThreadsDetails/GSBModel';

const GSBHistory = () => {
  const [startDateFilterValues, setStartDateFilterValues] = useState<string>(
    defaultStartDateTakedown,
  );
  const [endDateFilterValues, setEndDateFilterValues] = useState<string>(
    defaultEndDateTakedown,
  );
  const [gsbHistoryCurrentPage, setGsbHistoryCurrentPage] = useState<number>(1);
  const [searchThreatName, setSearchThreatName] = useState<string>('');
  const [openGsbThreatModal, setOpenGsbThreatModal] = useState<boolean>(false);

  const { getGSBHistory, gsbHistoryData, gsbLoading, gsbSubmitLoading } =
    useGSBHistoryContext();

  const pageSize = 50;
  const count = Math.ceil(gsbHistoryData?.total / pageSize);

  const handleGsbSearch = () => {
    setGsbHistoryCurrentPage(1);
    fetchGSBHistory();
  };

  useEffect(() => {
    fetchGSBHistory();
  }, [gsbHistoryCurrentPage]);

  const handleClearFilter = () => {
    setStartDateFilterValues(defaultStartDateTakedown);
    setEndDateFilterValues(defaultEndDateTakedown);
    setSearchThreatName('');
  };

  const fetchGSBHistory = () => {
    const params: GSBHistoryPayload = {
      start_date: startDateFilterValues,
      end_date: endDateFilterValues,
      page: gsbHistoryCurrentPage,
      size: pageSize,
    };

    if (!isNaN(+searchThreatName) && searchThreatName.trim() !== '') {
      params.threat_id = String(+searchThreatName);
    } else if (
      typeof searchThreatName === 'string' &&
      isValidUrl(searchThreatName)
    ) {
      params.url = searchThreatName;
    } else if (typeof searchThreatName === 'string') {
      params.threat_name = searchThreatName;
    }

    getGSBHistory(params);
  };

  const handleChangeGsbHistoryPage = (event: any, value: number) => {
    setGsbHistoryCurrentPage(value);
  };

  const handleChangeThreatName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchThreatName(e.target.value);
  };

  const onChangeDate = (
    value: (string | number | dayjs.Dayjs | Date | null | undefined)[],
  ) => {
    if (value) {
      const formattedStartDate = dayjs(value[0]).format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = dayjs(value[1]).format('YYYY-MM-DD HH:mm:ss');
      setStartDateFilterValues(formattedStartDate);
      setEndDateFilterValues(formattedEndDate);
    } else {
      setStartDateFilterValues('');
      setEndDateFilterValues('');
    }
  };

  const handleGsbThreatModal = () => {
    setOpenGsbThreatModal(true);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <GSBHistoryHeader>
        <GSBHistoryTitle variant="h6">GSB</GSBHistoryTitle>
      </GSBHistoryHeader>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <GSBHistoryFilters>
          <GSBHistoryGridContainer container>
            <GSBHistoryInput item xs={3}>
              <Input
                data-testid="gsb-search-input"
                size="small"
                sx={{ backgroundColor: '#FFFFFF' }}
                placeholder="Threat name or URL"
                onChange={handleChangeThreatName}
                value={searchThreatName}
              />
            </GSBHistoryInput>
            <GSBHistoryGridItem item xs={5}>
              <DatePickers
                size="small"
                onChange={onChangeDate}
                defaultStartDate={startDateFilterValues}
                defaultEndDate={endDateFilterValues}
                disableAfterToday={disableAfterToday}
              />
            </GSBHistoryGridItem>

            <GradientButton
              data-testid="gsb-search-button"
              variant="contained"
              sx={{ mx: 2 }}
              onClick={handleGsbSearch}>
              <SearchIcon />
              <span style={{ paddingLeft: '2px' }}>Search</span>
            </GradientButton>
            <Button
              data-testid="gsb-reset-filter-button"
              onClick={handleClearFilter}
              variant="outlined">
              Reset
            </Button>
          </GSBHistoryGridContainer>
        </GSBHistoryFilters>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => handleGsbThreatModal()}
            variant="contained"
            startIcon={<GoogleIcon />}
            disabled={gsbSubmitLoading}>
            {gsbSubmitLoading ? <Loader size={24} /> : 'Submit url to GSB'}
          </Button>
        </Box>
      </Box>

      <GSBHistoryTable />

      {gsbHistoryData?.total > pageSize && !gsbLoading && (
        <PaginationStyle>
          <Pagination
            data-testid="gsb-history-pagination"
            count={count}
            page={gsbHistoryCurrentPage}
            onChange={handleChangeGsbHistoryPage}
            color="primary"
          />
        </PaginationStyle>
      )}
      <GSBModel
        startDate={startDateFilterValues}
        endDate={endDateFilterValues}
        isSubmitForUrl={true}
        open={openGsbThreatModal}
        handleClose={() => {
          setOpenGsbThreatModal(false);
        }}
      />
    </Box>
  );
};

export default GSBHistory;
