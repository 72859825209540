import {
  SET_COMPANY_FILTER_VALUES,
  SET_DATE_PICKER_ENDDATE,
  SET_DATE_PICKER_STARTDATE,
  SET_EXCLUDE_COMPANY,
  SET_REVIEW_STATUS_FILTER_VALUES,
  SET_SERVICES_DATA,
  SET_SOURCES_DATA,
  SET_STATUS_FILTER_VALUES,
} from '../constant';

export const setServicesData = (values: { label: string }[]) => ({
  type: SET_SERVICES_DATA,
  payload: values,
});

export const setSourcesData = (values: { label: string; value: string }[]) => ({
  type: SET_SOURCES_DATA,
  payload: values,
});

export const setStatusFilterValues = (values: { label: string }[]) => ({
  type: SET_STATUS_FILTER_VALUES,
  payload: values,
});

export const setPickerStartDate = (values: string) => ({
  type: SET_DATE_PICKER_STARTDATE,
  payload: values,
});

export const setPickerEndDate = (values: string) => ({
  type: SET_DATE_PICKER_ENDDATE,
  payload: values,
});

export const setCompanyFilter = (values: string[]) => ({
  type: SET_COMPANY_FILTER_VALUES,
  payload: values,
});

export const setExcludeCompany = (values: string[]) => ({
  type: SET_EXCLUDE_COMPANY,
  payload: values,
});

export const setReviewStatusFilterValues = (values: { label: string }[]) => ({
  type: SET_REVIEW_STATUS_FILTER_VALUES,
  payload: values,
});
