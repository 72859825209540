import {
  CHANGE_BULK_STATUS_BEGIN,
  CHANGE_STATUSLABEL_BEGIN,
  CREATE_THREAT_BEGIN,
  GET_DOMAINS_LIST_BEGIN,
  GET_STATUS_BEGIN,
  GET_THREAT_BEGIN,
  GET_THREAT_DETAIL_BEGIN,
  RESET_THREAT_LIST,
  SET_PAGE_SIZE_PERSIST_VALUES,
  TOGGLE_INFINITE_SCROLL,
} from '../constant';
import {
  GetStatusActionType,
  GetThreatActionType,
  changeStatusLabelType,
  GetThreatActionTypeDetail,
  CreateThreatActionType,
} from '../../helpers/types';

export const getThreatAction = (payload: GetThreatActionType) => ({
  type: GET_THREAT_BEGIN,
  payload,
});

export const getThreatActionDetail = (payload: GetThreatActionTypeDetail) => ({
  type: GET_THREAT_DETAIL_BEGIN,
  payload,
});

export const changeStatusLabelAction = (payload: changeStatusLabelType) => ({
  type: CHANGE_STATUSLABEL_BEGIN,
  payload,
});

type BulkStatusPayload = {
  items: Array<{
    threat_name: string;
    threatId: number;
    status: string;
    user_id: string;
    company_id: number;
    process: string;
    priority: number;
    protected_asset_id: number;
    domain: string;
    currentStatus: string;
  }>;
};

export const changeBulkStatusAction = (payload: BulkStatusPayload) => ({
  type: CHANGE_BULK_STATUS_BEGIN,
  payload,
});

export const createThreatAction = (payload: CreateThreatActionType) => ({
  type: CREATE_THREAT_BEGIN,
  payload,
});

export const getDomainsListAction = (payload: string) => ({
  type: GET_DOMAINS_LIST_BEGIN,
  payload,
});

export const resetThreatAction = () => ({
  type: RESET_THREAT_LIST,
});

export const getStatusAction = (payload?: GetStatusActionType) => ({
  type: GET_STATUS_BEGIN,
  payload,
});

export const setPageSize = (pageSize: any) => ({
  type: SET_PAGE_SIZE_PERSIST_VALUES,
  payload: pageSize,
});

export const toggleInfiniteScroll = (isInfinite: boolean) => ({
  type: TOGGLE_INFINITE_SCROLL,
  payload: isInfinite,
});
