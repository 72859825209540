import React, { useMemo, useState } from 'react';
import {
  Typography,
  IconButton,
  Toolbar,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Drawer,
  List,
  Badge,
  Card,
} from '@mui/material';
import {
  AccountCircle,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import CircleIcon from '@mui/icons-material/Circle';
import { 
  changeBulkStatusAction
} from '../../store/threats/action';
import { clearAllNotifications } from '../../store/notification/action';
import {
  clearAllOperations
} from '../../store/bulkOperations/slice';
import Wheel2Logo from '../../assets/Images/Wheel2Logo.png';
import {
  AppBar,
  HeaderContainer,
  HeaderWrapper,
  StatusContainer,
  StatusDot,
  StatusText,
  CustomCardContent,
} from './Style/Header.style';
import {
  HeaderProps,
  Notification,
  NotificationsState,
} from '../../helpers/types';
import { formatedDate, removeExpiredNotifications } from '../../utils';
import Button from '../Button/Button';
import { useAuth0 } from '../../__mocks__/@auth0/auth0-react';


const Header = ({ open }: HeaderProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [notificationsDrawerOpen, setNotificationsDrawerOpen] = useState(false);
  const [pendingOperationsDrawerOpen, setPendingOperationsDrawerOpen] = useState(false);
  const notifications = useSelector(
    (state: { notification: NotificationsState }) => state.notification.data,
  );
  const pendingOperations = useSelector((state: any) => state.bulkOperations.pendingOperations);
  const pendingCount = Object.keys(pendingOperations).length;
  const { logout, user } = useAuth0();
  const { userData } = useSelector((state: any) => state.user);

  const notificationFilterData = useMemo(() => {
    return removeExpiredNotifications(notifications);
  }, [notifications]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => {
    localStorage.clear();
    setAnchorElUser(null);
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const toggleNotificationsDrawer = (open: boolean) => setNotificationsDrawerOpen(open);
  const dispatch = useDispatch();
  const togglePendingOperationsDrawer = (open: boolean) => setPendingOperationsDrawerOpen(open);

  const renderNotifications = () => (
    <List>
      {notificationFilterData?.map(
        (notification: Notification, index: number) => {
          const { thread_name, status, userId, date, errorMessage, isError } =
            notification;
          return (
            <Card
              variant="outlined"
              key={index}
              sx={{
                marginBottom: 1,
                marginTop: 1,
                padding: 0,
                borderColor: isError ? 'red' : '#ccc',
              }}>
              <CustomCardContent>
                <Typography variant="body2" color="text.primary">
                  {isError ? (
                    <>
                      <strong>Error with {thread_name} operation:</strong>
                      {errorMessage}
                    </>
                  ) : (
                    <>
                      <strong>{thread_name}</strong> changed status to{' '}
                      <strong>{status}</strong>
                    </>
                  )}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 1,
                  }}>
                  <Typography variant="caption" color="text.secondary">
                    by {userId}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {formatedDate(new Date(date))} UTC
                  </Typography>
                </Box>
              </CustomCardContent>
            </Card>
          );
        },
      )}

      {notificationFilterData?.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 1,
          }}>
          <Typography variant="caption" color="text.secondary">
            No data found
          </Typography>
          
        </Box>
      )}
    </List>
  );

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <HeaderContainer>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <img src={Wheel2Logo} alt="wheel2" style={{ height: 30 }} />
            {pendingCount > 0 && (
              <Tooltip title="Click to see pending operations">
                <StatusContainer 
                  onClick={() => togglePendingOperationsDrawer(true)}
                  sx={{ cursor: 'pointer' }}
                >
                  <CircleIcon sx={{ color: '#18CDCD', width: '8px', height: '8px', marginRight: '4px' }} />
                  <StatusText>{pendingCount} pending</StatusText>
                </StatusContainer>
              </Tooltip>
            )}
          </Box>
          <Box sx={HeaderWrapper}>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <Tooltip title="Server connection established. Live data is streaming and threats on the screen are updated in real time.">
                <StatusContainer aria-label="Live data streaming. Server connection established.">
                  <StatusDot />
                  <StatusText>Connected</StatusText>
                </StatusContainer>
              </Tooltip>
            </Box>
            <Typography sx={{ ml: 2, fontSize: '14px' }}>
              {user?.name}
            </Typography>
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              <IconButton
                size="large"
                data-testid="account-button"
                edge="end"
                onClick={handleOpenUserMenu}
                color="inherit">
                <AccountCircle />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted>
                <MenuItem>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
              <IconButton
                size="large"
                data-testid="notifications-button"
                onClick={() => toggleNotificationsDrawer(true)}
                color="inherit">
                <Badge
                  badgeContent={notificationFilterData?.length}
                  color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Box>
          </Box>
        </HeaderContainer>
      </Toolbar>
      <Drawer
        anchor="right"
        open={notificationsDrawerOpen}
        onClose={() => toggleNotificationsDrawer(false)}
        variant="temporary"
        sx={{ zIndex: 1300 }}
        PaperProps={{
          sx: { boxShadow: 3 },
        }}>
        <Box sx={{ 
          padding: '10px 15px',
          borderBottom: '1px solid #ccc',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography
            sx={{
              fontSize: 14,
            }}
            gutterBottom>
            Latest 50 threat status updates
          </Typography>
          {notificationFilterData?.length > 0 && (
            <Button
              variant="text"
              size="small"
              onClick={() => dispatch(clearAllNotifications())}
              color="primary"
            >
              Clear All
            </Button>
          )}
        </Box>
        <Box sx={{ width: 400, padding: '0 12px' }} role="presentation">
          {renderNotifications()}
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={pendingOperationsDrawerOpen}
        onClose={() => togglePendingOperationsDrawer(false)}
        variant="temporary"
        sx={{ zIndex: 1300 }}
        PaperProps={{
          sx: { boxShadow: 3 },
        }}>
        <Box sx={{ 
          padding: '10px 15px',
          borderBottom: '1px solid #ccc',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography
            sx={{
              fontSize: 14,
            }}
            gutterBottom>
            Pending Operations
          </Typography>
          {Object.keys(pendingOperations).length > 0 && (
            <Tooltip title="Clearing pending operations will clean up the list and stop any further retries. Operations queued in the server could still get through.">
              <Button
                variant="text"
                size="small"
                onClick={() => dispatch(clearAllOperations())}
                color="primary"
              >
                Clear All
              </Button>
            </Tooltip>
          )}
        </Box>
        <Box sx={{ width: 400, padding: '0 12px' }} role="presentation">
          <List>
            <Box sx={{ mb: 2 }}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="subtitle2" color="text.secondary">
                  Failed Operations ({Object.values(pendingOperations).filter((op: any) => op.hasError).length})
                </Typography>
              </Box>
              {Object.values(pendingOperations)
                .filter((op: any) => op.hasError)
                .map((operation: any, index: number) => (
                <Card
                  variant="outlined"
                  key={index}
                  sx={{
                    marginBottom: 1,
                    marginTop: 1,
                    padding: 0,
                    borderColor: 'red',
                    backgroundColor: 'rgba(255, 0, 0, 0.05)',
                  }}>
                  <CustomCardContent>
                    <Box>
                      <Typography variant="body2" color="error">
                        <strong>{operation.threatName}</strong>
                      </Typography>
                      <Typography variant="body2" color="error.light" sx={{ mt: 0.5 }}>
                        {operation.errorMessage}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                      }}>
                      <Typography variant="caption" color="text.secondary">
                        Started at {formatedDate(new Date(operation.startedAt))}
                      </Typography>
                    </Box>
                  </CustomCardContent>
                </Card>
              ))}
            </Box>

            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
              In Progress ({Object.values(pendingOperations).filter((op: any) => !op.hasError).length})
            </Typography>
            {Object.values(pendingOperations)
              .filter((op: any) => !op.hasError)
              .map((operation: any, index: number) => (
              <Card
                variant="outlined"
                key={index}
                sx={{
                  marginBottom: 1,
                  marginTop: 1,
                  padding: 0,
                  borderColor: '#1976d2',
                  backgroundColor: 'rgba(25, 118, 210, 0.05)',
                }}>
                <CustomCardContent>
                  <Box>
                    <Typography variant="body2" color="primary">
                      <strong>{operation.threatName}</strong>
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                      Changing status to <strong>{operation.expectedStatus}</strong>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 1,
                    }}>
                    <Typography variant="caption" color="text.secondary">
                      Started at {new Date(operation.startedAt).toLocaleString()}
                    </Typography>
                  </Box>
                </CustomCardContent>
              </Card>
            ))}

            {Object.keys(pendingOperations).length === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 1,
                }}>
                <Typography variant="caption" color="text.secondary">
                  No pending operations
                </Typography>
              </Box>
            )}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Header;
