import React from 'react';
import { Box } from '@mui/material';
import { DatePickerProps } from '../../helpers/types';
import { formatDate } from '../../utils';
import { StyledDateRangePicker } from './Style/DatePickers.style';

const DatePickers = ({
  onChange,
  defaultStartDate,
  defaultEndDate,
  disableAfterToday,
}: DatePickerProps) => {
  const startDate: any = defaultStartDate ? new Date(defaultStartDate) : null;
  const endDate: any = defaultEndDate ? new Date(defaultEndDate) : null;

  const handleDateChange = (value: any) => {
    if (value && value.length === 2) {
      const [start, end] = value;

      const formattedStartDate = formatDate(start);
      const formattedEndDate = formatDate(end);

      onChange([formattedStartDate, formattedEndDate]);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StyledDateRangePicker
        data-testid="date-range-picker"
        placeholder="YYYY-MM-DD HH:mm:ss"
        placement='bottomEnd'
        onChange={handleDateChange}
        defaultValue={[startDate, endDate]}
        format="yyyy-MM-dd HH:mm:ss"
        value={[startDate, endDate]}
        shouldDisableDate={disableAfterToday}
        size="lg"
        cleanable={false}
      />
    </Box>
  );
};

export default DatePickers;
