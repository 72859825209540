import {
  defaultReviewStatusFilter,
  defaultServiceFilter,
  defaultSourcesFilter,
  defaultStatusFilter,
} from '../../utils';
import {
  SET_COMPANY_FILTER_VALUES,
  SET_DATE_PICKER_ENDDATE,
  SET_DATE_PICKER_PERSIST_VALUES,
  SET_DATE_PICKER_STARTDATE,
  SET_EXCLUDE_COMPANY,
  SET_REVIEW_STATUS_FILTER_VALUES,
  SET_SERVICES_DATA,
  SET_SOURCES_DATA,
  SET_STATUS_FILTER_VALUES,
} from '../constant';

const initialState = {
  companysExclude: [],
  getPickerStartDate: [],
  companyFilterData: [],
  getPickerEndDate: [],
  statusFilterData: defaultStatusFilter,
  getServicesData: defaultServiceFilter,
  getSourcesData: defaultSourcesFilter,
  reviewStatusFilterData: defaultReviewStatusFilter,
  datePickerPersistValues: { startDate: '', endDate: '' },
};

export const filterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_STATUS_FILTER_VALUES:
      return {
        ...state,
        statusFilterData: action.payload,
      };
    case SET_SERVICES_DATA:
      return {
        ...state,
        getServicesData: action.payload,
      };
    case SET_SOURCES_DATA:
      return {
        ...state,
        getSourcesData: action.payload,
      };
    case SET_DATE_PICKER_STARTDATE:
      return {
        ...state,
        getPickerStartDate: action.payload,
      };
    case SET_DATE_PICKER_ENDDATE:
      return {
        ...state,
        getPickerEndDate: action.payload,
      };
    case SET_COMPANY_FILTER_VALUES:
      return {
        ...state,
        companyFilterData: action.payload,
      };
    case SET_DATE_PICKER_PERSIST_VALUES:
      return {
        ...state,
        datePickerPersistValues: action.payload,
      };
    case SET_EXCLUDE_COMPANY:
      return {
        ...state,
        companysExclude: action.payload,
      };
    case SET_REVIEW_STATUS_FILTER_VALUES:
      return {
        ...state,
        reviewStatusFilterData: action.payload,
      };
    default:
      return state;
  }
};
