import React, { useEffect, useMemo, useState } from 'react';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { Button } from '../../components';
import CompleteTakedownModal from '../ThreadsDetails/CompleteTakedownModal';
import {
  RootState,
  StatusTakedown,
  TakedownTableType,
} from '../../helpers/types';
import { completeTakedownAction } from '../../store/takedown/action';
import { LoaderWrapper, NoDataMessage, TakeDownTypes } from '../../utils';
import { CustomTableCell } from './Style/TakedownList.style';
import { getTakedownStatusApi } from '../../store/takedown';
import { ClickableUrl } from './Style/TakedownTable.style';
import './Style/TakedownTable.css';

const TakedownTable = ({
  takeDownCurrentPage,
  startDateFilterValues,
  endDateFilterValues,
  selectedTakedownTypes,
}: TakedownTableType) => {
  const [openCompleteTakedown, setOpenCompleteTakedown] = useState(false);
  const [takedownMessage, setTakedownMessage] = useState<string>('');
  const [getTakedownId, setGetTakedownId] = useState<string>('');
  const [selectedStatusTakedown, setSelectedStatusTakedown] =
    useState<string>('');
  const [selectedCancellationStatus, setSelectedCancellationStatus] =
    useState<string>();
  const [takedownPage, setTakedownPage] = useState<boolean>(true);

  const [statusList, setStatusList] = useState<any>('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { takedownList, getStatustakeDown } = useSelector(
    (state: RootState) => state.takedown,
  );
  const { getCompany } = useSelector((state: any) => state.company);
  const takeDownItems = takedownList?.data?.items;

  const formatDate = (timestamp: string | number | Date) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const findStatusNameById = (id: number) => {
    const status = statusList?.data?.find(
      (status: { id: number }) => status?.id === id,
    );
    return status ? status.name : 'Unknown';
  };

  useEffect(() => {
    const takeDownStatusList = async () => {
      const response = await getTakedownStatusApi();
      setStatusList(response);
    };
    takeDownStatusList();
  }, []);

  const handleCloseCompleteTakedown = () => {
    setOpenCompleteTakedown(false);
  };

  const handleChangeStatusTakedown = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedStatusTakedown(e.target.value);
  };

  const handleChangeCancellationReason = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedCancellationStatus(e.target.value);
  };

  const statusTakedownData = useMemo(
    () =>
      getStatustakeDown?.data?.map((item: StatusTakedown) => ({
        label: item.name,
        value: item.name,
      })) || [],
    [getStatustakeDown?.data],
  );

  const handleCompleteTakedown = () => {
    dispatch(
      completeTakedownAction({
        id: getTakedownId,
        description: takedownMessage,
        status_name: selectedStatusTakedown,
        since: startDateFilterValues, // for get Api
        until: endDateFilterValues,
        takedownPage,
        takedown_type: selectedTakedownTypes,
        ...(selectedStatusTakedown === 'cancelled' && {
          cancellation_reason_id: selectedCancellationStatus,
        }),
      }),
    );
    setTakedownMessage('');
    setOpenCompleteTakedown(false);
  };

  const handleOpenCompleteTakedown = (takeDownId: string) => {
    setGetTakedownId(takeDownId);
    setOpenCompleteTakedown(true);
  };

  const findCompanyNameById = (id: number) => {
    const company = getCompany?.data?.find((company: any) => company.Id === id);
    return company ? company.Name : 'Unknown';
  };

  const redirectToHistoryPage = (takeDownId: string) => {
    navigate(`/takedown/history/${takeDownId}`);
  };

  const copyTakedownUrl = (url: string) => {
    try {
      navigator.clipboard.writeText(url);
    } catch (error) {
      console.error('Failed to copy url: ', error);
    }
  };

  const openTakedownUrl = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <TableContainer 
        data-testid="takedowns-table-container"
        component={Paper} 
        sx={{ border: '1px solid lightgray' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <CustomTableCell>URL</CustomTableCell>
              {selectedTakedownTypes !== TakeDownTypes.socialMedia && (
                <CustomTableCell align="left">Target</CustomTableCell>
              )}
              {selectedTakedownTypes !== TakeDownTypes.externalThreatDomain && (
                <CustomTableCell align="left">Type Of Threat</CustomTableCell>
              )}
              <CustomTableCell align="left">Company</CustomTableCell>
              <CustomTableCell align="left">Comment</CustomTableCell>
              {selectedTakedownTypes !== TakeDownTypes.externalThreatDomain && (
                <CustomTableCell align="left">Platform</CustomTableCell>
              )}
              <CustomTableCell align="left">Description</CustomTableCell>
              <CustomTableCell align="left">User</CustomTableCell>
              <CustomTableCell align="left">Requested</CustomTableCell>
              <CustomTableCell align="left">Updated at</CustomTableCell>
              <CustomTableCell align="left">Status</CustomTableCell>
              <CustomTableCell align="left">Action</CustomTableCell>
            </TableRow>
          </TableHead>
          {takedownList?.loading ? (
            <LoaderWrapper size={25} />
          ) : takedownList?.data?.length ||
            takedownList?.data?.items?.length === 0 ? (
            <NoDataMessage message="No Takedown Data" />
          ) : (
            <TableBody>
              {takeDownItems?.map((takeDown: any) => (
                <TableRow
                  key={takeDown?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className='takedown-url'>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      minWidth={400}>
                      {/* Clickable URL */}
                      <ClickableUrl
                        role="button"
                        tabIndex={0}
                        onClick={() => redirectToHistoryPage(takeDown.id)}
                        onKeyDown={e => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            redirectToHistoryPage(takeDown.id);
                          }
                        }}>
                        {takeDown?.url}
                      </ClickableUrl>

                      {/* Action Buttons (Visible on Hover) */}
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          visibility: 'hidden', // Initially hidden
                        }}
                        className="action-buttons">
                        <IconButton
                          onClick={() => copyTakedownUrl(takeDown?.url)}
                          size="small"
                          sx={{ padding: 0 }}>
                          <ContentCopyIcon sx={{ height: 20, width: 20 }} />
                        </IconButton>
                        {takeDown.takedown_type ===
                          TakeDownTypes.socialMedia && (
                          <IconButton
                            onClick={() => openTakedownUrl(takeDown?.url)}
                            size="small"
                            sx={{ padding: 0 }}>
                            <OpenInNewIcon sx={{ height: 20, width: 20 }} />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </TableCell>

                  {/* Other Table Cells */}
                  <TableCell align="left">{takeDown?.target}</TableCell>
                  <TableCell align="left">
                    {takeDown?.threattype_id || '-'}
                  </TableCell>
                  <TableCell align="left">
                    {findCompanyNameById(takeDown?.company_id)}
                  </TableCell>
                  <TableCell align="left">
                    {takeDown?.cancellation_reason || '-'}
                  </TableCell>
                  <TableCell align="left">
                    {takeDown?.platform_id || '-'}
                  </TableCell>
                  <TableCell align="left">
                    <Tooltip title={takeDown?.description || '-'}>
                      <span>
                        {takeDown?.description?.length > 30
                          ? `${takeDown.description.slice(0, 30)}...`
                          : takeDown?.description || '-'}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">{takeDown?.user_id}</TableCell>
                  <TableCell align="left">
                    {formatDate(takeDown?.created)}
                  </TableCell>
                  <TableCell align="left">
                    {formatDate(takeDown?.updated_at)}
                  </TableCell>
                  <TableCell align="left">
                    {findStatusNameById(takeDown?.status_id)}
                  </TableCell>
                  <TableCell align="left">
                    {takeDown?.status_id !== 8 && (
                      <Button
                        data-testid={`change-status-button-${takeDown.id}`}
                        variant="contained"
                        size="small"
                        onClick={() => handleOpenCompleteTakedown(takeDown.id)}
                        sx={{ color: '#FFFFFF' }}>
                        Change Status
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <CompleteTakedownModal
        handleClose={handleCloseCompleteTakedown}
        openCompleteTakedown={openCompleteTakedown}
        handleCompleteTakedown={handleCompleteTakedown}
        takedownMessage={takedownMessage}
        setTakedownMessage={setTakedownMessage}
        handleChangeStatusTakedown={handleChangeStatusTakedown}
        statusTakedownData={statusTakedownData}
        selectedStatusTakedown={selectedStatusTakedown}
        selectedCancellationStatus={selectedCancellationStatus}
        handleChangeCancellationReason={handleChangeCancellationReason}
      />
    </>
  );
};

export default TakedownTable;
