import React, { useEffect, useState } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Pagination,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  HorizontalLine,
  StyledHeader,
  TableHeaderCell,
  TakedownTable,
} from '../ThreadsDetails/Style/SingleThreat.style';
import {
  formatedDate,
  getFormattedDescription,
  GSBHistoryHeader,
} from '../../utils';
import { getStatusTakedown } from '../../store/takedown/action';
import { PaginationStyle } from './Style/GSBHistory.style';
import { useGSBHistoryContext } from '../../context/GsbHistory/GsbHistoryContext';
import { GSBHistoryItemType } from '../../context/GsbHistory/GsbHistoryType';
import { CardWrapper } from '../../components/Wrapper/Wrapper.style';
import { Loader } from '../../components';
import { NoRecordFound } from '../../components/NoRecordFound/NoRecordFound';
import Title from '../../components/Title/Title';

const GSBStatusDetails = (threatDetail: any) => {
  const dispatch = useDispatch();

  const [gsbHistoryCurrentPage, setGsbHistoryCurrentPage] = useState<number>(1);
  const ThreadsDetails = threatDetail?.threatDetail;

  const { getGSBHistory, gsbHistoryData, gsbLoading } =
  useGSBHistoryContext();

  const totalItems = gsbHistoryData?.total || 0;
  const pageSize = 10;
  const count = Math.ceil(totalItems / pageSize);

  useEffect(() => {
    dispatch(getStatusTakedown());
    if (ThreadsDetails?.threat_id && ThreadsDetails?.threat_name) {
      fetchGSBHistory();
    }
  }, [dispatch, gsbHistoryCurrentPage]);

  const fetchGSBHistory = () => {
    const params: any = {
      page: gsbHistoryCurrentPage,
      size: pageSize,
      threat_name: ThreadsDetails?.threat_name,
    };
    getGSBHistory(params);
  };

  const handleChangeGsbHistoryPage = (event: any, value: number) => {
    setGsbHistoryCurrentPage(value);
  };

  const renderTableContent = () => {
    if (gsbLoading) {
      // Loading state: show loader
      return (
        <TableRow>
          <TableCell colSpan={GSBHistoryHeader.length} align="center" sx={{ border: 0 }}>
            <CardWrapper>
              <Loader size={20} />
            </CardWrapper>
          </TableCell>
        </TableRow>
      );
    }
    if (!gsbHistoryData || gsbHistoryData?.items?.length === 0) {
      // No records found: show NoRecordFound component
      return (
        <TableRow>
          <TableCell colSpan={GSBHistoryHeader.length} align="center" sx={{ border: 0 }}>
            <NoRecordFound />
          </TableCell>
        </TableRow>
      );
    }
    // Data available: render rows for each item
    return gsbHistoryData?.items?.map((item: GSBHistoryItemType, index: number) => (
      <TableRow
        key={item?.id}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          height: '50px',
        }}
      >
        <TableCell align="left">{item?.id}</TableCell>
        <TableCell align="left">{item?.domain_id}</TableCell>
        <TableCell align="left">{item?.url}</TableCell>
        <TableCell align="left">{item?.platform}</TableCell>
        <TableCell align="left">{item?.region_codes}</TableCell>
        <TableCell align="left">{item?.labels}</TableCell>
        <TableCell align="left">
          <Tooltip title={item?.comments || '-'}>
            <span>{getFormattedDescription(item?.comments)}</span>
          </Tooltip>
        </TableCell>
        <TableCell align="left">{item?.abuse_type}</TableCell>
        <TableCell align="left">{formatedDate(item.created_at)}</TableCell>
        <TableCell align="left">{item?.operation_id}</TableCell>
        <TableCell align="left">{item?.status || '-'}</TableCell>
      </TableRow>
    ));
  }

  return (
    <>
      <StyledHeader>
        <Title size={'large'} title="GSB Status" />
      </StyledHeader>
      <HorizontalLine />
      <TakedownTable>
        <TableContainer data-testid="gsb-status-table" sx={{ maxHeight: 400 }}>
          <Table stickyHeader size="small" sx={{ border: 0 }}>
            <TableHead>
              <TableRow>
                {GSBHistoryHeader.map((item, index) => (
                  <TableHeaderCell key={item} align="left">
                    {item}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderTableContent()}
            </TableBody>
          </Table>
          {totalItems > pageSize && !gsbLoading && (
            <PaginationStyle>
              <Pagination
                data-testid="gsb-status-pagination"
                count={count}
                page={gsbHistoryCurrentPage}
                onChange={handleChangeGsbHistoryPage}
                color="primary"
              />
            </PaginationStyle>
          )}
        </TableContainer>
      </TakedownTable>
    </>
  );
};

export default GSBStatusDetails;
