import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from '../../components';
import { getCompanyListAction } from '../../store/company/action';
import { CardWrapper, ClearAdornment, CloseIconButton, CompanyInputStyle, Content, ContentWrapper, FilterWrapper, HorizontalLine, MainContainer, SearchInputAdornment } from './Style/CompaniesListV2.style';
import CompaniesTableV2 from './CompaniesTableV2';
import AddCompanyModal from '../CompaniesList/AddCompanyModal';
import Title from '../../components/Title/Title';
import CustomPagination from '../../components/Pagination/Pagination';
import { hasCompanyV2Access } from '../../utils';
import SearchIcon from '../../assets/Images/Search.svg';

const currentPageRecords = 25;

const CompaniesListV2 = () => {
  const [companyCurrentPage, setCompanyCurrentPage] = useState<number>(1);
  const [searchCompanyName, setSearchCompanyName] = useState<string>('');
  const [openAddCompany, setOpenAddCompany] = useState<boolean>(false);
  const userEmail = useSelector(
    (state: any) => state?.user.userData?.data?.Email,
  );
  const { getCompanyList } = useSelector((state: any) => state.company);
  const dispatch = useDispatch();
  const count = Math.ceil(getCompanyList?.data?.Total / currentPageRecords);
  const navigate = useNavigate();
  const fetchCompanyList = (isClearSearch = false) => {
    dispatch(
      getCompanyListAction({
        d: 'y',
        u: 'n',
        s: 'y',
        h: isClearSearch ? '' :searchCompanyName,
        ipp: currentPageRecords,
        pn: companyCurrentPage,
        r: 'desc',
        m: 'created',
      }),
    );
  };

  // TODO: Temporary: company access handled in frontend, to be removed before production.
  useEffect(() => {
    const hasPermission = hasCompanyV2Access(userEmail);
    if (!hasPermission) {
      navigate(`/companies`);
    }
  }, [userEmail]);

  useEffect(() => {
    if (companyCurrentPage) {
      fetchCompanyList();
    }
  }, [companyCurrentPage]);

  const handleChangeCompanyPage = (event: any, value: number) => {
    setCompanyCurrentPage(value);
  };

  const handleChangeCompanyName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchCompanyName(e.target.value);
  };

  const handleCompanySearch = () => {
    setCompanyCurrentPage(1);
    fetchCompanyList();
  };

  const handleAddThreatModal = () => {
    navigate('/companies/new')
  };

  const handleClearSearch = () => {
    setSearchCompanyName('');
    fetchCompanyList(true);
  };

  return (
    <MainContainer>
      <Content>
        <ContentWrapper>
          <Title size={'small'} title="Companies list" />
          <Button
            data-testid="add-company-button"
            variant="contained"
            onClick={handleAddThreatModal}>
            Create Company
          </Button>
        </ContentWrapper>
        <HorizontalLine />
        <FilterWrapper>
          <Input
            data-testid="company-search-input"
            size="small"
            placeholder="Search for a company and press enter"
            onChange={handleChangeCompanyName}
            value={searchCompanyName}
            sx={CompanyInputStyle}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleCompanySearch();
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchInputAdornment src={SearchIcon} alt="SearchIcon" />
                </InputAdornment>
              ),
              endAdornment: searchCompanyName && (
                <CloseIconButton onClick={handleClearSearch} size="medium">
                  <ClearAdornment />
                </CloseIconButton>
              ),
            }}
          />
        </FilterWrapper>
      </Content>
      <CardWrapper>
        <CompaniesTableV2
          searchCompanyName={searchCompanyName}
          companyCurrentPage={companyCurrentPage}
        />
      </CardWrapper>
      {getCompanyList?.data?.Total > currentPageRecords &&
        !getCompanyList?.loading && (
          <CustomPagination
            count={count}
            page={companyCurrentPage}
            onChange={handleChangeCompanyPage}
          />
        )}
      <AddCompanyModal
        open={openAddCompany}
        handleClose={() => setOpenAddCompany(false)}
      />
    </MainContainer>
  );
};

export default CompaniesListV2;
