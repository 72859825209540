import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  List,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
  Collapse,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import {
  CustomListItemButton,
  CustomListItemIcon,
  Drawer,
  DrawerHeader,
} from './Style/Sidebar.style';
import { SidebarProps } from '../../helpers/types';
import { menuItems as defaultMenuItems, hasCompanyV2Access } from '../../utils'; // import your default menu items

const Sidebar = ({
  handleDrawerClose,
  open,
  handleDrawerOpen,
}: SidebarProps) => {
  const location = useLocation();
  const [isUserHasCompanyV2Access, setIsUserHasCompanyV2Access] =
    useState(false);
  const [openSubmenus, setOpenSubmenus] = useState<Record<number, boolean>>({});
 
  const userEmail = useSelector(
    (state: any) => state?.user.userData?.data?.Email,
  );
  
  // TODO: Temporary: company access handled in frontend, to be removed before production.

  useEffect(() => {
    const hasPermission = hasCompanyV2Access(userEmail);
    setIsUserHasCompanyV2Access(hasPermission);
  }, [userEmail]);

  const menuItems = useMemo(() => {
    return defaultMenuItems.map(item => {
      if (item.menuName === 'Companies') {
        return {
          ...item,
          to: isUserHasCompanyV2Access ? '/companies-temp' : '/companies',
        };
      }
      return item;
    });
  }, [isUserHasCompanyV2Access]);

  const toggleSubmenu = (id: number) => {
    setOpenSubmenus(prev => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    menuItems.forEach(item => {
      if (location.pathname.startsWith(item.to)) {
        setOpenSubmenus({});
      }
    });
  }, [location.pathname, menuItems]);

  const closeSubmenu = (id: number) => {
    setOpenSubmenus(prev => ({
      ...prev,
      [id]: false,
    }));
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <Typography variant="h6" fontWeight="bold" sx={{ mr: 3 }}>
          Wheel
        </Typography>
        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <List>
        {menuItems.map(item => {
          const isActive = location.pathname.startsWith(item.to);
          const isSubmenuOpen = openSubmenus[item.id];

          return (
            <div key={item.id}>
              <Link
                to={item.to}
                style={{ textDecoration: 'none', color: 'black' }}>
                <ListItem disablePadding sx={{ paddingRight: '16px' }}>
                  <CustomListItemButton isActive={isActive} open={open}>
                    <CustomListItemIcon isActive={isActive}>
                      {item.icon}
                    </CustomListItemIcon>
                    <ListItemText
                      primary={item.menuName}
                      sx={{
                        opacity: open ? 1 : 0,
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                      }}
                    />
                    {item.submenu && open && (
                      <IconButton
                        onClick={e => {
                          e.preventDefault();
                          if (isSubmenuOpen) {
                            closeSubmenu(item.id);
                          } else {
                            toggleSubmenu(item.id);
                          }
                        }}
                        size="small">
                        {isSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                    )}
                  </CustomListItemButton>
                </ListItem>
              </Link>
              {item.submenu?.length && (
                <Collapse in={isSubmenuOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.submenu.map(subitem => {
                      const isSubitemActive = location.pathname === subitem.to;
                      return (
                        <Link
                          to={subitem.to}
                          key={subitem.id}
                          style={{ textDecoration: 'none', color: 'black' }}>
                          <ListItem disablePadding>
                            <CustomListItemButton
                              isActive={isSubitemActive}
                              open={open}
                              sx={{
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                              }}>
                              <CustomListItemIcon isActive={isSubitemActive}>
                                {subitem.icon}
                              </CustomListItemIcon>
                              <ListItemText
                                primary={subitem.menuName}
                                sx={{
                                  opacity: open ? 1 : 0,
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                }}
                              />
                            </CustomListItemButton>
                          </ListItem>
                        </Link>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </div>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;
