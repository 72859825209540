import { Box, styled, Typography } from '@mui/material';

export const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems:'center',
  gap: 12,
});

export const Divider = styled(Box)(({ theme }) => ({
  height: 30,
  width: 4,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 9,
}));

export const TitleContent = styled(Typography)<{
  size: 'small' | 'medium' | 'large';
}>(({ theme, size }) => ({
  fontSize: size === 'small' ? 16 : size === 'medium' ? 20 : 24,
  fontFamily: theme.typography.fontFamily,
  fontWeight: 700,
  color: theme.palette.text.primary,
  alignItems: 'center',
  lineHeight: '100%',
}));
