import React from 'react';
import { Button, SearchableDropDownV2 } from '../../components';
import {
  ActionWrapper,
  Container,
  Content,
  ContentWrapper,
  HorizontalLine,
  InfoWrapper,
  StyledStrong,
} from './Style/CompanyCreationV2.style';
import Title from '../../components/Title/Title';
import { CompanyRelationshipProps, OptionType } from '../../helpers/types';

const CompanyRelationship: React.FC<CompanyRelationshipProps> = ({
  selectedValues,
  handleAutocompleteChange,
  handleRemoveParentCompany,
  dropdown,
}) => {
  const isParentSelected = selectedValues?.parent !== null;

  return (
    <Container>
      <Content sx={{height: 135}}>
        <ContentWrapper>
          <Title size={'large'} title="Company relationships" />
        </ContentWrapper>
        <HorizontalLine />
        <InfoWrapper>
          <StyledStrong size="small">Parent Company</StyledStrong>
          <ActionWrapper>
            <SearchableDropDownV2
              items={dropdown}
              placeholder="None"
              selectedValues={selectedValues?.parent || null}
              onChange={(event: any, value: OptionType | null) =>
                handleAutocompleteChange(event, value, 'parent')
              }
              multiple={false}
              dataTestid="parent-company-dropdown"
              minWidth={120}
              maxWidth={200}
            />
            {isParentSelected && (
              <Button
                data-testid="remove-company-button"
                variant="outlined"
                onClick={handleRemoveParentCompany}
                sx={{ width: 145, height: 34 }}>
                Remove
              </Button>
            )}
          </ActionWrapper>
        </InfoWrapper>
      </Content>
    </Container>
  );
};

export default CompanyRelationship;
