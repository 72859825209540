import React from 'react';
import { DialogContent, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  Timeline,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import { format } from 'date-fns';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { DialogDate, TimelineItemStyle } from './Style/Tabs.style';
import { formatDateActivity } from '../../../utils';
import ActivityIcon from '../../../assets/Images/ActivityIcon.svg';
import { CompanyInfoLoader } from '../../CompanyDetailsV2/style/CompanyDetailsV2.style';
import { Loader } from '../../../components';

const PaperStyle = {
  backgroundColor: 'white',
  height: 'auto',
};

const ActivityLogsTab = () => {
  const { getCompanyDetail } = useSelector((state: any) => state.company);

  const users = Array.isArray(getCompanyDetail?.data?.Users)
    ? getCompanyDetail.data.Users.map((user: any) => ({
        ...user,
        type: 'User',
      }))
    : [];

  const domains = Array.isArray(getCompanyDetail?.data?.Domains)
    ? getCompanyDetail.data.Domains.map((domain: any) => ({
        ...domain,
        type: 'Domain',
      }))
    : [];

  const combinedActivity = [...domains, ...users].reverse();
  const currentDate = format(new Date(), 'MMMM dd, yyyy');

  return (
    <>
      {getCompanyDetail?.loading ? (
        <CompanyInfoLoader>
          <Loader size={25} />
        </CompanyInfoLoader>
      ) : (
        <Paper
          data-testid="activity-logs-container"
          elevation={1}
          sx={PaperStyle}>
          <DialogDate>
            <DateRangeIcon />
            <span>{currentDate}</span>
          </DialogDate>
          <DialogContent dividers>
            <Timeline sx={{ padding: '6px 0' }}>
              {combinedActivity.map((item, index) => (
                <TimelineItemStyle key={index}>
                  <TimelineSeparator>
                    <TimelineDot sx={{ backgroundColor: '#f5f8fa' }}>
                      <img src={ActivityIcon} alt="" />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                  <Typography variant="body2" sx={{ fontWeight: 600, mb: 0.5 }}>
                      {item.type === 'User' ? (
                        <>
                        {item?.Firstname} {item?.Lastname} is now a contact for{' '}
                        {getCompanyDetail.data?.Name}
                        </>
                      ) : (
                        <>
                          {getCompanyDetail.data?.Name} added {item?.Name} as
                          protected domain!
                        </>
                      )}
                    </Typography>
                    <Typography variant="body2" color="gray">
                      Alerted {formatDateActivity(item.Created)} by Agatha
                    </Typography>
                  </TimelineContent>
                </TimelineItemStyle>
              ))}
            </Timeline>
          </DialogContent>
        </Paper>
      )}
    </>
  );
};

export default ActivityLogsTab;
