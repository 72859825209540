import { useEffect } from 'react';
import { styled } from '@mui/material';
import { useErrorBoundary } from "react-error-boundary";
import { useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import {
  Description,
  ErrorIcon,
  ErrorLink,
  FallbackContainer,
  Title,
} from './ErrorBoundary.style';
import { useAuth0 } from '../__mocks__/@auth0/auth0-react';

export const ErrorContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  marginTop: '40px',
});

export const MyFallbackComponent = ({ error, resetErrorBoundary }: any) => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <ErrorContainer role="alert">
      <h1>Something went wrong:</h1>
      <h3>{error.message}</h3>
      <button onClick={resetBoundary}>Try again</button>
    </ErrorContainer>
  );
};

export const ErrorBoundary = ({ children }: any) => {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export const FallbackComponent = () => {
  const { logout } = useAuth0();
  const error = useRouteError() as Error;

  const routeError = useRouteError()

  useEffect(() => {
      Sentry.captureException(error)
  }, [routeError])
  
  const handleReload = () => {
    window.location.reload();
  };
  const handleClearCache = () => {
    localStorage.clear();
    sessionStorage.clear();

    if (window.caches) {
      caches.keys().then(names => {
        names.forEach(name => {
          caches.delete(name);
        });
      });
    }
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <FallbackContainer>
      <Title>
        <ErrorIcon/>
        Something went wrong and has been reported.
      </Title>
      <Description>
        In the meantime, try{' '}
        <span>
          <ErrorLink href="#" onClick={handleReload}>
            reloading
          </ErrorLink>{' '}
          the page
        </span>{' '}
        or{' '}
        <span>
          <ErrorLink href="#" onClick={handleClearCache}>
            clearing your cache.
          </ErrorLink>
        </span>
      </Description>
    </FallbackContainer>
  );
};
