import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

import Login from './pages/Login/Login';
import SingleThreatDetail from './pages/ThreadsDetails/SingleThreatDetail';
import TakedownList from './pages/TakedownList/TakedownList';
import CompaniesList from './pages/CompaniesList/CompaniesList';
import CompanyDetails from './pages/CompanyDetails/CompanyDetails';
import ActiveDefense from './pages/ActiveDefense/ActiveDefense';
import FalsePositive from './pages/FalsePositive/FalsePositive';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import EnrichTakedown from './pages/TakedownList/EnrichTakedown';
import DefaultLayout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import TakedownStatusHistory from './pages/TakedownList/TakedownStatusHistory';
import useApiInterceptor from './helpers/useApiInterceptor';
import GSBHistory from './pages/GSBHistory/GSBHistory';

import {
  REACT_APP_ENVIRONMENT,
  REACT_APP_GTM_ID,
  REACT_APP_SENTRY_DSN,
} from './utils/envVar';
import { SingleThreatProvider } from './context/SingleThreat/SingleThreatContext';
import { GsbProvider } from './context/GsbHistory/GsbHistoryContext';
import { ErrorBoundary, FallbackComponent } from './helpers/ErrorBoundary';
import packageJson from '../package.json';
import CompanyDetailsV2 from './pages/CompanyDetailsV2/CompanyDetailsV2';
import CompaniesListV2 from './pages/CompaniesListV2/CompaniesListV2';
import CompanyCreationV2 from './pages/CompanyCreationV2/CompanyCreationV2';
import { useAuth0 } from './__mocks__/@auth0/auth0-react';

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: ['api.bfore.ai']
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/([a-zA-Z0-9-]+\.)*bfore\.ai\//,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: packageJson.version ?? 'unknown-release',
});

const tagManagerArgs: any = {
  gtmId: REACT_APP_GTM_ID,
};

TagManager.initialize(tagManagerArgs);

function App() {
  useApiInterceptor();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  useEffect(() => {
    const connect = async () => {
      const token : any = await getIdTokenClaims();
      const user = {
        email: token?.email,
        name: token?.name,
      };
      Sentry.setUser(user);
      (window as any)?.dataLayer?.push({
        event: 'appInit',
        userName: user?.name,
        userEmail: user?.email,
      });
    };
    if (isAuthenticated) {
      connect();
    } 
  }, [isAuthenticated, getIdTokenClaims]);

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
      <Router>
        <SentryRoutes>
          <Route path="/" element={<PublicRoute element={<Login />} />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ErrorBoundary>
                      <Home />
                    </ErrorBoundary>
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/threats/customer-review-requests"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ErrorBoundary>
                      <Home />
                    </ErrorBoundary>
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="threats/:threatName/:domainName/:companyId"
            element={
              <SingleThreatProvider>
                <GsbProvider>
                  <ErrorBoundary>
                    <SingleThreatDetail />
                  </ErrorBoundary>
                </GsbProvider>
              </SingleThreatProvider>
            }
          />
          <Route
            path="/takedown"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <Outlet />
                  </DefaultLayout>
                }
              />
            }>
            <Route
              index
              element={
                <ErrorBoundary>
                  <TakedownList />
                </ErrorBoundary>
              }
            />
            <Route
              path="history/:takedown_id"
              element={
                <ErrorBoundary>
                  <TakedownStatusHistory />
                </ErrorBoundary>
              }
            />
          </Route>
          <Route
            path="/enrich-takedown"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ErrorBoundary>
                      <EnrichTakedown />
                    </ErrorBoundary>
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/companies"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ErrorBoundary>
                      <CompaniesList />
                    </ErrorBoundary>
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/companies-temp"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ErrorBoundary>
                      <CompaniesListV2 />
                    </ErrorBoundary>
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/companies/new"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ErrorBoundary>
                      <CompanyCreationV2 />
                    </ErrorBoundary>
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/companies/:id/view"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ErrorBoundary>
                      <CompanyDetails />
                    </ErrorBoundary>
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/companies-temp/:id/view"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ErrorBoundary>
                      <CompanyDetailsV2 />
                    </ErrorBoundary>
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/network-disruption"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ErrorBoundary>
                      <ActiveDefense />
                    </ErrorBoundary>
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/false-positive"
            element={
              <PrivateRoute
                element={
                  <DefaultLayout>
                    <ErrorBoundary>
                      <FalsePositive />
                    </ErrorBoundary>
                  </DefaultLayout>
                }
              />
            }
          />
          <Route
            path="/gsb"
            element={
              <PrivateRoute
                element={
                  <SingleThreatProvider>
                    <GsbProvider>
                      <DefaultLayout>
                        <ErrorBoundary>
                          <GSBHistory />
                        </ErrorBoundary>
                      </DefaultLayout>
                    </GsbProvider>
                  </SingleThreatProvider>
                }
              />
            }
          />
        </SentryRoutes>
      </Router>
    </Sentry.ErrorBoundary>
  );
}

export default App;
