import { styled } from '@mui/material/styles';
import { Autocomplete, Box, ListItem, TextField } from '@mui/material';
import { OptionType } from '../../../helpers/types';

export const StyledAutocomplete = styled(
  Autocomplete<OptionType, boolean, false, false>,
)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.paper,
    textTransform: 'capitalize',
    borderRadius: 8,
    display: 'flex',
    flexWrap: 'nowrap',
    height: 34,
    alignItems: 'center',
    padding: 0,
    paddingLeft: 16,
    paddingRight: '35px !important',
    '& fieldset': {
      borderColor: theme.palette.divider,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiAutocomplete-input': {
    padding: '0px !important',
    minWidth: '10px !important',
  },

  '& .MuiInputBase-input': {
    lineHeight: '40px',
    display: 'inline-block',
    flexGrow: 1,
    paddingRight: 0,
    padding: 0,
  },

  '& .MuiAutocomplete-tag' : {
    display: 'inline-flex',
    height: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.background?.tertiary,
    color: theme.palette.text.primary,
    fontWeight: 400,
    lineHeight: '40px',
    paddingRight: 0,
    borderRadius: 6,
    marginRight: 4,
    '& .MuiChip-deleteIcon': {
      width: 14,
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.error.main,
      },
    },
  },

  '& .MuiAutocomplete-listbox': {
    maxHeight: 200,
    overflowY: 'auto',
    paddingRight: 0,
  },

  '& .MuiAutocomplete-popupIndicator': {
    color: theme.palette.primary.dark,
    right: 4
  },

  '& .MuiAutocomplete-option': {
    '&[aria-selected="true"]': {
      backgroundColor: `${theme.palette.primary.main}14`,
    },
    '&.Mui-focused': {
      backgroundColor: `${theme.palette.primary.main}14`,
    },
    paddingRight: 0,
  },
  '& .MuiAutocomplete-endAdornment': {
    right: 10
  }
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: 12,
  fontWeight: 500,
  fontFamily: theme.typography.fontFamily,
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  '& input::placeholder': {
    color: theme.palette.text.primary,
    opacity: 1
  },
}));

export const StyledTagBox = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 12,
  flexWrap: 'nowrap',
  fontWeight: 700,
  color: theme.palette.text.primary,
}));

export const StyledEllipsisBox = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  fontWeight: 700,
  display: 'inline-flex',
  marginRight: 1,
}));
