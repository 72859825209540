import React, { useCallback, useEffect, useState } from 'react';
import { Box, DialogContent, Paper, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Dropdown, GradientButton, Input, Loader } from '../../../components';
import { InformationButtonWrapper, InformationTitle } from './Style/Tabs.style';
import CompanyDetailsForm from '../CompanyDetailsForm';
import {
  getCompanyDetailAction,
  updateCompanyIndustryAction,
} from '../../../store/company/action';
import {
  removeCompanyServiceApi,
  updateCompanyServiceApi,
} from '../../../store/company';
import { showToast } from '../../../store/toaster/action';
import { CompanyInfoLoader } from '../../CompanyDetailsV2/style/CompanyDetailsV2.style';

const PaperStyle = {
  height: 'auto',
  borderRadius: '15px',
  border: 'none'
};

const InformationTab = () => {
  const [formValues, setFormValues] = useState<any>({
    logo: '',
    companyName: '',
    vat: '',
    hubSpotId: '',
    address: '',
    postCode: '',
    contactPhone: '',
    email: '',
    parent: null,
    nbEmployees: 0,
    services: '',
    industryName: '',
  });
  const { getCompanyDetail, getService } = useSelector(
    (state: any) => state.company,
  );
  const companyDetail = getCompanyDetail.data;
  const [companyServiceLoading, setCompanyServiceLoading] = useState(false);
  const [removeCompanyServiceLoader, setRemoveCompanyServiceLoader] = useState(false);
  const [addCompanyServiceLoader, setAddCompanyServiceLoader] = useState(false);
  const selectedServicesData = useCallback(
    companyDetail?.Services?.map((service: any) => ({
      id: service.Id,
      label: service.Name,
    })),
    [companyDetail?.Services],
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (companyDetail) {
      setFormValues({
        logo: companyDetail.Logo || '',
        companyName: companyDetail.Name || '',
        vat: companyDetail.Vat || '',
        hubSpotId: companyDetail.Properties?.hubSpotId || '',
        address: companyDetail.Address || '',
        postCode: companyDetail.Zip || '',
        contactPhone: companyDetail.Phone || '',
        email: companyDetail.Email || '',
        parent: companyDetail?.Parent || null,
        nbEmployees: companyDetail.nbEmployees || 0,
        services: selectedServicesData,
        industryName: companyDetail.Properties?.Industry || '',
      });
    }
  }, [companyDetail]);

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const compaareArrays = (oldArray: any, newArray: any) => {
    const oldMap = new Map(
      oldArray?.map((item: { [x: string]: any }) => [item['id'], item]),
    );
    const newMap = new Map(
      newArray?.map((item: { [x: string]: any }) => [item['id'], item]),
    );
    const removed = oldArray.filter(
      (item: { [x: string]: any }) => !newMap.has(item['id']),
    );
    const added = newArray.filter(
      (item: { [x: string]: any }) => !oldMap.has(item['id']),
    );
    return { added, removed };
  };

  const getCompanyDetails = async () => {
    if (id && !removeCompanyServiceLoader && !addCompanyServiceLoader) {
      await dispatch(getCompanyDetailAction({ id }));
      dispatch(
        showToast(
          'Company services have been updated',
          'success',
        ),
      );
      setCompanyServiceLoading(false);
    }
  };

  const handleRemoveCompanyService = async (serviceArray: any) => {
    setRemoveCompanyServiceLoader(true)
    setCompanyServiceLoading(true);
    const removeCompanyService = serviceArray.map(
      async (service: { id: number; label: string }) => {
        try {
          await removeCompanyServiceApi({
            serviceId: service.id,
            companyId: id,
          });
        } catch (error) {
          console.error("error")
        }
      },
    );
    await Promise.all(removeCompanyService);
    setRemoveCompanyServiceLoader(false)
    getCompanyDetails();
  };

  const handleAddCompanyService = async (serviceArray: any) => {
    setAddCompanyServiceLoader(true);
    setCompanyServiceLoading(true);
    const addCompanyService = serviceArray.map(
      async (service: { id: number; label: string }) => {
        try {
          await updateCompanyServiceApi({
            serviceId: service.id,
            companyId: id,
          });
        } catch (error) {
          console.error("error")
        }
      },
    );
    await Promise.all(addCompanyService);
    setAddCompanyServiceLoader(false);
    getCompanyDetails();
  };

  const handleAutocompleteChange = (event: any, value: any, name: any) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleChangeServices = () => {
    const { added, removed } = compaareArrays(
      selectedServicesData,
      formValues.services,
    );

    if (removed?.length > 0) {
      handleRemoveCompanyService(removed);
    }
    if (added?.length > 0) {
      handleAddCompanyService(added);
    }
  };

  const handleCompanyIndustry = () => {
    dispatch(
      updateCompanyIndustryAction({
        company: id,
        key: 'Industry',
        value: formValues.industryName,
      }),
    );
  };

  const searviceData = useCallback(
    getService?.data?.items?.map((service: any) => ({
      label: service.name,
      id: service.id,
    })),
    [getService?.data?.items],
  );

  return (
    <>
      {getCompanyDetail?.loading ? (
        <CompanyInfoLoader>
           <Loader size={25} />
        </CompanyInfoLoader>
      ) : (
        <>
          <Paper elevation={1} sx={PaperStyle}>
            <InformationTitle>
              <span>Entity Details</span>
            </InformationTitle>
            <CompanyDetailsForm
              formValues={formValues}
              handleChangeInput={handleChangeInput}
              handleAutocompleteChange={handleAutocompleteChange}
            />
          </Paper>
          <Paper elevation={1} sx={{ ...PaperStyle, my: 3 }}>
            <InformationTitle>
              <span>Entity Services</span>
            </InformationTitle>
            <DialogContent dividers>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Typography sx={{ minWidth: '30%' }} variant="body2">
                  Services
                </Typography>
                <Dropdown
                  dataTestid="company-dropdown"
                  items={searviceData}
                  size="small"
                  placeholder="Services"
                  label="Services"
                  onChange={(event: any, value: any) =>
                    handleAutocompleteChange(event, value, 'services')
                  }
                  clearIcon={null}
                  selectedValues={formValues?.services || []}
                  multiple={true}
                />
              </Box>
            </DialogContent>
            <InformationButtonWrapper>
              <Button variant="outlined" color="secondary">
                Cancel
              </Button>
              <GradientButton
                data-testid="save-services-button"
                variant="contained"
                disabled={companyServiceLoading}
                onClick={handleChangeServices}>
                Save Changes
              </GradientButton>
            </InformationButtonWrapper>
          </Paper>
          <Paper elevation={1} sx={{ ...PaperStyle, my: 3 }}>
            <InformationTitle>
              <span>Entity Industry</span>
            </InformationTitle>
            <DialogContent dividers>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Typography sx={{ minWidth: '30%' }} variant="body2">
                  Industry Name
                </Typography>
                <Input
                  size="small"
                  name="industryName"
                  value={formValues.industryName}
                  onChange={handleChangeInput}
                  placeholder="industry name"
                  sx={{ width: '100%' }}
                />
              </Box>
            </DialogContent>
            <InformationButtonWrapper>
              <Button variant="outlined" color="secondary">
                Cancel
              </Button>
              <GradientButton variant="contained" onClick={handleCompanyIndustry}>
                Save Changes
              </GradientButton>
            </InformationButtonWrapper>
          </Paper>
        </>
      )}
    </>
  );
};

export default InformationTab;
