import React, { createContext, useState, useContext, useMemo, ReactNode } from 'react';
import { delay } from 'redux-saga/effects';
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/toaster/action';
import { changeStatusLabelApi, getThreatDetailApi, getThreatScreenshot } from '../../store/threats';
import {
  createNetworkByType,
  getHistoryStatuesApi,
  getNetworkDataByType,
  getNetworkTaskById,
  getSubDomainsApi,
  fetchGSBLiveData,
} from '../../store/singleThreat';
import {
  DNSDataResponseType,
  HistoryStatuesType,
  HistoryStatusesDataType,
  SingleThreatContextType,
  SingleThreatDetailsType,
  SSlDataResponseType,
  SubDomainsType,
  WhoisDataResponseType,
  GSBLiveInfoType,
} from './SingleThreatType';
import { apiErrorMessage, isRecentNetworkRecords, NETWORK_DATA_TYPE } from '../../utils';
import { changeStatusLabelType } from '../../helpers/types';

const SingleThreatContext = createContext<SingleThreatContextType | null>(null);

interface SingleThreatProviderProps {
  children: ReactNode;
}
export const useSingleThreatContext = () => {
  const context = useContext(SingleThreatContext);
  if (!context) {
    throw new Error('useThreatContext must be used within a SingleThreatProvider');
  }
  return context;
};

export const SingleThreatProvider: React.FC<SingleThreatProviderProps> = ({ children }) => {
  const [singleThreatDetails, setSingleThreatDetails] =
    useState<SingleThreatDetailsType>();
  const [imageData, setImageData] = useState<string | undefined>();
  const [historyStatusesData, setHistoryStatusesData] = useState<
    HistoryStatusesDataType[]
  >([]);
  const [subDomainsData, setSubDomainsData] = useState<SubDomainsType[]>([]);
  const [subDomainsLoading, setSubDomainsLoading] = useState<boolean>(false);
  const [whoisData, setWhoisData] = useState<WhoisDataResponseType>();
  const [whoisDataLoading, setWhoisDataLoading] = useState<boolean>(false);
  const [sslData, setSSLData] = useState<SSlDataResponseType>();
  const [sslDataLoading, setSSLDataLoading] = useState<boolean>(false);
  const [dnsData, setDNSData] = useState<DNSDataResponseType>();
  const [dnsLoading, setDNSLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [historyStatusLoading, setHistoryStatusLoading] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const [gsbLiveInfo, setGSBLiveInfo] = useState<GSBLiveInfoType | undefined>();
  const [isGSBLiveDataLoading, setIsGSBLiveDataLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');


  const fetchSingleThreatList = async (params: {
    protected_id: string;
    threat_id: string;
  }) => {
    setLoading(true);
    setError('');
    try {
      const response = await getThreatDetailApi(params);
      setSingleThreatDetails(response?.data);
    } catch (err: any) {
      console.error(err)
    } finally {
      setLoading(false);
    }
  };

  const fetchThreatScreenshot = async (ThreatName: string | undefined) => {
    setImageLoading(true);
    setError('');
    try {
      const res = await getThreatScreenshot(ThreatName);
      setImageData(res.data.Image);
    } catch (err: any) {
      console.error(err)
    } finally {
      setImageLoading(false);
    }
  };

  const getHistoryStatuses = async (params: HistoryStatuesType) => {
    setHistoryStatusLoading(true);
    setError('');
    try {
      const res = await getHistoryStatuesApi(params);
      setHistoryStatusesData(res.data);
    } catch (err: any) {
      console.error(err)
    } finally {
      setHistoryStatusLoading(false);
    }
  };

  const getSubDomains = async (DomainId: number) => {
    setSubDomainsLoading(true);
    setError('');
    try {
      const res = await getSubDomainsApi(DomainId);
      setSubDomainsData(res.data);
    } catch (err: any) {
      console.error(err)
    } finally {
      setSubDomainsLoading(false);
    }
  };

  const getNetworkRecords = async (
    asset_id: number,
    type: NETWORK_DATA_TYPE.WHOIS | NETWORK_DATA_TYPE.CERTIFICATE | NETWORK_DATA_TYPE.RECORDS,
  ) => {
    if (type === NETWORK_DATA_TYPE.WHOIS) {
      setWhoisDataLoading(true);
    } else if (type === NETWORK_DATA_TYPE.CERTIFICATE) {
      setSSLDataLoading(true);
    } else {
      setDNSLoading(true);
    }

    setError('');
    try {
      const res = await getNetworkDataByType(asset_id, type);

      const isDataExits =
        res?.data?.items?.length > 0 &&
        isRecentNetworkRecords(type, res?.data?.items?.[0]?.created_at);

      if (isDataExits) {
        if (type === NETWORK_DATA_TYPE.WHOIS) {
          setWhoisData(res?.data);
        } else if (type === NETWORK_DATA_TYPE.CERTIFICATE) {
          setSSLData(res?.data);
        } else {
          setDNSData(res?.data);
        }
      } else {
        const resCreateNetwork = await createNetworkByType({ asset_id, type });
        await delay(2000);
        if(resCreateNetwork?.data?.task_id){
          const resTaskById = await getNetworkTaskById(
            resCreateNetwork?.data?.task_id,
          );
        if (type === NETWORK_DATA_TYPE.WHOIS) {
          setWhoisData(resTaskById?.data);
        } else if (type === NETWORK_DATA_TYPE.CERTIFICATE) {
          setSSLData(resTaskById?.data);
        } else {
          setDNSData(resTaskById?.data);
        }
      }
      }
    } catch (err: any) {
      dispatch(showToast(err?.message || 'Something went wrong', 'error'));
      console.error(err)
    } finally {
      setWhoisDataLoading(false);
      setSSLDataLoading(false);
      setDNSLoading(false);
    }
  };

  const getWhoisRecords = async (params: {
    asset_id: number;
    type: string;
  }) => {
    await getNetworkRecords(params.asset_id, NETWORK_DATA_TYPE.WHOIS);
  };

  const getSSLRecords = async (params: { asset_id: number; type: string }) => {
    await getNetworkRecords(params.asset_id, NETWORK_DATA_TYPE.CERTIFICATE);
  };

  const getDnsRecords = async (params: { asset_id: number; type: string }) => {
    await getNetworkRecords(params.asset_id, NETWORK_DATA_TYPE.RECORDS);
  };

  const updateThreatStatus = async (data: changeStatusLabelType) => {
    setError('');
  
    try {
      const { data: responseData } = await changeStatusLabelApi(data);
      const newStatus = responseData?.items?.[0]?.status;
  
      if (newStatus) {
        setSingleThreatDetails((prevDetails: any) => ({
          ...prevDetails,
          identification_status: {
            value: newStatus || prevDetails.identification_status.value,
          },
        }));
      }
  
    } catch (err: any) {
      console.error(err)
    }
  };  

  const fetchGSBLiveInfo = async (params: string ) => {
    setIsGSBLiveDataLoading(true);
    try {
      const res = await fetchGSBLiveData(params);
      setGSBLiveInfo(res?.data);
    } catch (err: any) {
      const errorMessage = apiErrorMessage(err)
      dispatch(showToast(errorMessage, 'error'));
      console.error(err)
    } finally {
      setIsGSBLiveDataLoading(false);
    }
  };

  const contextValue = useMemo(
    () => ({
      singleThreatDetails,
      loading,
      fetchSingleThreatList,
      fetchThreatScreenshot,
      imageData,
      imageLoading,
      getHistoryStatuses,
      historyStatusesData,
      historyStatusLoading,
      getSubDomains,
      subDomainsLoading,
      subDomainsData,
      getWhoisRecords,
      whoisData,
      whoisDataLoading,
      getSSLRecords,
      sslData,
      sslDataLoading,
      getDnsRecords,
      dnsData,
      dnsLoading,
      updateThreatStatus,
      fetchGSBLiveInfo,
      gsbLiveInfo,
      isGSBLiveDataLoading
    }),
    [
      singleThreatDetails,
      loading,
      error,
      imageData,
      imageLoading,
      historyStatusesData,
      historyStatusLoading,
      subDomainsData,
      subDomainsLoading,
      whoisData,
      whoisDataLoading,
      sslData,
      sslDataLoading,
      dnsData,
      dnsLoading,
      gsbLiveInfo,
      isGSBLiveDataLoading
    ],
  );

  return (
    <SingleThreatContext.Provider value={contextValue}>
      {children}
    </SingleThreatContext.Provider>
  );
};
