import React, { useState, useCallback, useRef } from 'react';
import {
  Typography,
  Modal,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
} from '@mui/material';
import { RemoveCircle, HighlightOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Button, GradientButton } from '../../components';
import { createThreatAction } from '../../store/threats/action';
import {
  AddThreatsMode,
  CreateThreatModelProps,
  DomainThreats,
  Errors,
} from '../../helpers/types';
import { ButtonWrapper, CloseButton, ModalWrapper } from './Style/Home.style';
import { urlRegex } from '../../utils';
import MultiDomainInput from '../../components/MultiDomainInput/MultiDomainInput';
import {
  InfoBox,
  InfoTypography,
  StyledBox,
  StyledButton,
  StyledTableContainer,
  StyledTextField,
  StyledTypography,
} from './Style/CreateThreatModel.style';

const CreateThreatModel: React.FC<CreateThreatModelProps> = ({
  open,
  handleClose,
  userData,
}) => {
  const [mode, setMode] = useState<AddThreatsMode>('single');
  const dropdownStates = useRef<boolean[]>([]);
  const isDomainRef = useRef(false);
  const [singleDomainThreats, setSingleDomainThreats] = useState<DomainThreats>(
    {
      protectedAssetId: '',
      protectedAssetName: '',
      companyId: '',
      threatName: '',
      createThreats: [],
    },
  );
  const [uniqueDomainThreats, setUniqueDomainThreats] = useState<
    DomainThreats[]
  >([
    {
      protectedAssetId: '',
      protectedAssetName: '',
      companyId: '',
      threatName: '',
      createThreats: [],
    },
  ]);
  const [errors, setErrors] = useState<Errors>({
    singleDomain: '',
    singleThreats: '',
    uniqueThreats: [],
  });

  const dispatch = useDispatch();
  const protectedDomains = useSelector(
    (state: any) => state.threats?.domainList?.data?.Items,
  );

  const validateInputs = () => {
    let isValid = true;

    setErrors({
      singleDomain: '',
      singleThreats: '',
      uniqueThreats: [],
    });

    if (mode === 'single') {
      if (!singleDomainThreats.protectedAssetName) {
        setErrors((prev: Errors) => ({
          ...prev,
          singleDomain: 'Domain is required.',
        }));
        isValid = false;
      }

      if (singleDomainThreats.createThreats.length === 0) {
        setErrors((prev: Errors) => ({
          ...prev,
          singleThreats: 'At least one threat name is required.',
        }));
        isValid = false;
      } else if (
        singleDomainThreats.createThreats.some(
          (threat: string) => !urlRegex.test(threat),
        )
      ) {
        setErrors((prev: Errors) => ({
          ...prev,
          singleThreats: 'All threat names must be valid URLs.',
        }));
        isValid = false;
      }
    } else {
      const uniqueThreatsErrors = uniqueDomainThreats.map(
        (threat: DomainThreats) => {
          const errors = { domain: '', threats: '' };

          if (!threat.protectedAssetName) {
            errors.domain = 'Domain is required.';
            isValid = false;
          }

          if (threat.createThreats.length === 0) {
            errors.threats = 'At least one threat name is required.';
            isValid = false;
          } else if (
            threat.createThreats.some((name: string) => !urlRegex.test(name))
          ) {
            errors.threats = 'All threat names must be valid URLs.';
            isValid = false;
          }

          return errors;
        },
      );

      setErrors((prev: Errors) => ({
        ...prev,
        uniqueThreats: uniqueThreatsErrors,
      }));
    }

    return isValid;
  };

  const handleAddThreats = useCallback(() => {
    if (!validateInputs()) return;
    let createThreatRequest = [];
    if (mode === 'single') {
      createThreatRequest = singleDomainThreats?.createThreats?.map(
        (threat: string) => {
          return {
            threat_name: threat,
            status: 'confirmed',
            user_id: userData?.data?.Id || '',
            company_id: singleDomainThreats?.companyId,
            process: 'w',
            priority: 1,
            protected_asset_id: singleDomainThreats?.protectedAssetId,
          };
        },
      );
    } else {
      createThreatRequest =
        uniqueDomainThreats?.flatMap((domain: any) =>
          domain?.createThreats?.map((threat: string) => ({
            threat_name: threat,
            status: 'confirmed',
            user_id: userData?.data?.Id || '',
            company_id: domain?.companyId,
            process: 'w',
            priority: 1,
            protected_asset_id: domain?.protectedAssetId,
          })),
        ) || [];
    }

    dispatch(createThreatAction({ items: createThreatRequest }));
    resetForm();
  }, [singleDomainThreats, mode, uniqueDomainThreats]);

  const resetForm = () => {
    handleClose();

    setSingleDomainThreats({
      protectedAssetId: '',
      protectedAssetName: '',
      companyId: '',
      threatName: '',
      createThreats: [],
    });

    setUniqueDomainThreats([
      {
        protectedAssetId: '',
        protectedAssetName: '',
        companyId: '',
        threatName: '',
        createThreats: [],
      },
    ]);

    setErrors({
      singleDomain: '',
      singleThreats: '',
      uniqueThreats: [],
    });

    setMode('single');
  };

  const handleSingleThreatDomainChange = useCallback(
    (value: any) => {
      if (!value) {
        setSingleDomainThreats({
          ...singleDomainThreats,
          protectedAssetName: '',
        });
      }
      const protectedDomain = protectedDomains.find(
        (item: any) => item.Domain.Name === value,
      );
      if (protectedDomain) {
        setSingleDomainThreats((prev: any) => ({
          ...prev,
          protectedAssetName: value,
          protectedAssetId: protectedDomain?.Domain?.Id,
          companyId: protectedDomain?.Company?.Id,
        }));
      }
    },
    [protectedDomains],
  );

  const handleUniqueThreatDomainChange = useCallback(
    (value: any, index: number) => {
      if (!value) {
        setUniqueDomainThreats((prevThreats: DomainThreats[]) =>
          prevThreats.map((threat, i) =>
            i === index
              ? {
                  ...threat,
                  protectedAssetName: '',
                  protectedAssetId: '',
                  companyId: '',
                }
              : threat,
          ),
        );
        return;
      }
      const protectedDomain = protectedDomains.find(
        (item: any) => item.Domain.Name === value,
      );
      if (protectedDomain) {
        setUniqueDomainThreats((prevThreats: DomainThreats[]) =>
          prevThreats.map((threat: DomainThreats, i: number) =>
            i === index
              ? {
                  ...threat,
                  protectedAssetName: value,
                  protectedAssetId: protectedDomain?.Domain?.Id,
                  companyId: protectedDomain?.Company?.Id,
                }
              : threat,
          ),
        );
      }
    },
    [protectedDomains],
  );

  const handleAddRow = () => {
    setUniqueDomainThreats((prev: DomainThreats[]) => [
      ...prev,
      {
        protectedAssetId: '',
        protectedAssetName: '',
        companyId: '',
        threatName: '',
        createThreats: [],
      },
    ]);
  };

  const handleRemoveRow = (index: number) => {
    setUniqueDomainThreats((prevThreats: DomainThreats[]) =>
      prevThreats.filter((_, i) => i !== index),
    );

    setErrors((prevErrors: Errors) => ({
      ...prevErrors,
      uniqueThreats: prevErrors.uniqueThreats.filter(
        (_: any, i: number) => i !== index,
      ),
    }));
  };

  const handleCloseModal = () => {
    resetForm();
  };

  const isAddThreatsDisabled = useCallback(() => {
    const isSingleMode = mode === 'single';

    const isThreatsInvalid = (threats: DomainThreats[]) =>
      threats.some(
        threat =>
          !threat.protectedAssetName || threat.createThreats.length === 0,
      );

    if (isSingleMode) {
      return isThreatsInvalid([singleDomainThreats]);
    }

    return isThreatsInvalid(uniqueDomainThreats);
  }, [mode, singleDomainThreats, uniqueDomainThreats]);

  const renderSingleMode = () => (
    <>
      <StyledTypography>Protected Domain :</StyledTypography>
      <Autocomplete
        size="small"
        value={singleDomainThreats.protectedAssetName}
        onChange={(event, value) => handleSingleThreatDomainChange(value)}
        options={[
          ...new Set(
            protectedDomains
              ?.map((item: any) => item?.Domain?.Name)
              .sort((a: string, b: string) => a.localeCompare(b)) || [],
          ),
        ]}
        filterOptions={(options, { inputValue }) => {
          const lowerInput = inputValue?.toLowerCase() || '';

          return options
            .filter((option: any) => {
              const lowerOption = option.toLowerCase();
              return (
                lowerOption.startsWith(lowerInput) ||
                lowerOption.includes(`-${lowerInput}`)
              );
            })
            .slice(0, 100);
        }}
        openOnFocus
        renderInput={params => (
          <StyledBox>
            {isDomainRef.current && (
              <InfoTypography>
                Showing first 100 domains from the list
              </InfoTypography>
            )}
            <StyledTextField
              {...params}
              placeholder="Type or select the domain from the list"
              error={!!errors.singleDomain}
              onFocus={() => (isDomainRef.current = true)}
              onBlur={() => (isDomainRef.current = false)}
            />
          </StyledBox>
        )}
      />
      <StyledTypography>Threat Names :</StyledTypography>
      <MultiDomainInput
        placeholder="Type a domain and press enter"
        value={singleDomainThreats?.createThreats}
        onChange={newThreats =>
          setSingleDomainThreats({
            ...singleDomainThreats,
            createThreats: newThreats,
          })
        }
        error={!!errors?.singleThreats}
      />
    </>
  );

  const renderUniqueMode = () => {
    const handleDropdownOpen = (index: number) => {
      dropdownStates.current[index] = true;
    };

    const handleDropdownClose = (index: number) => {
      dropdownStates.current[index] = false;
    };

    return (
      <>
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Domain</TableCell>
                <TableCell>Threat Name(s)</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uniqueDomainThreats.map(
                (threat: DomainThreats, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ width: '40%', verticalAlign: 'top' }}>
                      <StyledBox sx={{ width: '100%' }}>
                        <Autocomplete
                          size="small"
                          value={threat.protectedAssetName}
                          onChange={(event, value) =>
                            handleUniqueThreatDomainChange(value, index)
                          }
                          options={[
                            ...new Set(
                              protectedDomains
                                ?.map((item: any) => item?.Domain?.Name)
                                .sort((a: string, b: string) =>
                                  a.localeCompare(b),
                                ) || [],
                            ),
                          ]}
                          filterOptions={(options, { inputValue }) => {
                            const lowerInput = inputValue?.toLowerCase() || '';

                            return options
                              .filter((option: any) => {
                                const lowerOption = option.toLowerCase();
                                return (
                                  lowerOption.startsWith(lowerInput) ||
                                  lowerOption.includes(`-${lowerInput}`)
                                );
                              })
                              .slice(0, 100);
                          }}
                          onOpen={() => handleDropdownOpen(index)}
                          onClose={() => handleDropdownClose(index)}
                          renderInput={params => (
                            <StyledBox sx={{ position: 'relative' }}>
                              {dropdownStates.current[index] && (
                                <InfoBox>
                                  <InfoTypography>
                                    Showing first 100 domains from the list
                                  </InfoTypography>
                                </InfoBox>
                              )}
                              <StyledTextField
                                {...params}
                                placeholder="Type or select the domain from the list"
                                error={!!errors.uniqueThreats[index]?.domain}
                                helperText={errors.uniqueThreats[index]?.domain}
                              />
                            </StyledBox>
                          )}
                        />
                      </StyledBox>
                    </TableCell>
                    <TableCell sx={{ width: '50%' }}>
                      <MultiDomainInput
                        key={index}
                        value={threat.createThreats || []}
                        placeholder="Type a domain and press enter"
                        onChange={newThreats => {
                          setUniqueDomainThreats(prevThreats =>
                            prevThreats.map((threat, i) =>
                              i === index
                                ? { ...threat, createThreats: newThreats }
                                : threat,
                            ),
                          );
                        }}
                        error={!!errors.uniqueThreats[index]?.threats}
                      />
                    </TableCell>

                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <IconButton
                        onClick={() => handleRemoveRow(index)}
                        sx={{ color: 'error.main', fontSize: 30 }}
                        title="Remove Row">
                        <RemoveCircle />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <StyledButton
            onClick={handleAddRow}
            variant="outlined"
            color="primary"
            label="Add Row"
          />
        </Box>
      </>
    );
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <ModalWrapper data-testid="create-threat-modal">
        <CloseButton
          data-testid="close-threat-modal"
          onClick={handleCloseModal}>
          <HighlightOff />
        </CloseButton>
        <Typography variant="h6" component="h2">
          Add Threat Details
        </Typography>
        <RadioGroup
          row
          value={mode}
          onChange={e => setMode(e.target.value as AddThreatsMode)}
          sx={{ mt: 2, mb: 2 }}>
          <FormControlLabel
            value="single"
            control={<Radio />}
            label="Single Domain for All"
          />
          <FormControlLabel
            value="unique"
            control={<Radio />}
            label="Unique Domains for Each"
          />
        </RadioGroup>

        {mode === 'single' ? renderSingleMode() : renderUniqueMode()}

        <ButtonWrapper>
          <Button
            data-testid="cancel-threat-modal"
            onClick={handleCloseModal}
            variant="outlined"
            color="secondary"
            label="Cancel"
          />
          <GradientButton
            data-testid="submit-threat"
            onClick={handleAddThreats}
            label="Add Threats"
            type="submit"
            disabled={isAddThreatsDisabled()}
          />
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default CreateThreatModel;
