import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BulkOperationsState, BulkOperationItem } from './types';

const initialState: BulkOperationsState = {
  pendingOperations: {}
};

const bulkOperationsSlice = createSlice({
  name: 'bulkOperations',
  initialState,
  reducers: {
    addBulkOperation: (state, action: PayloadAction<BulkOperationItem[]>) => {
      console.log('Adding bulk operations:', action.payload);
      action.payload.forEach(operation => {
        state.pendingOperations[operation.threatId] = operation;
      });
      console.log('New state after adding:', state.pendingOperations);
    },
    removeBulkOperation: (state, action: PayloadAction<number>) => {
      delete state.pendingOperations[action.payload];
    },
    setBulkOperationError: (state, action: PayloadAction<{
      threatId: number;
      errorMessage: string;
    }>) => {
      const operation = state.pendingOperations[action.payload.threatId];
      if (operation) {
        operation.hasError = true;
        operation.errorMessage = action.payload.errorMessage;
      }
    },
    clearAllOperations: (state) => {
      state.pendingOperations = {};
    }
  }
});

export const { 
  addBulkOperation, 
  removeBulkOperation, 
  setBulkOperationError,
  clearAllOperations
} = bulkOperationsSlice.actions;

export default bulkOperationsSlice.reducer;
