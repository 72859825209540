import { styled } from '@mui/material/styles';
import { default as SimpleButton } from '@mui/material/Button';
import { ButtonProps } from '../../helpers/types';

const CustomButton = styled(SimpleButton)<ButtonProps>(
  ({ disabled, variant, theme, size }) => ({
    textTransform: 'none',
    borderRadius: 6,
    width: 'fit-content',
    height: 'fit-content',
    color: theme.palette.text.button,
    fontSize: 12,
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,
    lineHeight: '21px',
    boxShadow: '0px 21px 34px 16px rgba(193, 193, 193, 0.16)',
    background: variant === 'contained' ? '#18CDCD' : '#FFFFFF',
    minWidth: size === 'small' ? '38px' : 'inherit',
    '&.MuiButton-root': {
      padding: size === 'small' ? '4px 8px' : '6px 19px',
    },
    '&:hover': {
      boxShadow: 'none',
      background: disabled
        ? '#D0D5DD' // Background color when disabled
        : variant === 'contained'
        ? '#018184' // primary dark color for contained
        : variant === 'outlined'
        ? '#18CDCD' // primary for outlined
        : '#f5f5f5', // light gray for text variant
    },
    '&.MuiButton-root.Mui-disabled': {
      color: theme.palette.text.tertiary,
      boxShadow: 'none',
    },
  }),
);


const Button = ({
  variant,
  loading,
  label,
  disabled,
  onClick,
  style,
  size,
  type,
  children,
  startIcon,
  endIcon,
  sx,
  color,
  ...props
}: ButtonProps) => {
  return (
    <CustomButton
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      size={size}
      endIcon={endIcon}
      startIcon={startIcon}
      style={style}
      sx={sx}
      type={type}
      {...props}>
      {loading ? 'Loading...' : label}
      {children}
    </CustomButton>
  );
};

export default Button;
