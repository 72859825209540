import { Notification } from '../../helpers/types';
import { ADD_NOTIFICATION } from '../constant';

export const addNotification = (notificationData: Notification) => ({
  type: ADD_NOTIFICATION,
  payload: notificationData,
});

export const clearAllNotifications = () => ({
  type: 'CLEAR_ALL_NOTIFICATIONS'
});
