import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Content,
  ContentWrapper,
  HorizontalLine,
  InfoWrapper,
  StyledStrong,
  CompaniesMethod,
  HubspotLink,
  ErrorMessage,
  CustomOpenInNewIcon,
} from './Style/CompanyCreationV2.style';
import Title from '../../components/Title/Title';
import { Button, Input } from '../../components';
import { CompanyInformationProps } from '../../helpers/types';
import OpenInNew from '../../assets/Images/OpenInNew.svg'
import { REACT_APP_HUBSPOT_ID } from '../../utils/envVar';

const CompanyInformation: React.FC<CompanyInformationProps> = ({
  companyName,
  setCompanyName,
  hubspotId,
  setHubspotId,
  attackCostSavings,
  setAttackCostSavings,
  errors,
  handleSubmit,
  handleBlur,
  isPreCrimeBrand,
}) => {
  const { id } = useParams();
  const invalidFields = Object.keys(errors)
    .filter(field => errors[field as keyof typeof errors])
    .map(field =>
      field
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^./, str => str.toUpperCase()),
    );

  const errorMessage = `Validation errors pending to be resolved for: ${invalidFields.join(
    ', ',
  )}`;

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const companyData = { companyName, hubspotId, attackCostSavings };
    handleSubmit(companyData);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Container>
        <Content>
          <ContentWrapper>
            <Title size={'medium'} title="Company Information" />
          </ContentWrapper>
          <HorizontalLine />
          <InfoWrapper width={'45%'}>
            <CompaniesMethod>
              <StyledStrong>Company Name*</StyledStrong>
              <Input
                size="small"
                placeholder="Enter company name"
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
                error={Boolean(errors.companyName)}
                onBlur={() => handleBlur?.('companyName')}
                helperText={errors.companyName || ''}
              />
            </CompaniesMethod>

            <CompaniesMethod>
              <StyledStrong
                sx={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                HubSpot ID
                {hubspotId ? (
                  <HubspotLink
                    to={`https://app.hubspot.com/contacts/${REACT_APP_HUBSPOT_ID}/record/0-2/${hubspotId}/`}
                    target="_blank"
                    rel="noopener noreferrer">
                    See in HubSpot
                    <CustomOpenInNewIcon src={OpenInNew} alt="Open in new" />
                  </HubspotLink>
                ) : null}
              </StyledStrong>
              <Input
                size="small"
                placeholder="Enter HubSpot ID"
                value={hubspotId}
                type="number"
                onWheel={e => {
                  (e.target as HTMLElement).blur();
                }}
                onChange={e => {
                  const value = e.target.value;
                  const numericValue = parseFloat(value);
                  if (!value) {
                    setHubspotId(numericValue);
                    return;
                  }

                  if (numericValue >= 0) {
                    setHubspotId(numericValue);
                  }
                }}
                onBlur={() => handleBlur?.('hubspotId')}
                error={Boolean(errors.hubspotId)}
                helperText={errors.hubspotId ?? ''}
              />
            </CompaniesMethod>

            <CompaniesMethod>
              <StyledStrong sx={{ display: 'flex', gap: '5px' }}>
                Attack Cost savings ($)
                {isPreCrimeBrand && '*'}
              </StyledStrong>
              <Input
                size="small"
                placeholder="Enter attack cost savings"
                value={attackCostSavings ?? ''}
                type="number"
                onWheel={e => (e.target as HTMLElement).blur()}
                onChange={e => {
                  const value = e.target.value;
                  if (value === '') {
                    setAttackCostSavings(null);
                    return;
                  }
                  const numericValue = parseFloat(value);
                  if (!isNaN(numericValue) && numericValue >= 0) {
                    setAttackCostSavings(numericValue);
                  }
                }}
                onBlur={() => handleBlur?.('attackCostSavings')}
                error={Boolean(errors.attackCostSavings)}
                helperText={errors.attackCostSavings || ''}
              />
            </CompaniesMethod>
          </InfoWrapper>
        </Content>
        <Content sx={{ alignItems: 'end', gap: '3px' }}>
          <Button
            type="submit"
            data-test-id="save-new-company-button"
            variant="contained"
            sx={{ height: 34 }}
            disabled={invalidFields?.length > 0}>
            {id ? 'Update information' : 'Save new company'}
          </Button>
          {invalidFields?.length > 0 && (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          )}
        </Content>
      </Container>
    </form>
  );
};

export default CompanyInformation;
