import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material';

const CustomInput = styled(TextField)<TextFieldProps>(({ theme }) => ({
  width: '100%',
  borderRadius: 1,
  backgroundColor: theme.palette.background.paper,

  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    paddingLeft: 0,
    paddingRight: 6,
    height: 40,
    '& fieldset': {
      borderColor: theme.palette.border?.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },

  '& .MuiOutlinedInput-root.Mui-focused': {
    boxShadow: 'none',
  },

  '& input': {
    color: theme.palette.text.secondary,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    lineHeight: '14px',
    borderRadius: 8,
  },

  '& input::placeholder': {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    lineHeight: '16px',
    color: theme.palette.text.inputPlaceholder,
    opacity: 1,
  },

  '& .MuiFormHelperText-root': {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: 12,
    color: theme.palette.warning.main,
  },

  '& .MuiFormHelperText-root.Mui-error': {
    color: theme.palette.warning.main,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: 12,
    margin: '2px 0',
    padding: 0,
    letterSpacing: '-0.36px',
    lineHeight: '16px',
  },

  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

const Input = ({
  variant = 'outlined',
  label,
  name,
  type,
  onChange,
  error,
  value,
  helperText,
  size,
  placeholder,
  multiline,
  required,
  rows,
  onKeyDown,
  sx,
  ...props
}: TextFieldProps) => {
  return (
    <CustomInput
      name={name}
      label={label}
      variant={variant}
      value={value}
      error={error}
      helperText={helperText}
      type={type}
      onChange={onChange}
      placeholder={placeholder}
      size={size}
      multiline={multiline}
      rows={rows}
      sx={sx}
      required={required}
      onKeyDown={onKeyDown}
      {...props}
    />
  );
};

export default Input;
