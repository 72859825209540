import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import {
  Box,
  Typography,
  Grid,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import {
  addBulkOperation,
  removeBulkOperation,
  setBulkOperationError,
} from '../../store/bulkOperations/slice';
import FilterCard from './FilterCard';
import {
  changeBulkStatusAction,
  getDomainsListAction,
  getThreatAction,
  resetThreatAction,
  setPageSize,
} from '../../store/threats/action';
import {
  defaultEndDate,
  defaultReviewStatusFilter,
  defaultServiceFilter,
  defaultSortBy,
  defaultSortOrder,
  defaultSourcesFilter,
  defaultStartDate,
  defaultStatusFilter,
  getDateOrDefault,
  getLabelsAndIds,
  PER_PAGE_RECORDS,
  threatsStatus,
} from '../../utils';
import {
  ThreatCardWrapper,
  TotalCard,
  TotalText,
  ThreatCardContainer,
  LoaderThreatTable,
  SelectedButton,
} from './Style/Home.style';
import {
  Button,
  SearchBar,
  StatusMenu,
  ConfirmationModal,
  ThreatCardV2,
  ThreatsSkeleton,
  Loader,
} from '../../components';
import {
  BulkThreatDataType,
  ThreatTypes,
  selectedThreatDataType,
} from '../../helpers/types';
import CreateThreatModel from './CreateThreatModel';
import { PAGE_NAME } from '../../utils/analytic';
import { setAnalyticObject } from '../../store/analytic/action';
import { PaginationStyle } from '../ThreadsDetails/Style/SingleThreat.style';
import AddCommonNotesModal from './AddCommonNotesModal';
import {
  getServiceAction,
} from '../../store/company/action';
import { getNotesValuesAction } from '../../store/noteComments/action';
import ThreatTable from '../../components/ThreatTable/ThreatTable';
import ThreatViewToggle from './ThreatViewToggle';
import {
  getUserTokenAction,
  setUserViewPreference,
} from '../../store/user/action';
import { REACT_APP_BASE_URL_WEBSOCKET } from '../../utils/envVar';
import {
  CHANGE_BULK_STATUS_FAILURE,
  CHANGE_STATUSLABEL_SUCCESS,
} from '../../store/constant';
import { getThreatDetailApi } from '../../store/threats';
import { addNotification } from '../../store/notification/action';
import ScrollToTopButton from '../../components/ScrollToTopButton/ScrollToTopButton';
import {
  setCompanyFilter,
  setExcludeCompany,
  setPickerEndDate,
  setPickerStartDate,
  setReviewStatusFilterValues,
  setServicesData,
  setSourcesData,
  setStatusFilterValues,
} from '../../store/filter/action';
import { useAuth0 } from '../../__mocks__/@auth0/auth0-react';

const Home = () => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedThreats, setSelectedThreats] = useState<any>([]); // TODO:
  const [openStatusMenu, setOpenStatusMenu] = useState<null | HTMLElement>(
    null,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [companyFilterValues, setCompanyFilterValues] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedBulkStatusLabel, setSelectedBulkStatusLabel] =
    useState<string>('');
  const [openAddThreatModal, setOpenAddThreatModal] = useState<boolean>(false);
  const [hasMoreThreats, setHasMoreThreats] = useState<boolean>(true);
  const [networkData, setNetworkData] = useState<any>({});
  const [openCommonThreatModal, setOpenCommonThreatModal] =
    useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>(defaultSortBy);
  const [sortOrder, setSortOrder] = useState<string>(defaultSortOrder);
  const [openSortBy, setOpenSortBy] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, isAuthenticated, getIdTokenClaims } = useAuth0();

  const { userData } = useSelector((state: any) => state.user);
  const pendingOperations = useSelector((state: any) => state.bulkOperations.pendingOperations);
  const {
    threatList: threats,
    pageSizePersistValues,
  } = useSelector((state: any) => state.threats);
  const {
    companyFilterData,
    companysExclude,
    getServicesData,
    statusFilterData,
    getSourcesData,
    getPickerStartDate,
    getPickerEndDate,
    reviewStatusFilterData,
  } = useSelector((state: any) => state.filterList);

  const currentViewPreference = useSelector(
    (state: any) => state?.user?.viewPreference,
  );

  const selectedCompanyLabels = getLabelsAndIds(companyFilterData, 'id');
  const selectedStatusLabels = getLabelsAndIds(statusFilterData, 'label');
  const selectedReviewStatusLabels = getLabelsAndIds(
    reviewStatusFilterData,
    'label',
  );
  const selectedServiceLabels = getLabelsAndIds(getServicesData, 'label');
  const selectedSourceLabels = getLabelsAndIds(getSourcesData, 'value');
  const selectedCompanyExcludeLabels = getLabelsAndIds(companysExclude, 'id');
  const getThreatActionProps = {
    currentPage,
    searchValue,
    selectedCompanyLabels,
    selectedStatusLabels,
  };

  const isCustomerReviewPage =
    location.pathname === '/threats/customer-review-requests';

  useEffect(() => {
    const params = {
      m: 5,
      d: 0,
    };
    dispatch(getUserTokenAction(params));
  }, []);

  useEffect(() => {
    if (isCustomerReviewPage) {
      dispatch(resetThreatAction());
      dispatch(setReviewStatusFilterValues(reviewStatusFilterData));
    } else if (location.pathname === '/dashboard') {
      dispatch(setStatusFilterValues(statusFilterData));
      dispatch(resetThreatAction());
    }
    fetchThreats(sortOrder, sortBy);
  }, [location.pathname, currentPage]);

  const wsMessageHandle = useCallback(
    (event: any) => {
      const wsResponse = JSON.parse(event.data);

      if (wsResponse?.channel === 'threat_status_update') {
        const errorStatus = !!wsResponse?.data?.error;
        console.log('Processing threat_status_update:', {
          errorStatus,
          data: wsResponse.data,
          currentPendingOps: pendingOperations 
        });

        if (errorStatus) {
          // Find all pending operations with matching threat name
          const matchingOperations = Object.entries(pendingOperations)
            .filter(([_, operation]: [string, any]) => 
              operation?.threatName === wsResponse?.data?.threat_name
          );

          if (matchingOperations.length > 0) {
            // Mark operations as errored - they'll be shown in the list
            // because of our filtering logic
            matchingOperations.forEach(([threatId, operation]) => {
              dispatch(setBulkOperationError({
                threatId: Number(threatId),
                errorMessage: wsResponse.data.error || 'Unknown error occurred'
              }));
            });
          } else {
            // If no matching operations found, create a new one
            const matchingThreat = threats?.items?.find((threat: any) => 
              threat.threat_name === wsResponse?.data?.threat_name
            );

            if (matchingThreat) {
              dispatch(addBulkOperation([{
                threatId: matchingThreat.threat_id,
                threatName: matchingThreat.threat_name,
                startedAt: new Date().toISOString(),
                hasError: true,
                errorMessage: wsResponse.data.error,
                companyId: matchingThreat.company_id,
                domainId: matchingThreat.protected_asset_id,
                originalData: matchingThreat,
                expectedStatus: matchingThreat.identification_status.value
              }]));
            }
          }

          dispatch({
            type: CHANGE_BULK_STATUS_FAILURE,
            payload: wsResponse?.data,
          });

        } else {
          // For success case
          dispatch(addNotification({
            thread_name: wsResponse?.data?.threat_name,
            status: wsResponse?.data?.status || '-',
            userId: wsResponse?.data?.user_id,
            date: wsResponse?.data?.timestamp || '-',
            errorMessage: null,
            isError: false
          }));

          dispatch(removeBulkOperation(wsResponse.data.threat_id));
          dispatch({
            type: CHANGE_STATUSLABEL_SUCCESS,
            payload: wsResponse?.data,
          });
        }
      }

      if (wsResponse?.channel === 'asset_network_data') {
        setNetworkData((prevState: any) => ({
          ...prevState,
          [wsResponse?.data?.asset_id]: {
            ...prevState[wsResponse?.data?.asset_id],
            loader: false,
            ssl: wsResponse?.data?.data?.ssl_certificate ?? null,
            whois: wsResponse?.data?.data?.domain_information ?? null,
            whois_loading: wsResponse?.data?.whois_loading || false,
            ssl_loading: wsResponse?.data?.ssl_loading || false,
            ...(wsResponse?.data?.error && {
              ssl: null,
              whois: null,
              whois_loading: false,
              ssl_loading: false,
            }),
          },
        }));
      }
    },
    [threats.items, pendingOperations],
  );

  useEffect(() => {
    let ws: WebSocket | null = null;
    let heartbeatTimeout: NodeJS.Timeout | null = null;
    let reconnectTimeout: NodeJS.Timeout | null = null;
    let lastHeartbeat = Date.now();
    const RECONNECT_INTERVAL = 5000;
    const HEARTBEAT_TIMEOUT = 20000; // 20 seconds

    const sendMessageToSentry = () => {
      Sentry.captureMessage('WebSocket connection closed unexpectedly', {
        level: 'error',
        extra: {
          timestamp: new Date().toUTCString()
        }
      });
    }
    // Notes: I added console log for testing we'll remove it later.
    const checkHeartbeat = () => {
      const now = Date.now();
      if (now - lastHeartbeat > HEARTBEAT_TIMEOUT) {
        console.log('No heartbeat received for 20s, sending ping...');
        try {
          ws?.send(
            JSON.stringify({
              channel: 'ping',
              data: {
                timestamp: dayjs(Date.now()).format('YYYY-MM-DD HH:mm:ss')
              },
            }),
          );

          // Wait 5 seconds for pong response
          setTimeout(() => {
            if (Date.now() - lastHeartbeat > HEARTBEAT_TIMEOUT + RECONNECT_INTERVAL) {
              console.log('No pong received, forcing reconnection...');
              if (ws) {
                ws.onclose = null; // Prevent normal onclose handler
                ws.close();
                // Report unexpected closures to Sentry
                sendMessageToSentry();
                connect(); // Immediate reconnect
              }
            }
          }, RECONNECT_INTERVAL);
        } catch (error) {
          console.error('Failed to send ping, socket may be dead:', error);
          // Force reconnection on send failure
          if (ws) {
            ws.onclose = null; // Prevent normal onclose handler
            ws.close();
            // Report unexpected closures to Sentry
            sendMessageToSentry();
            connect(); // Immediate reconnect
          }
        }
      }
    };

    const connect = async () => {
      try {
        if (heartbeatTimeout) {
          clearInterval(heartbeatTimeout);
        }
        if (reconnectTimeout) {
          clearTimeout(reconnectTimeout);
        }

        const token: any = await getIdTokenClaims();

        if (ws) {
          ws.close();
        }

        ws = new WebSocket(
          `${REACT_APP_BASE_URL_WEBSOCKET}?bearer=${token?.__raw}`,
        );

        ws.onopen = () => {
          console.log('WebSocket Connected');
          lastHeartbeat = Date.now();

          // Start heartbeat check
          heartbeatTimeout = setInterval(checkHeartbeat, RECONNECT_INTERVAL);
        };

        ws.onmessage = event => {
          const data = JSON.parse(event.data);

          // Update lastHeartbeat time when receiving heartbeat or pong
          if (data.channel === 'heartbeat' || data.channel === 'pong') {
            lastHeartbeat = Date.now();
            console.log(`Received ${data.channel}, updated lastHeartbeat`);
          }

          wsMessageHandle(event);
        };

        ws.onclose = () => {
          console.log('WebSocket Disconnected');
          if (heartbeatTimeout) {
            clearInterval(heartbeatTimeout);
          }

          // Schedule reconnection only if onclose wasn't nullified
          if (ws?.onclose) {
            reconnectTimeout = setTimeout(connect, RECONNECT_INTERVAL);
          }
        };

        ws.onerror = () => {
          ws?.close();
        };

      } catch (error) {
        if (reconnectTimeout) {
          clearTimeout(reconnectTimeout);
        }
        reconnectTimeout = setTimeout(connect, RECONNECT_INTERVAL);
      }
    };

    if (isAuthenticated) {
      connect();
    }

    return () => {
      if (ws) {
        ws.close();
      }
      if (heartbeatTimeout) {
        clearInterval(heartbeatTimeout);
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
    };
  }, [isAuthenticated, getIdTokenClaims]);

  useEffect(() => {
    if (!threats?.loading && threats?.items?.length > 0) {
      setNetworkData((prevNetworkData: any) => {
        const updatedNetworkData = { ...prevNetworkData };

        threats?.items?.forEach((item: { threat_id: string | number }) => {
          if (!updatedNetworkData[item?.threat_id]) {
            updatedNetworkData[item?.threat_id] = {
              ssl: null,
              whois: null,
              ssl_loading: true,
              whois_loading: true,
            };
          }
        });

        return updatedNetworkData;
      });
    }
  }, [threats]);

  const onClickRefreshNetworkData = (threat_id: number) => {
    setNetworkData((prevState: any) => ({
      ...prevState,
      [threat_id]: {
        ...prevState[threat_id],
        whois_loading: true,
        ssl_loading: true,
        ssl: null,
        whois: null,
      },
    }));
  };

  const fetchThreats = (
    sortOrder: string = defaultSortOrder,
    sortBy: string = defaultSortBy,
    size = pageSizePersistValues || PER_PAGE_RECORDS,
    page = currentPage
  ) => {
    const params = {
      from: getDateOrDefault(getPickerStartDate, defaultStartDate),
      until: getDateOrDefault(getPickerEndDate, defaultEndDate),
      status: isCustomerReviewPage
        ? selectedReviewStatusLabels
        : selectedStatusLabels,
      threat_name: searchValue,
      company_ids: selectedCompanyLabels,
      service: selectedServiceLabels,
      sort: sortBy,
      order: sortOrder,
      page: currentPage,
      size,
      sources: selectedSourceLabels,
      excludeCompanysId: selectedCompanyExcludeLabels,
    };
    dispatch(getThreatAction(params));
    setSelectedThreats([]);
  };

  useEffect(() => {
    dispatch(getNotesValuesAction());
    dispatch(getServiceAction());
  }, []);

  const handleSortBy = (
    order: string | undefined,
    sortBy: string | undefined,
  ) => {
    if (order && sortBy) {
      setSortOrder(order);
      setSortBy(sortBy);
      fetchThreats(order, sortBy);
    }
    setOpenSortBy(null);
  };

  const handleUpdatedASCE = () => handleSortBy('ASCENDING', 'updated_at');
  const handleUpdatedDESC = () => handleSortBy('DESCENDING', 'updated_at');
  const handleDetectedASCE = () => handleSortBy('ASCENDING', 'detected_at');
  const handleDetectedDESC = () => handleSortBy('DESCENDING', 'detected_at');

  const handleApplyFilter = () => {
    dispatch(resetThreatAction());
    setCurrentPage(1);
    setHasMoreThreats(true);
    setSelectedThreats([]);
    dispatch(
      getThreatAction({
        from: getDateOrDefault(getPickerStartDate, defaultStartDate),
        until: getDateOrDefault(getPickerEndDate, defaultEndDate),
        status: isCustomerReviewPage
          ? selectedReviewStatusLabels
          : selectedStatusLabels,
        threat_name: searchValue,
        company_ids: selectedCompanyLabels,
        service: selectedServiceLabels,
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: pageSizePersistValues || PER_PAGE_RECORDS,
        sources: selectedSourceLabels,
        excludeCompanysId: selectedCompanyExcludeLabels,
      }),
    );
  };
  const handleResetFilter = () => {
    const defaultStatus = isCustomerReviewPage
      ? defaultReviewStatusFilter
      : defaultStatusFilter;

    if (isCustomerReviewPage) {
      dispatch(setReviewStatusFilterValues(defaultReviewStatusFilter));
    } else if (location.pathname === '/dashboard') {
      dispatch(setStatusFilterValues(defaultStatusFilter));
    }

    dispatch(setPickerStartDate(defaultStartDate));
    dispatch(setPickerEndDate(defaultEndDate));
    dispatch(setCompanyFilter([]));
    dispatch(setExcludeCompany([]));
    setSelectedThreats([]);
    setSearchValue('');
    setCompanyFilterValues([]);
    dispatch(setServicesData(defaultServiceFilter));
    dispatch(setSourcesData(defaultSourcesFilter));
    setCurrentPage(1);
    dispatch(resetThreatAction());
    dispatch(
      getThreatAction({
        from: getDateOrDefault(defaultStartDate, getPickerStartDate),
        until: getDateOrDefault(defaultEndDate, getPickerEndDate),
        status: defaultStatus?.map(status => status?.label),
        company_ids: 0,
        threat_name: '',
        service: defaultServiceFilter?.map(service => service?.label),
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: pageSizePersistValues || PER_PAGE_RECORDS,
        sources: defaultSourcesFilter?.map(sources => sources.value),
      }),
    );
  };

  const handleSelectAll = useCallback(() => {
    console.log('Current threats:', threats?.items);
    console.log('Current pendingOperations:', pendingOperations);

    const visibleThreats = [
      // Get errored threats from pendingOperations
      ...Object.values(pendingOperations)
        .filter((op: any) => {
          if (!op.hasError || !op.originalData) {
            console.log('Filtered out pending operation:', op);
            return false;
          }
          return true;
        })
        .map((op: any) => op.originalData),
      // Get regular threats that aren't already shown as errored
      ...(threats?.items || [])
        .filter((item: any) => {
        if (!item) {
          console.log('Filtered out null/undefined threat item');
          return false;
        }
        if (Object.values(pendingOperations)
          .find((op: any) => op.hasError && op.threatId === item.threat_id)) {
          console.log('Filtered out threat with pending error:', item);
          return false;
        }
        return true;
        })
    ];

    if (selectedThreats?.length === visibleThreats.length) {
      setSelectedThreats([]);
    } else {
      const newSelectedThreats = visibleThreats
        .filter((threat: ThreatTypes) => 
          // Add validation to ensure all required properties exist
          threat?.protected_asset_name &&
          threat?.threat_name &&
          threat?.threat_id &&
          threat?.company_id &&
          threat?.protected_asset_id &&
          threat?.identification_status?.value
        )
        .map((threat: ThreatTypes) => ({
          domain: threat.protected_asset_name,
          threat: threat.threat_name,
          threatId: threat.threat_id,
          companyId: threat.company_id,
          domainId: threat.protected_asset_id,
          currentStatus: threat.identification_status.value,
        }));
      setSelectedThreats(newSelectedThreats || []);
    }
  }, [selectedThreats?.length, threats?.items, pendingOperations]);

  const onChangeSatatuses = (
    e: React.SyntheticEvent,
    value: { label: string }[],
  ) => {
    if (isCustomerReviewPage) {
      dispatch(setReviewStatusFilterValues(value));
    } else if (location.pathname === '/dashboard') {
      dispatch(setStatusFilterValues(value));
    }
  };

  const onChangeCompany = (e: React.SyntheticEvent, value: string[]) => {
    dispatch(setCompanyFilter(value));
  };

  const onChangeExcludeCompany = (e: React.SyntheticEvent, value: string[]) => {
    dispatch(setExcludeCompany(value));
  };

  const onChangeService = (
    e: React.SyntheticEvent,
    value: { label: string }[],
  ) => {
    dispatch(setServicesData(value));
  };

  const onChangeSource = (
    e: React.SyntheticEvent,
    value: { label: string; value: string }[],
  ) => {
    dispatch(setSourcesData(value));
  };

  const onChangeDate = (value: any) => {
    if (value) {
      const [start, end] = value;
      const formattedStartDate = start || '';
      const formattedEndDate = end || '';

      dispatch(setPickerStartDate(formattedStartDate));
      dispatch(setPickerEndDate(formattedEndDate));
    } else {
      dispatch(setPickerStartDate(''));
      dispatch(setPickerEndDate(''));
    }
  };

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenStatusMenu(event.currentTarget);
  };

  const handleCloseStatusMenu = () => {
    setOpenStatusMenu(null);
  };

  const handleConfirmModal = (value: string) => {
    setSelectedBulkStatusLabel(value);
    setOpenStatusMenu(null);
    setOpenConfirmModal(true);
  };

  const updateBulkThreatsStatus = () => {
    console.log('Starting bulk update with threats:', selectedThreats);

    setOpenConfirmModal(false);
    const analytic = {
      pageName: PAGE_NAME.HOME_PAGE,
      user: { email: user?.email, name: user?.name },
      isBulkUpdate: true,
    };
    dispatch(setAnalyticObject(analytic));

    // Create bulk operations records
    const bulkOperations = selectedThreats.map((threat: { 
        threatId: number;
        threat: string;
        companyId: number;
        domainId: number;
        status: string;
      }) => ({
        threatId: threat.threatId,
        threatName: threat.threat,
        startedAt: new Date().toISOString(),
        expectedStatus: selectedBulkStatusLabel,
        companyId: threat.companyId,
        domainId: threat.domainId,
        originalData: threats.items.find((item: { threat_id: number }) => item.threat_id === threat.threatId)
    }));

    console.log('Created bulk operations:', bulkOperations);

    // First add the operations to state
    dispatch(addBulkOperation(bulkOperations));

    // Wait 500ms before hiding threats and fetching new ones
    setTimeout(() => {
      console.log('Dispatching changeBulkStatusAction with items:', items);
      dispatch(changeBulkStatusAction({ items }));

      // Calculate next page
      const nextPage = currentPage < count ? currentPage + 1 : 1;
      setCurrentPage(nextPage);

      // Fetch new threats to fill the gaps with new page
      fetchThreats(sortOrder, sortBy, pageSizePersistValues, nextPage);
    }, 500);
    const items = selectedThreats.map((item: BulkThreatDataType) => ({
      threat_name: item.threat,
      threatId: item.threatId,
      status: selectedBulkStatusLabel,
      user_id: userData?.data?.Id,
      company_id: item.companyId,
      process: 'w',
      priority: 1,
      protected_asset_id: item.domainId,
      domain: item.domain,
      currentStatus: item.status,
    }));

    dispatch(changeBulkStatusAction({ items }));
    setSelectedThreats([]);
  };

  const handleChangeSelectedThreats = useCallback(
    (threatsItem: selectedThreatDataType) => {
      setSelectedThreats((prevThreats: any[]) => {
        const indexOf = prevThreats.findIndex(
          (threat: { threatId: number; domainId: number; companyId: number }) =>
            threat.threatId === threatsItem.threatId &&
            threat.domainId === threatsItem.domainId &&
            threat.companyId === threatsItem.companyId,
        );
        if (indexOf > -1) {
          const newThreats = [...prevThreats];
          newThreats.splice(indexOf, 1);
          return newThreats;
        }
        return [...prevThreats, threatsItem];
      });
    },
    [],
  );

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchSubmit = () => {
    dispatch(
      getThreatAction({
        from: getDateOrDefault(getPickerStartDate, defaultStartDate),
        until: getDateOrDefault(getPickerEndDate, defaultEndDate),
        status: selectedStatusLabels,
        threat_name: searchValue,
        company_ids: 0,
        service: selectedServiceLabels,
        sort: 'updated_at',
        order: 'DESCENDING',
        page: 1,
        size: pageSizePersistValues,
        sources: selectedSourceLabels,
      }),
    );
    setSelectedThreats([]);
  };

  const handleAddThreatModal = () => {
    setOpenAddThreatModal(true);
    dispatch(getDomainsListAction(''));
  };

  const handleClearSearch = () => {
    dispatch(
      getThreatAction({
        from: getDateOrDefault(getPickerStartDate, defaultStartDate),
        until: getDateOrDefault(getPickerEndDate, defaultEndDate),
        status: isCustomerReviewPage
          ? selectedReviewStatusLabels
          : selectedStatusLabels,
        threat_name: '',
        company_ids: selectedCompanyLabels,
        service: selectedServiceLabels,
        sort: sortBy,
        order: sortOrder,
        page: 1,
        size: pageSizePersistValues || PER_PAGE_RECORDS,
        sources: selectedSourceLabels,
        excludeCompanysId: selectedCompanyExcludeLabels,
      }),
    );
    setSearchValue('');
    setSelectedThreats([]);
  };

  const count = Math.ceil(threats?.total / pageSizePersistValues);
  const handleChangePage = (event: any, value: number) => {
    setCurrentPage(value);
    setSelectedThreats([]);
  };

  const handlePageSizeChange = (event: SelectChangeEvent<string>) => {
    const selectedPageSize = Number(event?.target?.value);
    dispatch(setPageSize(selectedPageSize));
    setCurrentPage(1);
    fetchThreats('DESCENDING', 'updated_at', selectedPageSize);
  };

  const handleViewToggle = (newPreference: string) => {
    dispatch(setUserViewPreference(newPreference));
  };

  const isThreatSelected = useMemo(() => {
    return (threatData: selectedThreatDataType) => {
      const { companyId, domainId, threatId } = threatData;
      const indexOf = selectedThreats.findIndex(
        (threat: selectedThreatDataType) =>
          threat.threatId === threatId &&
          threat.domainId === domainId &&
          threat.companyId === companyId,
      );
      return indexOf > -1;
    };
  }, [selectedThreats]);

  return (
    <Box sx={{ padding: '24px' }}>
      <Grid container spacing={3}>
        <Grid item md={1} sx={{ display: 'flex', width: '100%' }}>
          <TotalCard>
            <Typography>Threats</Typography>
            <Typography sx={TotalText}>
              {threats?.loading ? <Loader size={16} /> : threats?.total}
            </Typography>
          </TotalCard>
        </Grid>
        <Grid item md={11}>
          <FilterCard
            onChangeSatatuses={onChangeSatatuses}
            onChangeCompany={onChangeCompany}
            onChangeService={onChangeService}
            onChangeDate={onChangeDate}
            handleApplyFilter={handleApplyFilter}
            handleResetFilter={handleResetFilter}
            statuesFilterValues={selectedStatusLabels}
            companyFilterValues={companyFilterValues}
            getServicesData={getServicesData}
            getPickerStartDate={getPickerStartDate}
            getPickerEndDate={getPickerEndDate}
            openSortBy={openSortBy}
            setOpenSortBy={setOpenSortBy}
            handleUpdatedASCE={handleUpdatedASCE}
            handleUpdatedDESC={handleUpdatedDESC}
            handleDetectedASCE={handleDetectedASCE}
            handleDetectedDESC={handleDetectedDESC}
            getSourcesData={getSourcesData}
            onChangeSource={onChangeSource}
            onChangeExcludeCompany={onChangeExcludeCompany}
            isCustomerReviewPage={isCustomerReviewPage}
          />
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', mt: 2 }}>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ThreatViewToggle
              viewPreference={currentViewPreference}
              handleViewToggle={handleViewToggle}
            />
            <SearchBar
              data-testid="threat-search-bar"
              size="small"
              handleChange={handleChangeSearch}
              handleSearch={handleSearchSubmit}
              searchValue={searchValue}
              handleClearSearch={handleClearSearch}
            />
          </Box>
          <Box
            sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}
            id="bulk">
            <SelectedButton
              data-testid={`${
                threats?.items?.length > 0 &&
                selectedThreats?.length === threats?.items?.length
                  ? 'clear-selected-threats-button'
                  : 'select-all-threats-button'
              }`}
              variant="text"
              size="medium"
              label={
                threats?.items?.length > 0 &&
                selectedThreats?.length === threats?.items?.length
                  ? `Clear selected(${selectedThreats?.length})`
                  : `Select all${
                      selectedThreats?.length
                        ? `(${selectedThreats.length})`
                        : ''
                    }`
              }
              disabled={threats.loading || !threats?.items?.length}
              onClick={handleSelectAll}
            />
            <Button
              data-testid="add-threat-button"
              variant="contained"
              onClick={handleAddThreatModal}>
              + Add Threat
            </Button>
            {selectedThreats?.length > 0 && (
              <>
                <Button
                  data-testid="change-threats-status-button"
                  variant="contained"
                  size="medium"
                  label="Change Status"
                  onClick={handleOpenStatusMenu}
                />
                <Button
                  data-testid="add-threats-notes-button"
                  variant="outlined"
                  size="medium"
                  label="Add Notes"
                  onClick={() => setOpenCommonThreatModal(true)}
                />
              </>
            )}
          </Box>
        </Grid>
      </Box>

      <Box>
        {currentViewPreference === 'table' ? (
          <ThreatCardContainer data-testid="threats-grid-container">
            {(threats?.loading || threats?.isFetching) ? (
              <LoaderThreatTable>
                <Loader size={25} />
              </LoaderThreatTable>
            ) : (!threats?.items?.length && !threats.loading && !threats.isFetching) ? (
              <ThreatCardWrapper>No Data Found</ThreatCardWrapper>
            ) : (
              <ThreatTable
                threats={threats}
                handleChangeSelectedThreats={handleChangeSelectedThreats}
                isThreatSelected={isThreatSelected}
                getThreatActionProps={getThreatActionProps}
                threatNetworkData={networkData}
                userData={userData}
              />
            )}
            <PaginationStyle style={{ marginTop: '16px' }}>
              <Select
                data-testid="threats-page-size-select"
                value={String(pageSizePersistValues)}
                onChange={handlePageSizeChange}
                label="Size"
                fullWidth
                style={{ width: '80px', height: '35px', marginLeft: '20px' }}>
                <MenuItem value="10">10</MenuItem>{' '}
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Select>
              <Pagination
                data-testid="threats-pagination"
                count={count || 0}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
              />
            </PaginationStyle>
          </ThreatCardContainer>
        ) : (
          <ThreatCardContainer>
            {(threats.loading || threats.isFetching) ? (
              <ThreatsSkeleton />
            ) : (!threats?.items?.length && !threats.loading && !threats.isFetching) ? (
              <ThreatCardWrapper>No Data Found</ThreatCardWrapper>
            ) : (
              <Grid
                container
                spacing={2}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
                {[
                  // First show errored threats
                  ...Object.values(pendingOperations)
                    .filter((op: any) => op.hasError && op.originalData)
                    .map((op: any) => op.originalData),
                  // Then show regular threats
                ...(threats?.items || []).filter((item: any) => 
                  item && !Object.values(pendingOperations)
                    .find((op: any) => op.hasError && op.threatId === item.threat_id)
                )
              ].filter((item: ThreatTypes) => {
                    if (!item) return false;
                    const operation = pendingOperations[item.threat_id];
                    // Show the threat if:
                    // 1. It's not in pending operations
                    // 2. It's in pending operations but has errored
                    // 3. It's in pending operations but was just added (within last 500ms)
                return !operation || 
                      operation.hasError ||
                      (Date.now() - new Date(operation.startedAt).getTime() < 500);
                }).map((items: ThreatTypes, index: number) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                      <ThreatCardV2
                        items={items}
                        handleChangeSelectedThreats={handleChangeSelectedThreats}
                        isThreatSelected={isThreatSelected}
                        getThreatActionProps={getThreatActionProps}
                        threatNetworkData={networkData}
                        onClickRefreshNetworkData={onClickRefreshNetworkData}
                        userData={userData}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
            <PaginationStyle style={{ marginTop: '16px' }}>
              <Select
                value={String(pageSizePersistValues)}
                onChange={handlePageSizeChange}
                label="Size"
                fullWidth
                style={{ width: '80px', height: '35px', marginLeft: '20px' }}>
                <MenuItem value="10">10</MenuItem>{' '}
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Select>
              <Pagination
                count={count || 0}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
              />
            </PaginationStyle>
          </ThreatCardContainer>
        )}
        <ScrollToTopButton />
      </Box>

      <CreateThreatModel
        open={openAddThreatModal}
        handleClose={() => setOpenAddThreatModal(false)}
        userData={userData}
      />
      <AddCommonNotesModal
        open={openCommonThreatModal}
        handleClose={() => setOpenCommonThreatModal(false)}
        selectedThreats={selectedThreats}
      />
      <StatusMenu
        items={threatsStatus}
        openStatusMenu={openStatusMenu}
        closeStatusMenu={handleCloseStatusMenu}
        handleOnClick={handleConfirmModal}
      />
      <ConfirmationModal
        title="Confirm status"
        subTitle="Do you want to change the status?"
        confirmLabel="Update"
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
        handleUpdate={() => updateBulkThreatsStatus()}
      />
    </Box>
  );
};

export default Home;
