import { call, put, select } from 'redux-saga/effects';
import {
  ADD_CHILD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  ADD_COMPANY_SUCCESS,
  ADD_PROTECTED_DOMAINS_FAILURE,
  ADD_PROTECTED_DOMAINS_SUCCESS,
  DELETE_COMPANY_FAILURE,
  DELETE_COMPANY_SUCCESS,
  GET_COMPANYDETAIL_FAILURE,
  GET_COMPANYDETAIL_SUCCESS,
  GET_COMPANYLIST_FAILURE,
  GET_COMPANYLIST_SUCCESS,
  GET_COMPANY_FAILURE,
  GET_COMPANY_SUCCESS,
  GET_KEYWORDS_FAILURE,
  GET_KEYWORDS_SUCCESS,
  GET_SERVICE_FAILURE,
  GET_SERVICE_SUCCESS,
  UPDATE_COMPANY_DETAILS_FAILURE,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPDATE_COMPANY_INDUSTRY_FAILURE,
  UPDATE_COMPANY_INDUSTRY_SUCCESS,
  UPDATE_COMPANY_SERVICE_FAILURE,
  UPDATE_COMPANY_SERVICE_SUCCESS,
  DOMAIN_UNPROTECT_SUCCESS,
  DOMAIN_UNPROTECT_FAILURE,
  CHANGE_STATUS_DOMAIN_SUCCESS,
  CHANGE_STATUS_DOMAIN_FAILURE,
  GET_DOMAIN_KEYWORDS_SUCCESS,
  GET_DOMAIN_KEYWORDS_FAILURE,
  ADD_DOMAIN_KEYWORDS_FAILURE,
  ADD_DOMAIN_KEYWORDS_SUCCESS,
  UNSET_PARENT_COMPANY_SUCCESS,
  UNSET_PARENT_COMPANY_FAILURE,
  ADDINFORMATION_GENERIC_SUCCESS,
  ADDINFORMATION_GENERIC_FAILURE,
  UPDATE_DOMAIN_PROGRESS,
} from '../constant';
import {
  addChildCompanyApi,
  addCompanyApi,
  addDomainIdProtectApi,
  addProtectedDomainsApi,
  deleteCompanyApi,
  getCompanyApi,
  getCompanyDetailApi,
  getCompanyListApi,
  getKeywordsApi,
  getServiceApi,
  updateCompanyDetailsApi,
  updateCompanyIndustryApi,
  updateCompanyServiceApi,
  domainUnprotectApi,
  changeStatusDomainApi,
  getDomainKeywordsApi,
  addDomainKeywordsApi,
  unsetParentCompanyApi,
  addInformationGenericApi,
  getDomainsInfoApi,
} from '.';
import { showToast } from '../toaster/action';

export function* getCompanySaga(action: any): any {
  try {
    const response = yield call(getCompanyApi, action?.payload);
    yield put({
      type: GET_COMPANY_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_COMPANY_FAILURE,
      payload: error?.response?.data?.message || 'something went wrong!',
    });
    yield put(showToast(error?.message || 'Failed to get company!', 'error'));
  }
}

export function* getCompanyListSaga(action: any): any {
  try {
    const response = yield call(getCompanyListApi, action?.payload);
    yield put({
      type: GET_COMPANYLIST_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_COMPANYLIST_FAILURE,
      payload: error?.response?.data || 'something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Failed to get company list!', 'error'),
    );
  }
}

export function* deleteCompanySaga(action: any): any {
  try {
    const response = yield call(deleteCompanyApi, action?.payload?.id);
    const getCompanyListRes = yield call(getCompanyListApi, {
      d: 'y',
      u: 'n',
      s: 'y',
      h: action?.payload?.h,
      ipp: 10,
      pn: action?.payload?.pn,
      r: 'desc',
      m: 'created',
    });
    yield put({
      type: DELETE_COMPANY_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: GET_COMPANYLIST_SUCCESS,
      payload: getCompanyListRes?.data,
    });
  } catch (error: any) {
    yield put({
      type: DELETE_COMPANY_FAILURE,
      payload: error?.response?.data || 'something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Failed to delete company!', 'error'),
    );
  }
}

export function* addCompanySaga(action: any): any {
  try {
    const response = yield call(addCompanyApi, action?.payload);
    const currentCompanyList = yield select(
      state => state?.company?.getCompanyList?.data?.Companies || [],
    );
    const companyList = yield select(
      state => state?.company?.getCompany?.data || [],
    );

    const updatedCompanyList = [response?.data, ...currentCompanyList];
    const companyResponse = [response?.data, ...companyList];

    const companyListResponse = {
      ...currentCompanyList,
      Companies: updatedCompanyList,
    };

    yield put({ type: ADD_COMPANY_SUCCESS, payload: response?.data });
    yield put({ type: GET_COMPANYLIST_SUCCESS, payload: companyListResponse });
    yield put({ type: GET_COMPANY_SUCCESS, payload: companyResponse });
    yield put(showToast('Company Added successfully.', 'success'));
  } catch (error: any) {
    yield put({
      type: ADD_COMPANY_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(showToast(error?.message || 'Failed to add company!', 'error'));
  }
}

export function* addChildCompanySaga(action: any): any {
  try {
    const response = yield call(addChildCompanyApi, action?.payload);
    yield put({
      type: ADD_CHILD_COMPANY_SUCCESS,
      payload: response?.data,
    });
    const getCompanyListRes = yield call(getCompanyListApi, {
      d: 'n',
      u: 'n',
      s: 'y',
      h: '',
      ipp: 10,
      pn: 1,
      c: action?.payload?.parentId,
    });
    yield put({
      type: GET_COMPANYLIST_SUCCESS,
      payload: getCompanyListRes?.data,
    });
  } catch (error: any) {
    yield put({
      type: ADD_COMPANY_FAILURE,
      payload: error?.response?.data || 'something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Fail to add child company!', 'error'),
    );
  }
}

export function* unsetParentCompanySaga(action: any): any {
  try {
    const response = yield call(unsetParentCompanyApi, action?.payload);
    yield put({
      type: UNSET_PARENT_COMPANY_SUCCESS,
      payload: response?.data,
    });
    const getCompanyListRes = yield call(getCompanyListApi, {
      d: 'n',
      u: 'n',
      s: 'y',
      h: '',
      ipp: 10,
      pn: 1,
      c: action?.payload?.parentId,
    });
    yield put({
      type: GET_COMPANYLIST_SUCCESS,
      payload: getCompanyListRes?.data,
    });
  } catch (error: any) {
    yield put({
      type: UNSET_PARENT_COMPANY_FAILURE,
      payload: error?.response?.data || 'Something went wrong!',
    });
    yield put(showToast(error?.message || 'Something went wrong!', 'error'));
  }
}

export function* getCompanyDetailSaga(action: any): any {
  try {
    const response = yield call(getCompanyDetailApi, action?.payload);
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_COMPANYDETAIL_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Fail to get company detail!', 'error'),
    );
  }
}

function* handleDomainProtection(
  domain: any,
  index: number,
  total: number,
): any {
  try {
    const response = yield call(getDomainsInfoApi, domain?.name);
    const domainIdProtectPayload: any = {
      domainId: response?.data?.Id,
      companyId: domain?.companyId,
    };

    if (domain?.ownDomain === true) {
      domainIdProtectPayload.statusName = 'owned';
    }
    yield call(addDomainIdProtectApi, domainIdProtectPayload);
    yield put(
      showToast(
        `Domain id ${response?.data?.Id} added successfully for ${domain?.name}`,
        'success',
      ),
    );
    yield put({
      type: ADD_PROTECTED_DOMAINS_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: UPDATE_DOMAIN_PROGRESS,
      payload: { processed: index + 1, total },
    });
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.message || `An error occurred for ${domain?.name}`;

    if (error.response?.status === 400) {
      try {
        const response = yield call(addProtectedDomainsApi, domain?.name);
        yield put(
          showToast(
            `Domain protection added successfully for ${domain?.name}`,
            'success',
          ),
        );
        const domainIdProtectPayload: any = {
          domainId: response?.data?.Id,
          companyId: domain?.companyId,
        };

        if (domain?.ownDomain) {
          domainIdProtectPayload.statusName = 'owned';
        }

        yield call(addDomainIdProtectApi, domainIdProtectPayload);
        yield put(
          showToast(
            `Domain id ${response?.data?.Id} added successfully for ${domain?.name}`,
            'success',
          ),
        );
        yield put({
          type: ADD_PROTECTED_DOMAINS_SUCCESS,
          payload: response?.data,
        });
        yield put({
          type: UPDATE_DOMAIN_PROGRESS,
          payload: { processed: index + 1, total },
        });
      } catch (secondaryError: any) {
        yield put(
          showToast(
            secondaryError?.response?.data?.message ||
              `An error occurred while adding domain protection for ${domain?.name}`,
            'error',
          ),
        );
        yield put({
          type: ADD_PROTECTED_DOMAINS_FAILURE,
          payload:
            secondaryError?.response?.data?.message ||
            `An error occurred for ${domain?.name}`,
        });
      }
    } else {
      yield put({
        type: ADD_PROTECTED_DOMAINS_FAILURE,
        payload: errorMessage,
      });
      yield put(showToast(errorMessage, 'error'));
    }
  }
}

export function* addProtectedDomainsSaga(action: any): any {
  const domains = action?.payload?.domains;
  const totalDomains = domains?.length;

  yield put({
    type: UPDATE_DOMAIN_PROGRESS,
    payload: { processed: 0, total: totalDomains },
  });

  for (let index = 0; index < totalDomains; index++) {
    yield call(handleDomainProtection, domains[index], index, totalDomains);
  }

  const companyDetailResponse = yield call(getCompanyDetailApi, {
    id: domains[0]?.companyId,
  });
  yield put({
    type: GET_COMPANYDETAIL_SUCCESS,
    payload: companyDetailResponse?.data,
  });

  yield put({
    type: UPDATE_DOMAIN_PROGRESS,
    payload: { processed: 0, total: 0 },
  });
}

export function* domainUnprotectSaga(action: any): any {
  try {
    const response = yield call(domainUnprotectApi, {
      domainId: action?.payload?.domainId,
      companyId: action?.payload?.companyId,
    });
    yield put({
      type: DOMAIN_UNPROTECT_SUCCESS,
      payload: response?.data,
    });
    const getCompanyDetails = yield call(getCompanyDetailApi, {
      id: action?.payload?.companyId,
    });
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: getCompanyDetails?.data,
    });
  } catch (error: any) {
    yield put({
      type: DOMAIN_UNPROTECT_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(showToast(error?.message || 'Unprotected domain fail!', 'error'));
  }
}

export function* addInformationGenericSaga(action: any): any {
  try {
    const response = yield call(addInformationGenericApi, action?.payload);
    yield put({
      type: ADDINFORMATION_GENERIC_SUCCESS,
      payload: response?.data,
    });
    const getCompanyDetails = yield call(getCompanyDetailApi, {
      id: action?.payload?.c,
    });
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: getCompanyDetails?.data,
    });
  } catch (error: any) {
    yield put({
      type: ADDINFORMATION_GENERIC_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(
        error?.message || 'Failed to add information generic!',
        'error',
      ),
    );
  }
}

export function* changeStatusDomainSaga(action: any): any {
  try {
    const response = yield call(changeStatusDomainApi, action?.payload);
    yield put({
      type: CHANGE_STATUS_DOMAIN_SUCCESS,
      payload: response?.data,
    });
    const getCompanyDetails = yield call(getCompanyDetailApi, {
      id: action?.payload?.companyId,
    });
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: getCompanyDetails?.data,
    });
  } catch (error: any) {
    yield put({
      type: CHANGE_STATUS_DOMAIN_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Changed domain status failed!', 'error'),
    );
  }
}

export function* getDomainKeywordsSaga(action: any): any {
  try {
    const response = yield call(getDomainKeywordsApi, action?.payload);
    yield put({
      type: GET_DOMAIN_KEYWORDS_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_DOMAIN_KEYWORDS_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Failed to get domain keywords!', 'error'),
    );
  }
}

export function* addDomainKeywordsSaga(action: any): any {
  try {
    const response = yield call(addDomainKeywordsApi, action?.payload);
    yield put({
      type: ADD_DOMAIN_KEYWORDS_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: ADD_DOMAIN_KEYWORDS_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Failed to add domain keyword!', 'error'),
    );
  }
}

export function* getServiceSaga(action: any): any {
  try {
    const response = yield call(getServiceApi, action.payload);
    yield put({
      type: GET_SERVICE_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_SERVICE_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(showToast(error?.message || 'Get service failed!', 'error'));
  }
}

export function* updateCompanyDetailsSaga(action: any): any {
  try {
    const response = yield call(updateCompanyDetailsApi, action?.payload);
    yield put({
      type: UPDATE_COMPANY_DETAILS_SUCCESS,
      payload: response?.data,
    });
    yield put(showToast('Changes have been saved successfully.', 'success'));
    const getCompanyDetailRes = yield call(getCompanyDetailApi, {
      id: action?.payload?.id,
    });
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: getCompanyDetailRes?.data,
    });
  } catch (error: any) {
    yield put({
      type: UPDATE_COMPANY_DETAILS_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Failed to update company details!', 'error'),
    );
  }
}

export function* updateCompanyIndustrySaga(action: any): any {
  try {
    const response = yield call(updateCompanyIndustryApi, action?.payload);
    yield put({
      type: UPDATE_COMPANY_INDUSTRY_SUCCESS,
      payload: response?.data,
    });
    yield put(showToast('Changes have been saved successfully.', 'success'));
    const getCompanyDetailRes = yield call(getCompanyDetailApi, {
      id: action?.payload?.company,
    });
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: getCompanyDetailRes?.data,
    });
  } catch (error: any) {
    yield put({
      type: UPDATE_COMPANY_INDUSTRY_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(
      showToast(error?.message || 'Update company industry failed!', 'error'),
    );
  }
}

export function* updateCompanyServiceSaga(action: any): any {
  try {
    const response = yield call(updateCompanyServiceApi, action?.payload);
    yield put({
      type: UPDATE_COMPANY_SERVICE_SUCCESS,
      payload: response?.data,
    });
    const getCompanyDetailRes = yield call(getCompanyDetailApi, {
      id: action?.payload?.companyId,
    });
    yield put({
      type: GET_COMPANYDETAIL_SUCCESS,
      payload: getCompanyDetailRes?.data,
    });
  } catch (error: any) {
    yield put({
      type: UPDATE_COMPANY_SERVICE_FAILURE,
      payload: error?.response?.data?.message,
    });
    yield put(
      showToast(error?.message || 'Failed to update company service!', 'error'),
    );
  }
}

export function* getKeywordsSaga(action: any): any {
  try {
    const response = yield call(getKeywordsApi, action?.payload);
    yield put({
      type: GET_KEYWORDS_SUCCESS,
      payload: response?.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_KEYWORDS_FAILURE,
      payload: error?.response?.data?.message || 'Something went wrong!',
    });
    yield put(showToast(error?.message || 'Failed to get keywords!', 'error'));
  }
}
