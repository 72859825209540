import React from 'react';
import { TextField, Box } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import { SearchableDropDownProps } from '../../helpers/types';
import { StyledAutocomplete } from './Style/SearchableDropDown.style';

export interface OptionType {
  id?: number | string;
  label: string;
  value?: string;
  disabled?: boolean;
}

type SearchableDropDownPropsWithOption = SearchableDropDownProps & {
  items: OptionType[];
  selectedValues?: OptionType | OptionType[] | null;
  multiple?: boolean;
  dataTestid?: string;
  clearIcon?: any
};

const SearchableDropDown = ({
  items,
  size,
  placeholder,
  label,
  onChange,
  selectedValues,
  multiple,
  dataTestid,
  clearIcon
}: SearchableDropDownPropsWithOption) => {
  return (
    <StyledAutocomplete
      sx={{ width: 1 }}
      multiple={multiple}
      data-testid={dataTestid}
      options={items}
      getOptionLabel={(option) => option.label}
      disableCloseOnSelect
      onChange={onChange}
      value={selectedValues}
      isOptionEqualToValue={(option: OptionType, value) =>
        option.label === value.label
      }
      renderOption={(props, option: OptionType, state) => {
        const isDisabled = option.disabled;
        return (
          <Box
            component="li"
            {...props}
            key={`${option.id}${option.label}`}
            style={{
              textTransform: 'capitalize',
              pointerEvents: isDisabled ? 'none' : 'auto',
              color: isDisabled ? 'gray' : 'black',
            }}>
            {option.label}
          </Box>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          size={size}
          InputLabelProps={{                                                                                                
            sx: {                                                                                                           
                                                                                                                           
            }                                                                                                               
          }}
        />
      )}
      clearIcon={clearIcon}
    />
  );
};

export default SearchableDropDown;
