import React, { useCallback, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Skeleton,
  TableSortLabel,
  Box,
  IconButton,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ConfirmationModal, SelectOption } from '..';
import { formatedDate, threatsStatus } from '../../utils';
import { Span, ThreatDetails } from '../ThreatCard/ThreatCard.style';
import { setAnalyticObject } from '../../store/analytic/action';
import { PAGE_NAME } from '../../utils/analytic';
import { changeStatusLabelAction } from '../../store/threats/action';
import { ThreatTableProps } from '../../helpers/types';
import { ActionButtons, ThreatUrlCell } from '../Style/ThreatTable.style';
import { useAuth0 } from '../../__mocks__/@auth0/auth0-react';

const MIN_THREAT_NAME_LENGTH = 35;

const ThreatTable = ({
  threats,
  handleChangeSelectedThreats,
  isThreatSelected,
  threatNetworkData,
  userData,
}: ThreatTableProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedThreat, setSelectedThreat] = useState<any>(null);
  const [threatSort, setThreatSort] = useState<{
    columnKey: string;
    sortDirection: 'asc' | 'desc';
  } | null>(null);

  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { getCompany } = useSelector((state: any) => state.company);

  const findCompanyNameById = useCallback(
    (id: number) => {
      const company = getCompany?.data?.find(
        (company: { Id: number }) => company.Id === id,
      );
      return company ? company.Name : 'Unknown';
    },
    [getCompany?.data],
  );

  const handleChangeThreatStatus = (threat: any, newStatus: string) => {
    setOpenModal(true);
    setSelectedThreat({ ...threat, newStatus });
  };
  const updateSingleStatus = async () => {
    const {
      protected_asset_name,
      protected_asset_id,
      threat_name,
      threat_id,
      company_id,
      identification_status,
      newStatus,
    } = selectedThreat;
    const { email, name } = user || {};
    const analytic = {
      pageName: PAGE_NAME.HOME_PAGE,
      user: { email, name },
      isBulkUpdate: false,
    };
    await dispatch(setAnalyticObject(analytic));
    await dispatch(
      changeStatusLabelAction({
        threat_name,
        status: newStatus,
        user_id: userData?.data?.Id,
        company_id,
        protected_asset_id,
        protected_asset_name,
        threat_id,
        process: 'w',
        priority: 1,
        currentStatus: identification_status?.value,
      }),
    );
    setOpenModal(false);
  };
  const handleSort = (columnKey: string) => {
    setThreatSort(prevConfig => {
      const isSameColumn = prevConfig?.columnKey === columnKey;
      const nextSortDirection =
        isSameColumn && prevConfig?.sortDirection === 'asc' ? 'desc' : 'asc';
      return { columnKey, sortDirection: nextSortDirection };
    });
  };

  const sortItems = (
    items: any[],
    config: { columnKey: string; sortDirection: 'asc' | 'desc' } | null,
  ): any[] => {
    if (!config) return items;

    const { columnKey, sortDirection } = config;

    const getValue = (item: any, columnKey: string) => {
      if (
        columnKey.startsWith('sslData') ||
        columnKey.startsWith('domainInfo')
      ) {
        const threatId = item.threat_id;
        const nestedData = threatNetworkData[threatId];
        if (columnKey.startsWith('sslData')) {
          return nestedData?.ssl?.organisation ?? '';
        }
        if (columnKey.startsWith('domainInfo')) {
          const domainInfo = nestedData?.whois ?? {};
          if (columnKey === 'domainInfo.registrar') {
            return domainInfo.registrar ?? '';
          }
          if (columnKey === 'domainInfo.registration_date') {
            return domainInfo.registration_date ?? '';
          }
          if (columnKey === 'domainInfo.expiration_date') {
            return domainInfo.expiration_date ?? '';
          }
        }
      }
      return (
        columnKey.split('.').reduce((obj, prop) => obj?.[prop], item) ?? ''
      );
    };

    return [...items].sort((itemA, itemB) => {
      const valueA = getValue(itemA, columnKey);
      const valueB = getValue(itemB, columnKey);

      if (columnKey === 'score') {
        const scoreA = typeof valueA?.value === 'number' ? valueA.value : null;
        const scoreB = typeof valueB?.value === 'number' ? valueB.value : null;

        if (scoreA === null && scoreB === null) return 0;
        if (scoreA === null) return sortDirection === 'asc' ? -1 : 1;
        if (scoreB === null) return sortDirection === 'asc' ? 1 : -1;
        return sortDirection === 'asc' ? scoreA - scoreB : scoreB - scoreA;
      }

      if (columnKey === 'company_name') {
        const companyNameA = findCompanyNameById(itemA.company_id);
        const companyNameB = findCompanyNameById(itemB.company_id);
        return sortDirection === 'asc'
          ? companyNameA.localeCompare(companyNameB)
          : companyNameB.localeCompare(companyNameA);
      }

      const isValueAMissing = [null, '', '-'].includes(valueA);
      const isValueBMissing = [null, '', '-'].includes(valueB);

      if (isValueAMissing && !isValueBMissing)
        return sortDirection === 'asc' ? -1 : 1;
      if (!isValueAMissing && isValueBMissing)
        return sortDirection === 'asc' ? 1 : -1;
      if (isValueAMissing && isValueBMissing) return 0;

      if (columnKey.includes('date')) {
        const dateA = new Date(valueA);
        const dateB = new Date(valueB);
        return sortDirection === 'asc'
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      }

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
      }

      const stringA = String(valueA).toLowerCase();
      const stringB = String(valueB).toLowerCase();
      return sortDirection === 'asc'
        ? stringA.localeCompare(stringB)
        : stringB.localeCompare(stringA);
    });
  };

  const sortedItems = useMemo(
    () => sortItems(threats?.items || [], threatSort),
    [threatSort, threats],
  );

  const copyThreatName = (name: string) => {
    try {
      navigator.clipboard.writeText(name);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  const renderTableRow = (items: any, index: number) => {
    // Protect against undefined items
    if (!items?.threat_id) {
      return null;
    }

    // If the threat is in pending operations and hasn't errored, don't render it
    if (pendingOperations?.[items.threat_id]?.hasError === false) {
      return null;
    }

    const {
      threat_id: threatId,
      company_id,
      protected_asset_id,
      threat_name,
      protected_asset_name,
    } = items;

    const threatData = threatNetworkData?.[threatId];
    const domainInfo = threatData?.whois;
    const sslData = threatData?.ssl;

    const whoisoading = threatData?.whois_loading;
    const sslLoading = threatData?.ssl_loading;

    const onSelectThreat = () => {
      handleChangeSelectedThreats({
        domain: protected_asset_name,
        threat: threat_name,
        threatId,
        companyId: company_id,
        domainId: protected_asset_id,
        status: items.identification_status?.value,
      });
    };

    return (
      <TableRow
        key={`${index}${items.threat_id}`}
        sx={{
          backgroundColor: pendingOperations?.[items.threat_id]?.hasError
            ? 'rgba(255, 0, 0, 0.1)'
            : 'inherit',
        }}>
        <ThreatUrlCell>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}>
              <Checkbox
                data-testid="checkbox-table"
                checked={isThreatSelected({
                  threatId,
                  companyId: company_id,
                  domainId: protected_asset_id,
                })}
                onChange={() => onSelectThreat()}
                sx={{ paddingLeft: '0' }}
              />
              <ThreatDetails
                data-testid="table-threat-name"
                to={`/threats/${threat_name}/${protected_asset_name}/${company_id}?protected_id=${protected_asset_id}&threat_id=${threatId}`}
                target="_blank">
                {items?.threat_name?.length > MIN_THREAT_NAME_LENGTH
                  ? items.threat_name.substring(0, MIN_THREAT_NAME_LENGTH) +
                    '...'
                  : items.threat_name}
              </ThreatDetails>
            </Box>
            <ActionButtons className="action-buttons">
              <IconButton
                data-testid="table-copy-content"
                onClick={() => copyThreatName(items?.threat_name)}
                size="small"
                sx={{ padding: 0 }}>
                <ContentCopyIcon sx={{ height: 20, width: 20 }} />
              </IconButton>
            </ActionButtons>
          </Box>
        </ThreatUrlCell>
        <TableCell>{items?.protected_asset_name}</TableCell>
        <TableCell>{findCompanyNameById(company_id)}</TableCell>
        <TableCell>
          {items.isLoading ? (
            <Skeleton
              animation="wave"
              variant="text"
              sx={{ fontSize: '1rem' }}
              width={'100%'}
            />
          ) : (
            <SelectOption
              size="small"
              width="120px"
              value={items.identification_status?.value}
              options={threatsStatus.map(status => ({
                label: status,
                value: status,
              }))}
              onChange={(e: any) => {
                const newStatus = e.target.value;
                handleChangeThreatStatus(items, newStatus);
              }}
            />
          )}
        </TableCell>
        <TableCell>
          {items?.score?.value || <Span>Not scored yet</Span>}
        </TableCell>
        <TableCell>
          {sslLoading ? (
            <Skeleton animation="wave" variant="text" width="100%" />
          ) : (
            sslData?.organisation || <Span>No data available</Span>
          )}
        </TableCell>
        <TableCell>
          {whoisoading ? (
            <Skeleton animation="wave" variant="text" width="100%" />
          ) : (
            domainInfo?.registrar || <Span>No data available</Span>
          )}
        </TableCell>
        <TableCell>
          {whoisoading ? (
            <Skeleton animation="wave" variant="text" width="100%" />
          ) : domainInfo?.registration_date ? (
            `${formatedDate(domainInfo?.registration_date)} UTC`
          ) : (
            <Span>No data available</Span>
          )}
        </TableCell>
        <TableCell>
          {whoisoading ? (
            <Skeleton animation="wave" variant="text" width="100%" />
          ) : domainInfo?.expiration_date ? (
            `${formatedDate(domainInfo?.expiration_date)} UTC`
          ) : (
            <Span>No data available</Span>
          )}
        </TableCell>
        <TableCell>
          {items?.detection_date ? (
            `${formatedDate(items?.detection_date)} UTC`
          ) : (
            <Span>No data available</Span>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const pendingOperations = useSelector((state: any) => state.bulkOperations.pendingOperations);

  return (
    <TableContainer component={Paper} sx={{ 
        boxShadow: '0px 18px 32px 16px rgba(193, 193, 193, 0.16)',
      }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {[
              { label: 'Threat Name', key: 'threat_name' },
              { label: 'Target Domain', key: 'protected_asset_name' },
              { label: 'Company', key: 'company_name' },
              { label: 'Status', key: 'identification_status.value' },
              { label: 'CTI Score', key: 'score' },
              { label: 'SSL', key: 'sslData.organisation' },
              { label: 'Registrar', key: 'domainInfo.registrar' },
              { label: 'Registration', key: 'domainInfo.registration_date' },
              { label: 'Expiration', key: 'domainInfo.expiration_date' },
              { label: 'Detection', key: 'detection_date' },
            ].map(column => (
              <TableCell key={column.key}>
                <TableSortLabel
                  data-testid="table-sort-header-button"
                  active={threatSort?.columnKey === column.key}
                  direction={
                    threatSort?.columnKey === column.key
                      ? threatSort.sortDirection
                      : 'asc'
                  }
                  onClick={() => handleSort(column.key)}>
                  {column.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[
            // First show errored threats
            ...Object.values(pendingOperations)
              .filter((op: any) => op.hasError)
              .map((op: any) => op.originalData),
            // Then show regular threats
            ...sortedItems.filter((item: any) => 
              !Object.values(pendingOperations)
                .find((op: any) => op.hasError && op.threatId === item.threat_id)
            )
          ].map(renderTableRow)}
        </TableBody>
      </Table>
      <ConfirmationModal
        title="Confirm status"
        subTitle="Do you want to change the status?"
        confirmLabel="Update"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleUpdate={() => updateSingleStatus()}
      />
    </TableContainer>
  );
};

export default ThreatTable;
