import { styled } from '@mui/material';

const NoRecords = styled('span')(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.disabled,
    fontStyle: 'italic'
  }));
  
  export const NoNetworksDataFound = () => {
    return <NoRecords>No data available</NoRecords>
  }