import React from 'react';
import { Checkbox, FormControlLabel, Grid, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  ButtonsStyle,
  CenteredGrid,
  CheckboxStyle,
  FormControlLabelStyle,
  NotesTitle,
  NotesValues,
} from '../../pages/ThreadsDetails/Style/SingleThreat.style';
import Loader from '../Loader/Loader';
import GradientButton from '../Button/GradientButton';
import StarIcon from '../../assets/Images/StarIcon.svg';
import CustomToolTip from '../CustomToolTip/CustomToolTip';

const NoteList = ({
  handleNotesChange,
  selectedNoteIds,
  automatedNoteIds,
  handleApplyNotes,
  showApplyButton,
}: any) => {
  const { getNotesValues, createNotes, getNoteId } = useSelector(
    (state: any) => state.noteComments,
  );

  const isLoading = getNoteId.loading || getNotesValues?.loading;

  return (
    <>
      {isLoading ? (
        <CenteredGrid container>
          <Loader size={24} />
        </CenteredGrid>
      ) : (
        <>
          {getNotesValues?.data?.map((item: any, index: number) => (
            <Grid item xs={6} key={index}>
              <NotesTitle>{item.name}</NotesTitle>
              {item.note_values?.map((note: any, noteIndex: number) => {
                if (note.deleted_at) return null;
                return (
                  <FormControlLabel
                    key={note.id}
                    sx={FormControlLabelStyle}
                    control={
                      <Checkbox
                        sx={CheckboxStyle}
                        size="small"
                        checked={selectedNoteIds?.includes(note.id)}
                        onChange={() => handleNotesChange(note.id)}
                      />
                    }
                    label={
                      <NotesValues data-testid={`note-${note.name}`}>
                        <CustomToolTip title={note.description} arrow>
                          {note.name}
                        </CustomToolTip>
                        {automatedNoteIds?.includes(note.id) && (
                          <CustomToolTip
                            title="Note automatically generated by the system"
                            placement="bottom">
                            <img
                              data-testid={`automated-note-icon-${note.name}`}
                              src={StarIcon}
                              alt="star-icon"
                              style={{ marginLeft: '5px', cursor: 'pointer' }}
                            />
                          </CustomToolTip>
                        )}
                      </NotesValues>
                    }
                  />
                );
              })}
            </Grid>
          ))}
          {showApplyButton && (
            <ButtonsStyle>
              <GradientButton
                variant="contained"
                disabled={selectedNoteIds.length === 0 || createNotes.loading}
                onClick={handleApplyNotes}>
                {createNotes.loading ? <Loader size={18} /> : 'Apply'}
              </GradientButton>
            </ButtonsStyle>
          )}
        </>
      )}
    </>
  );
};

export default NoteList;
