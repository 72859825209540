import React from 'react'
import { Divider, Header, TitleContent } from './Style/Title.style';

const Title = ({ title, size }: { title: string; size: any }) => {
  return (
    <Header>
      <Divider />
      <TitleContent size={size}>{title}</TitleContent>
    </Header>
  );
};

export default Title
