import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import OutputIcon from '@mui/icons-material/Output';
import { ExternalToolListWrapper, ThreatCard } from './Style/SingleThreat.style';
import GenerateReportModal from './GenerateReportModal';
import CompleteTakedownModal from './CompleteTakedownModal';
import { Button, Loader } from '../../components';
import { HubSportIdData, ThreatTypes } from '../../helpers/types';
import {
  completeTakedownAction,
  createTakedownAction,
  getHubSportIdAction,
  getStatusTakedown,
  getTakedownDetail,
} from '../../store/takedown/action';
import { getExternalUrl } from '../../utils';

const CustomStyle = {
  backgroundColor: '#885EC0',
  color: '#FFFFFF',
  ':hover': {
    backgroundColor: '#59279d'
  }
};

const ExternalToolList = ({
  singleThreat,
  imageData,
  threatName,
}: {
  singleThreat?: ThreatTypes | null;
  imageData?: string;
  threatName: string;
}) => {
  const [openThreatReport, setOpenThreatReport] = useState<boolean>(false);
  const [openCompleteTakedown, setOpenCompleteTakedown] = useState(false);
  const [takedownMessage, setTakedownMessage] = useState<string>('');
  const [selectedStatusTakedown, setSelectedStatusTakedown] =
    useState<string>('');
  const [selectedCancellationStatus, setSelectedCancellationStatus] =
    useState<string>();

  const dispatch = useDispatch();
  const {
    takeDownDetails,
    createTakedown,
    getStatustakeDown,
    hubSportId,
    completeTakedown: { isSucessTakeDown },
  } = useSelector((state: any) => state.takedown);


  useEffect(() => {
    if (singleThreat?.identification_status?.value === 'confirmed') {
      if (isSucessTakeDown) {
        dispatch(
          getTakedownDetail({
            until: '',
            since: '',
            asset_id: singleThreat.protected_asset_id,
            threat_id: singleThreat.threat_id,
            sort: 'created',
            order: 'descending',
            page: 1,
            size: 50,
          }),
        );
        dispatch(getStatusTakedown());
      }
      dispatch(getHubSportIdAction(singleThreat?.protected_asset_name));
    }
  }, [isSucessTakeDown, singleThreat?.identification_status?.value]);

  const handleCloseThreatReport = () => {
    setOpenThreatReport(false);
  };

  const handleCloseCompleteTakedown = () => {
    setOpenCompleteTakedown(false);
  };

  const handleCreateTakedown = useCallback(() => {
    const reference_id = hubSportId?.data?.Informations?.find((items: HubSportIdData) => items.Key === 'hubSpotTicketId')
    dispatch(
      createTakedownAction({
        url: `https://${singleThreat?.threat_name || 'defaultUrl'}`,
        target: singleThreat?.protected_asset_name,
        type: 'phishing',
        description: '',
        source: 'api',
        reference_id: reference_id?.Value || '',
        company_id: singleThreat?.company_id,
        takedown_type: 'identified_threat_domain',
      }),
    );
  }, [dispatch, singleThreat]);

  const handleChangeStatusTakedown = (e: any) => {
    setSelectedStatusTakedown(e.target.value);
  };

  const handleChangeCancellationReason = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedCancellationStatus(e.target.value);
  };

  const statusTakedownData = useCallback(
    getStatustakeDown?.data?.map((item: any) => ({
      label: item.name,
      value: item.name,
    })),
    [getStatustakeDown?.data],
  );

  const handleCompleteTakedown = () => {
    const getTakedownId = takeDownDetails?.data?.items[0]?.id;
    dispatch(
      completeTakedownAction({
        id: getTakedownId,
        description: takedownMessage,
        status_name: selectedStatusTakedown,
        asset_id: singleThreat?.protected_asset_id, // for get API
        threat_id: singleThreat?.threat_id,
        ...(selectedStatusTakedown === 'cancelled' && {
          cancellation_reason_id: selectedCancellationStatus,
        }),
      }),
    );
    setTakedownMessage('');
    setOpenCompleteTakedown(false);
  };

  return (
    <ThreatCard>
      <Box sx={{ gap: 1, display: 'flex', flexWrap: 'wrap' }} mb={2}>
        <Button
          data-testid="export-threat-report-button"
          sx={{ color: '#ffffff' }}
          variant="contained"
          onClick={() => setOpenThreatReport(true)}
          startIcon={<FileDownloadIcon />}>
          Export
        </Button>
        {/* Initiate TD */}
        {singleThreat?.identification_status?.value === 'confirmed' && (
          <Button
            sx={{
              backgroundColor: '#f90307',
              color: '#FFFFFF',
              ':hover' : {
                backgroundColor: '#b80003',
              }
            }}
            variant="contained"
            onClick={handleCreateTakedown}>
            {createTakedown.loading ? (
              <Loader size={22} />
            ) : (
              'Initiate Takedown'
            )}
          </Button>
        )}
        {/* Update TD */}
        {singleThreat?.identification_status?.value === 'confirmed' &&
          takeDownDetails?.data?.items?.length > 0 &&
          takeDownDetails?.data?.items?.[0]?.status_id !== 8 && (
            <Button
              sx={{
                backgroundColor: '#f90307',
                color: '#FFFFFF',
              ':hover' : {
                  backgroundColor: '#b80003',
              }
              }}
              variant="contained"
              onClick={() => setOpenCompleteTakedown(true)}>
              Update Takedown
            </Button>
          )}
      </Box>
      <ExternalToolListWrapper>
        <Link to={getExternalUrl('urlscan', threatName)} target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            URL Scan
          </Button>
        </Link>
        <Link to={getExternalUrl('archive', threatName)} target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            Wayback Machine
          </Button>
        </Link>
        <Link
          to={getExternalUrl('transparencyreport', threatName)}
          target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            GSB
          </Button>
        </Link>
        <Link to={getExternalUrl('crt', threatName)} target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            Crt.sh
          </Button>
        </Link>
        <Link to={getExternalUrl('virustotal', threatName)} target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            VT
          </Button>
        </Link>
        <Link
          to={getExternalUrl('threatintelligenceplatform', threatName)}
          target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            TIP
          </Button>
        </Link>
        <Link to={getExternalUrl('dnschecker', threatName)} target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            DNS Checker
          </Button>
        </Link>
        <Link to="https://www.whois.com" target="_blank">
          <Button
            sx={CustomStyle}
            variant="contained"
            startIcon={<OutputIcon />}>
            Whois
          </Button>
        </Link>
      </ExternalToolListWrapper>
      <GenerateReportModal
        openThreatReport={openThreatReport}
        handleClose={handleCloseThreatReport}
        singleThreat={singleThreat}
        imageData={imageData}
      />
      <CompleteTakedownModal
        handleClose={handleCloseCompleteTakedown}
        openCompleteTakedown={openCompleteTakedown}
        handleCompleteTakedown={handleCompleteTakedown}
        takedownMessage={takedownMessage}
        setTakedownMessage={setTakedownMessage}
        handleChangeStatusTakedown={handleChangeStatusTakedown}
        statusTakedownData={statusTakedownData}
        selectedStatusTakedown={selectedStatusTakedown}
        selectedCancellationStatus={selectedCancellationStatus}
        handleChangeCancellationReason={handleChangeCancellationReason}
      />
    </ThreatCard>
  );
};

export default ExternalToolList;
