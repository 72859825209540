/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Tooltip,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  ProgressBar,
  StatusLabel,
  ConfirmationModal,
  Button,
} from '..';
import { DomainInfo, ThreatCardProps } from '../../helpers/types';
import { formatedDate, getStatusColor } from '../../utils';
import {
  StyledCard,
  CardTitle,
  LoaderWrapper,
  StatusWrapper,
  CardActions,
  SyncIconWrapper,
  LinkStyle,
  DomainInformation,
  CtiScore,
  StackContainer,
  StyledCompanyData,
  StyledDiscardConfirmWrapper,
  StyledTrademarkOwnedWrapper,
  ImageBackground,
  ErrorMessageBox
} from './ThreatCardV2.style';
import Toaster from '../Toaster/Toaster';
import { changeStatusLabelAction } from '../../store/threats/action';
import ScreenShotModal from '../ScreenShotModal/ScreenShotModal';
import ThreatNotesModal from '../../pages/Home/ViewThreatNotesModal';
import { PAGE_NAME } from '../../utils/analytic';
import { setAnalyticObject } from '../../store/analytic/action';
import { SyncIconStyle } from '../../pages/ThreadsDetails/Style/SingleThreat.style';
import { getThreatScreenshot } from '../../store/threats';
import { getSingleNetworkData } from '../../store/singleThreat';
import RefreshButton from '../RefreshButton/RefreshButton';
import Avtar from '../../assets/Images/Avtar.png';
import { useAuth0 } from '../../__mocks__/@auth0/auth0-react';

const ThreatCardV2 = ({
  items,
  handleChangeSelectedThreats,
  isThreatSelected,
  threatNetworkData,
  onClickRefreshNetworkData,
  userData,
}: ThreatCardProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [imageData, setImageData] = useState<string | null>(null);
  const [imageLoader, setImageLoader] = useState<boolean>(false);
  const [statusLabelThreat, setStatusLabelThreat] = useState('');
  const [isToasterStatus, setIsToasterStatus] = useState<boolean>(false);
  const [openScreenShotModal, setOpenScreenShotModal] =
    useState<boolean>(false);
  const [openThreatNoteModal, setOpenThreatNoteModal] =
    useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [showFullError, setShowFullError] = useState<boolean>(false);
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { getCompany } = useSelector((state: any) => state.company);
  const pendingOperations = useSelector((state: any) => state.bulkOperations.pendingOperations);

  useEffect(() => {
    if (items.error) {
      setIsToasterStatus(true);
    }
  }, [items.error]);

  useEffect(() => {
    const getImages = async () => {
      try {
        setImageLoader(true);
        const res = await getThreatScreenshot(items?.threat_name);
        setImageData(res?.data?.Image);
      } catch (error) {
        console.error('Error fetching threat screenshot', error);
        setImageData(null);
      } finally {
        setImageLoader(false);
      }
    };
    getImages();
  }, []);

  const handleCloseScreenShot = () => {
    setOpenScreenShotModal(false);
  };

  const handleCloseThreatNote = () => setOpenThreatNoteModal(false);
  const handleCopyThreatName = () => {
    try {
    navigator.clipboard.writeText(items.threat_name);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    }catch (error) {
      console.error("Clipboard write failed", error);
    }
  };

  const updateSingleStatus = async () => {
    const {
      protected_asset_name,
      protected_asset_id,
      threat_name,
      threat_id,
      company_id,
      identification_status,
    } = items;
    const analytic = {
      pageName: PAGE_NAME.HOME_PAGE,
      user: { email: user?.email, name: user?.name },
      isBulkUpdate: false,
    };
    await dispatch(setAnalyticObject(analytic));
    await dispatch(
      changeStatusLabelAction({
        threat_name,
        status: statusLabelThreat,
        user_id: userData?.data?.Id,
        company_id,
        protected_asset_id,
        protected_asset_name,
        threat_id,
        process: 'w',
        priority: 1,
        currentStatus: identification_status.value,
      }),
    );
    setOpenModal(false);
  };

  const handleCloseToaster = (threatId: number) => {
    setIsToasterStatus(false);
  };

  const handleOpenSingleModal = (value: string) => {
    setStatusLabelThreat(value);
    setOpenModal(true);
  };

  const companies = getCompany?.data?.find(
    (c: { Id: number }) => c?.Id === items?.company_id,
  );

  const handleSyncIconClick = async () => {
    if (items?.threat_id) {
      try {
        onClickRefreshNetworkData(items?.threat_id);
        await getSingleNetworkData(items?.threat_id, items?.threat_name);
      } catch (error) {
        console.log('err', error);
      }
    }
  };

  const threatData = threatNetworkData?.[items.threat_id];
  const domainInfo = threatData?.whois as unknown as DomainInfo;
  const sslData = threatData?.ssl;
  const whoisoading = threatData?.whois_loading;
  const sslLoading = threatData?.ssl_loading;
  const isDisabled = whoisoading || sslLoading;

  const isThreatChecked = useMemo(() => {
    return isThreatSelected({
      threatId: items.threat_id,
      companyId: items.company_id,
      domainId: items.protected_asset_id,
    });
  }, [isThreatSelected, items]);

  const updateSelectedThreat = useCallback(() => {
    handleChangeSelectedThreats({
      domain: items.protected_asset_name,
      threat: items.threat_name,
      threatId: items.threat_id,
      companyId: items.company_id,
      domainId: items.protected_asset_id,
      status: items.identification_status.value,
    });
  }, [handleChangeSelectedThreats, items]);

  return (
    <>
      <Box>{items.isLoading && <ProgressBar />}</Box>
      <StyledCard 
        sx={{
          ...(items.isLoading ? LoaderWrapper : {}),
          ...(pendingOperations[items.threat_id]?.hasError ? {
            border: '1px solid #ff0000',
            backgroundColor: 'rgba(255, 0, 0, 0.1)'
          } : {})
        }}>
        {isToasterStatus && (
          <Toaster
            title={items.error || 'Something went wrong'}
            backgroundColor="red"
            onCloseToaster={() => handleCloseToaster(items.threat_id)}
          />
        )}
        {/* Threat Name and actions */}
        <Box
          data-testid="card-threat-name"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 12px',
          }}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                flex: 1,
                minWidth: 0,
                marginRight: '8px',
              }}>
              <Tooltip title={items.threat_name} placement="top">
                <FormControlLabel
                  sx={{
                    ...CardTitle,
                    width: '100%',
                    margin: 0,
                    '& .MuiFormControlLabel-label': {
                      ...CardTitle['& .MuiFormControlLabel-label'],
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                    },
                  }}
                  control={
                    <Checkbox
                      data-test-id="card-title-checkbox"
                      checked={isThreatChecked}
                      onChange={updateSelectedThreat}
                      sx={{ padding: '0' }}
                    />
                  }
                  label={items?.threat_name}
                />
              </Tooltip>
            </Box>
          </Box>
          <IconButton
            data-testid="card-copy-button"
            onClick={handleCopyThreatName}
            size="small"
            sx={{ padding: '0' }}
            disabled={copied}>
            <ContentCopyIcon sx={{ height: '20px', width: '20px' }} />
          </IconButton>
        </Box>

        <ImageBackground
          screenshot={imageData 
            ? `data:image/jpeg;base64,${imageData}` 
            : Avtar}
          data-testid="card-screenshot-image">
          <StyledCompanyData>
            {companies?.Name}: {items?.protected_asset_name}
          </StyledCompanyData>
          {pendingOperations[items.threat_id] && (
              <ErrorMessageBox 
                onClick={() => setShowFullError(!showFullError)}
                style={{ 
                  cursor: showFullError ? 'default' : 'pointer',
                  backgroundColor: pendingOperations[items.threat_id]?.hasError ? 'rgba(255, 0, 0, 0.1)' : 'rgba(25, 118, 210, 0.05)',
                  color: pendingOperations[items.threat_id]?.hasError ? '#ff0000' : '#1976d2'
                }}
                isFullError={showFullError}
              >
                {showFullError ? (
                  <>
                    {pendingOperations[items.threat_id]?.hasError 
                      ? pendingOperations[items.threat_id]?.errorMessage || 'An error occurred'
                      : 'Operation in progress'}
                  </>
                ) : (
                  pendingOperations[items.threat_id]?.hasError 
                    ? 'Bulk operation error' 
                    : 'Operation in progress'
                )}
              </ErrorMessageBox>
            )}
          {/* Lower image */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              padding: '8px 12px',
            }}>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <CtiScore score={items?.score?.value ?? null}>
                {items?.score?.value || '-'}
              </CtiScore>
              <StatusWrapper>
                <StatusLabel
                  label={items?.identification_status?.value.replace(/-/g, ' ')}
                  bgcolor={getStatusColor(items?.identification_status?.value)}
                />
              </StatusWrapper>
            </Box>
            <Button
              data-testid="add-notes-button"
              size='small'
              variant='outlined'
              onClick={() => setOpenThreatNoteModal(true)}>
              <EditIcon></EditIcon>
            </Button>
          </Box>
        </ImageBackground>
        {/* Screenshot */}
        {/*           {imageLoader ? (
            <ImageSkeleton height={150} />
          ) : (
            <Avatar
              alt="Placeholder"
              variant="square"
              src={
                imageData === null
                  ? Avtar
                  : imageData
                  ? `data:image/jpeg;base64,${imageData}`
                  : Avtar
              }
              onClick={() => setOpenScreenShotModal(true)}
              sx={{ height: '150px', minWidth: '100%' }}
            />
          )} */}
        <CardActions>
          {/* DISCARD */}
          <StyledDiscardConfirmWrapper>
            <Button
              variant={'contained'}
              data-testid="card-discard-button"
              disabled={
                items?.identification_status?.value === 'discarded'
                  ? true
                  : false
              }
              onClick={() => {
                handleOpenSingleModal('discarded');
              }}>
              Discard
            </Button>
            {/* CONFIRM */}
            <Button
              variant={'contained'}
              data-testid="card-confirm-button"
              disabled={
                items?.identification_status?.value === 'confirmed'
                  ? true
                  : false
              }
              onClick={() => {
                handleOpenSingleModal('confirmed');
              }}>
              Confirm
            </Button>
          </StyledDiscardConfirmWrapper>
          <StyledTrademarkOwnedWrapper>
            {/* Trademark */}
            <Button
                size="small"
              data-testid="card-trademark-button"
              variant={'outlined'}
              disabled={
                items?.identification_status?.value === 'trademark'
                  ? true
                  : false
              }
              onClick={() => {
                handleOpenSingleModal('trademark');
              }}>
              T
            </Button>
            {/* OWNED */}
            <Button
              size='small'
              variant={'outlined'}
              data-testid="card-owned-button"
              disabled={
                items?.identification_status?.value === 'owned' ? true : false
              }
              onClick={() => {
                handleOpenSingleModal('owned');
              }}>
              O
            </Button>
          </StyledTrademarkOwnedWrapper>
        </CardActions>

        <Link
          to={`/threats/${items?.threat_name}/${items?.protected_asset_name}/${items?.company_id}?protected_id=${items?.protected_asset_id}&threat_id=${items?.threat_id}`}
          target="_blank"
          style={LinkStyle}>
          {/* Data */}
          <StackContainer>
            <Box>
              <DomainInformation>
                {/* Registrar */}
                <span>Registered by</span>
                {whoisoading ? (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: '13px' }}
                    width={'50%'}
                  />
                ) : (
                  domainInfo?.registrar || <span>No data available</span>
                )}
              </DomainInformation>
              <DomainInformation>
                {/* Registration */}
                <span>on </span>
                {whoisoading ? (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: '13px' }}
                    width={'50%'}
                  />
                ) : domainInfo?.registration_date &&
                  domainInfo?.registration_date !== 'undefined' ? (
                  `${formatedDate(domainInfo?.registration_date)} UTC`
                ) : (
                  <span>No data available</span>
                )}
              </DomainInformation>
              <DomainInformation>
                {/* Detected */}
                <span>Detected on</span>
                {items?.detection_date &&
                items?.detection_date !== 'undefined' ? (
                  `${formatedDate(items?.detection_date)} UTC`
                ) : (
                  <span>No data available</span>
                )}
              </DomainInformation>
              <DomainInformation>
                {/* Expiration */}
                <span>Expires on</span>
                {whoisoading ? (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: '13px' }}
                    width={'50%'}
                  />
                ) : domainInfo?.expiration_date &&
                  domainInfo?.expiration_date !== 'undefined' ? (
                  `${formatedDate(domainInfo?.expiration_date)} UTC`
                ) : (
                  <span>No data available</span>
                )}
              </DomainInformation>
              <DomainInformation>
                {/* SSL */}
                <span>SSL: </span>
                {sslLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{ fontSize: '13px' }}
                    width={'50%'}
                  />
                ) : (
                  sslData?.organisation || <span>No data available</span>
                )}
              </DomainInformation>
            </Box>
            <SyncIconWrapper>
              <RefreshButton
                handleSyncIconClick={handleSyncIconClick}
                isDisabled={isDisabled}
                SyncIconStyle={SyncIconStyle}
              />
            </SyncIconWrapper>
          </StackContainer>
        </Link>

        <ScreenShotModal
          imageData={imageData}
          handleCloseScreenShot={handleCloseScreenShot}
          openScreenShotModal={openScreenShotModal}
        />
        <ThreatNotesModal
          openThreatNoteModal={openThreatNoteModal}
          handleCloseThreatNote={handleCloseThreatNote}
          items={items}
        />
        <ConfirmationModal
          title="Confirm status"
          subTitle={`Do you want to change the status to ${statusLabelThreat}?`}
          confirmLabel="Update"
          open={openModal}
          handleClose={() => setOpenModal(false)}
          handleUpdate={() => updateSingleStatus()}
        />
      </StyledCard>
    </>
  );
};

export default ThreatCardV2;
