import { Link } from 'react-router-dom';
import { Box, styled, Divider as MuiDivider } from '@mui/material';

interface StyledStrongProps {
  size?: 'small' | 'default';
}

// ** Main Container Styles **
export const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 24,
  gap: 16,
  minHeight: '60vh',
});

// ** Content Section Styles **
export const Content = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 16,
  boxShadow: '0px 20px 32px 15px #C1C1C129',
}));

export const ContentWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 13,
});

// ** Layout Styles **
export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: '100%',
});

export const LayoutConatainer = styled(Box)({
  display: 'flex',
  gap: 13,
});

// ** Horizontal Line Styles **
export const HorizontalLine = styled(MuiDivider)(({ theme }) => ({
  margin: '12px 0',
  backgroundColor: theme.palette.background.paper,
}));

// ** Info Section Styles **
export const InfoWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
});

// ** Dropdown Wrapper Styles **
export const DatePickerWrapper = styled(Box)(({ theme }) => ({
  width: 150
}));

// ** Text Styling **
export const StyledParagraph = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '18px',
  letterSpacing: '-0.36px',
}));

export const StyledStrong = styled('strong')<StyledStrongProps>(
  ({ theme, size = 'default' }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: size === 'small' ? '12px' : '14px',
    fontWeight: 700,
    lineHeight: '14px',
    letterSpacing: '-0.36px',
  }),
);

export const StyledNormal = styled('span')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '-0.36px',
  color: theme.palette.text.tertiary
}));

// ** Action Wrapper Styles **
export const ActionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 13,
}));

// ** Companies Method Styles **
export const CompaniesMethod = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 13,
}));

// ** HubSpot Link Styling **
export const HubspotLink = styled(Link)(({ theme }) => ({
  display: 'flex', 
  alignItems: 'center',
  color: theme.palette.primary.dark,
  fontFamily: theme.typography.fontFamily,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '-0.36px',
  '&:hover, &:focus, &:active': {
    color: theme.palette.primary.dark, 
    textDecoration: 'none',
  },
}));

export const CustomOpenInNewIcon = styled('img')(({ theme }) => ({
  width: 12,
  height: 12,
  marginLeft: 2,
}));


// ** Error Message Styling **
export const ErrorMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  alignItems: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: 500,
  letterSpacing: '-0.36px',
}));
