import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  LayoutConatainer,
  MainContainer,
} from '../CompanyCreationV2/Style/CompanyCreationV2.style';
import Services from '../CompanyCreationV2/Services';
import TakedownMode from '../CompanyCreationV2/TakedownMode';
import CompanyRelationship from '../CompanyCreationV2/CompanyRelationship';
import Industries from '../CompanyCreationV2/Industries';
import CompanyInformation from '../CompanyCreationV2/CompanyInformation';
import { OptionType } from '../../helpers/types';
import { LoaderWrapper, TAKEDOWN_MODE_ITEMS } from '../../utils';

const InformationTabV2: React.FC = () => {
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTakedownMode, setSelectedTakedownMode] = useState<string[]>([
    'Manual',
  ]);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [companyName, setCompanyName] = useState('');
  const [hubspotId, setHubspotId] = useState<number | null>(null);
  const [attackCostSavings, setAttackCostSavings] = useState<number | null>(
    null,
  );
  const [errors, setErrors] = useState({
    companyName: '',
    attackCostSavings: '',
  });

  const [selectedValues, setSelectedValues] = useState<{
    parent: OptionType | null;
  }>({ parent: null });

  const { getService, getCompanyDetail, getCompany } = useSelector(
    (state: any) => state.company,
  );

  const companyDetail = getCompanyDetail.data;

  const isPreCrimeBrand = selectedServices?.some((item) => {
    return item?.includes('Precrime Brand');
  });

  const serviceData = useCallback(
    () =>
      getService?.data?.items?.map((service: { name: string; id: number }) => ({
        label: service.name,
        value: service.id.toString(),
        id: service.id,
      })) || [],
    [getService?.data?.items],
  );

  const parentFilterData = useMemo(() => {
    return (
      getCompany?.data?.map((item: { Name: string; Id: number }) => ({
        label: item.Name,
        id: item.Id,
      })) || []
    );
  }, [getCompany?.data]);

  useEffect(() => {
    if (companyDetail) {
      setSelectedServices(
        companyDetail.Services?.map((service: {Name: string}) => service.Name) || [],
      );
      setSelectedDate(
        companyDetail.Properties?.serviceUntil
          ? new Date(companyDetail.Properties.serviceUntil)
          : null,
      );
      setSelectedTakedownMode(
        companyDetail.Properties?.takedownMode || ['Manual'],
      );
      setSelectedIndustries(
        companyDetail.Industries?.map((industry: {Name: string}) => industry.Name) || [],
      );
      setCompanyName(companyDetail.Name || '');
      setHubspotId(companyDetail.Properties?.hubSpotId || '');
      setAttackCostSavings(companyDetail.Properties?.attackCostSavings || '');
    }
  }, [companyDetail]);

  const handleServicesChange = (newSelectedItems: string[]) => {
    setSelectedServices(newSelectedItems);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleTakedownModeChange = (newSelectedModes: string[]) => {
    setSelectedTakedownMode(newSelectedModes);
  };

  const handleIndustriesChange = (newSelectedIndustries: string[]) => {
    setSelectedIndustries(newSelectedIndustries);
  };

  const handleAutocompleteChange = (
    event: any,
    value: OptionType | null,
    name: string,
  ) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [name]: value || null,
    }));
  };

  const handleRemoveParentCompany = () => {
    setSelectedValues(prevState => ({ ...prevState, parent: null }));
  };

  const handleBlur = (field: string) => {
    if (field === 'companyName' && !companyName) {
      setErrors(prevErrors => ({
        ...prevErrors,
        companyName: 'Company name is required',
      }));
    } else if (field === 'attackCostSavings') {
      if (isPreCrimeBrand) {
        if (
          !attackCostSavings ||
          isNaN(Number(attackCostSavings)) ||
          Number(attackCostSavings) <= 0
        ) {
          setErrors(prevErrors => ({
            ...prevErrors,
            attackCostSavings:
              'Attack cost savings is mandatory when PreCrime Brand service is selected',
          }));
        } else {
          setErrors(prevErrors => ({ ...prevErrors, attackCostSavings: '' }));
        }
      } else {
        if (
          attackCostSavings &&
          (isNaN(Number(attackCostSavings)) || Number(attackCostSavings) <= 0)
        ) {
          setErrors(prevErrors => ({
            ...prevErrors,
            attackCostSavings:
              'Attack cost savings must be a number greater than 0',
          }));
        } else {
          setErrors(prevErrors => ({ ...prevErrors, attackCostSavings: '' }));
        }
      }
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [field]: '' }));
    }
  };

  const handleSubmit = (companyData: {
    companyName: string;
    hubspotId?: string;
    attackCostSavings: string;
  }) => {
    const { companyName, hubspotId, attackCostSavings } = companyData;

    const newErrors = {
      companyName: companyName.trim() ? '' : 'Company name is required',
      attackCostSavings: attackCostSavings.trim()
        ? ''
        : 'Attack cost savings is required',
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some(error => error !== '')) {
      console.log('Validation errors:', newErrors);
      return;
    }

    const formData = {
      ...companyData,
      selectedServices,
      selectedDate,
      selectedTakedownMode,
      selectedIndustries,
      selectedParent: selectedValues.parent,
    };

    console.log('Submitting form data:', formData);

    // Perform API request or other submission logic here
  };

  if (getCompanyDetail?.loading) {
    return (
      <MainContainer>
        <LoaderWrapper size={25} />
      </MainContainer>
    );
  }

  return (
    <MainContainer sx={{ padding: 0 }}>
      <LayoutConatainer>
        <Services
          selectedServices={selectedServices}
          handleServicesChange={handleServicesChange}
          serviceData={serviceData()}
          selectedDate={selectedDate}
          handleDateChange={handleDateChange}
        />
        <TakedownMode
          takedownItems={TAKEDOWN_MODE_ITEMS}
          selectedTakedownMode={selectedTakedownMode}
          handleTakedownModeChange={handleTakedownModeChange}
        />
      </LayoutConatainer>
      <LayoutConatainer>
        <Industries
          selectedIndustries={selectedIndustries}
          handleIndustriesChange={handleIndustriesChange}
        />
        <CompanyRelationship
          selectedValues={selectedValues}
          handleAutocompleteChange={handleAutocompleteChange}
          handleRemoveParentCompany={handleRemoveParentCompany}
          dropdown={parentFilterData}
        />
      </LayoutConatainer>
      <LayoutConatainer sx={{ flexDirection: 'column' }}>
        <CompanyInformation
          companyName={companyName}
          setCompanyName={setCompanyName}
          hubspotId={hubspotId}
          setHubspotId={setHubspotId}
          attackCostSavings={attackCostSavings}
          setAttackCostSavings={setAttackCostSavings}
          errors={errors}
          handleBlur={handleBlur}
          handleSubmit={() => {
            handleSubmit;
          }}
          isPreCrimeBrand={isPreCrimeBrand}
        />
      </LayoutConatainer>
    </MainContainer>
  );
};

export default InformationTabV2;
