export const StyledButton = {
  position: 'fixed',
  bottom: '80px',
  right: '22px',
  zIndex: 1000,
  transition: 'opacity 300ms ease, transform 300ms ease',
  opacity: 1,
  transform: 'translateY(0)',

  '&:hover': {
    opacity: 0.8,
  },
};
