import React, { useState } from 'react';
import { Box } from '@mui/material';
import { urlRegex } from '../../utils';
import {
  ChipsWrapper,
  StyledChip,
  StyledTextField,
} from './style/MultiDomainInput.style';
import { MultiDomainInputProps } from '../../helpers/types';
import { InfoTypography } from '../../pages/Home/Style/CreateThreatModel.style';

const MultiDomainInput: React.FC<MultiDomainInputProps> = ({
  value,
  onChange,
  error,
  onBlur,
  sx,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState<string>('');

  const validateDomains = (domains: string[]) => {
    const validDomains = domains.filter(domain => urlRegex.test(domain));
    const invalidDomains = domains.filter(domain => !urlRegex.test(domain));

    if (invalidDomains.length > 0) {
      setInputValue(invalidDomains.join(''));
      setInputError('Please enter valid URLs.');
    } else {
      setInputError('');
      setInputValue('');
    }

    return validDomains;
  };

  const addDomain = () => {
    const trimmedInput = inputValue.trim();

    if (trimmedInput) {
      const domains = trimmedInput
        .split(/[ ,]+/)
        .map(domain => domain.trim())
        .filter(domain => domain.length > 0);

      const validDomains = validateDomains(domains);

      if (validDomains.length === 0) {
        setInputError('Please enter valid URLs.');
      } else {
        const newDomains = validDomains.filter(
          domain => !value?.includes(domain),
        );

        if (newDomains.length > 0) {
          onChange([...value, ...newDomains]);
        }
      }
    } else {
      setInputError('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (['Enter', ',', ' '].includes(e.key)) {
      e.preventDefault();
      addDomain();
    }
  };

  const handleDeleteDomain = (index: number) => {
    const updatedDomains = value?.filter((_, i) => i !== index);
    onChange(updatedDomains);
  };

  const displayedError = error || inputError;

  return (
    <Box sx={{ width: '100%' }}>
      <StyledTextField
        multiline
        rows={1}
        placeholder={placeholder}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={() => {
          if (onBlur) onBlur();
          addDomain();
        }}
        fullWidth
        error={!!displayedError}
        sx={{ ...sx }}
      />
      <InfoTypography sx={{ mt: 1 }}>
        Use commas or spaces for multiple domain insertion
      </InfoTypography>

      {displayedError && (
        <InfoTypography sx={{ mt: 1, color: 'error.main' }}>
          {displayedError}
        </InfoTypography>
      )}

      <ChipsWrapper sx={{ mt: 1 }}>
        {value.map((domain, index) => (
          <StyledChip
            key={index}
            label={domain}
            onDelete={() => handleDeleteDomain(index)}
          />
        ))}
      </ChipsWrapper>
    </Box>
  );
};

export default MultiDomainInput;
