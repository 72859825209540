import React, { ReactNode } from "react";
import { StyledSpan, StyledToolTip } from './Style/CustomToolTip.style';

type CustomToolTipProps = {
  title: string;
  children: ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  arrow?: boolean;
  sx?: object;
};

const CustomToolTip: React.FC<CustomToolTipProps> = ({
  title,
  children,
  placement = "bottom",
  arrow = true,
  sx,
}) => {
  return (
    <StyledToolTip title={title} placement={placement} arrow={arrow} sx={sx}>
      <StyledSpan>{children}</StyledSpan>
    </StyledToolTip>
  );
};

export default CustomToolTip;