import { ToastActions } from '../../helpers/types';
import { HIDE_TOAST, SHOW_TOAST } from '../constant';

const initialState = {
  toster: {
    open: false,
    message: '',
    severity: '',
  },
};

export const toasterReducer = (
  state = initialState,
  action: ToastActions,
) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        toster: {
          ...state.toster,
          open: true,
          message: typeof action?.payload?.message === 'string' ?  action.payload.message: "Something went wrong" ,
          severity: action?.payload?.severity,
        },
      };
    case HIDE_TOAST:
      return {
        ...state,
        toster: {
          open: false,
          message: '',
          severity: '',
        },
      };
    default:
      return state;
  }
};
