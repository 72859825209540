import { styled, Tooltip, TooltipProps } from '@mui/material';

export const StyledToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: theme.palette.background.tooltipBackground,
    color: theme.palette.background.paper,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    fontWeight: 500,
    padding: "14px 8px",
    borderRadius: 4,
    textAlign: "center",
    width: 250,

  },
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.background.tooltipBackground,
  },
}));

export const StyledSpan = styled("span")(({ theme }) => ({
  display: "inline-block",
  cursor: "pointer",
}));
