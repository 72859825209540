import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@mui/material';
import { TableHeaderCell, TakedownTable } from '../Style/SingleThreat.style';
import { DNSRecordsHeader } from '../../../utils';
import { DNSRecordProps } from '../../../context/SingleThreat/SingleThreatType';
import { NoRecordFound } from '../../../components/NoRecordFound/NoRecordFound';
import { NoNetworksDataFound } from './NoNetworksDataFound';

const DNSRecordsTab = ({ dnsRecord, threatDetailLoader, loading }: DNSRecordProps) => {
  const filteredRecords = dnsRecord?.filter(
    (item: any) =>
      item.record_type === 'A' ||
      item.record_type === 'AAAA' ||
      item.record_type === 'MX',
  );

  const renderTableContent = () => {
    if (loading) {
      // Show loading indicator if data is being fetched
      return (
        <TableRow>
          <TableCell colSpan={5} align="center" sx={{ border: 0 }}>
            {threatDetailLoader()} {/* Replace with your loading indicator */}
          </TableCell>
        </TableRow>
      );
    }
    if (filteredRecords && filteredRecords.length > 0) {
      // Render table rows if filtered records exist
      return filteredRecords.map((item: any, index: number) => (
        <TableRow
          key={index}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
            height: '50px',
          }}>
          <TableCell>{item?.record_type || <NoNetworksDataFound />}</TableCell>
          <TableCell align="left">{item?.address || <NoNetworksDataFound />}</TableCell>
          <TableCell align="left">{item?.domain_name || <NoNetworksDataFound />}</TableCell>
        </TableRow>
      ));
    }
    // Display 'No records found' if there are no filtered records
    return (
      <TableRow>
        <TableCell colSpan={5} align="center" sx={{ border: 0 }}>
          <NoRecordFound />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TakedownTable>
      <TableContainer data-testid="dns-records-table" sx={{ maxHeight: 400 }}>
        <Table stickyHeader size="small" sx={{ border: 0 }}>
          <TableHead>
            <TableRow>
              {DNSRecordsHeader.map((item) => (
                <TableHeaderCell key={item} align="left">
                  {item}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableContent()}
          </TableBody>
        </Table>
      </TableContainer>
    </TakedownTable>
  
  );
};

export default DNSRecordsTab;
