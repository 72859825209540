import React, { useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { HistoryStatues } from '../../helpers/types';
import { Loader } from '../../components';
import { HorizontalLine, StyledHeader, TableHeaderCell, ThreatCard } from './Style/SingleThreat.style';
import { useSingleThreatContext } from '../../context/SingleThreat/SingleThreatContext';
import { SingleThreatDetailsType } from '../../context/SingleThreat/SingleThreatType';
import { StatusHistoryHeader } from '../../utils';
import { CardWrapper } from '../../components/Wrapper/Wrapper.style';
import { NoRecordFound } from '../../components/NoRecordFound/NoRecordFound';
import Title from '../../components/Title/Title';

const StatusHistory = ({
  singleThreat,
}: {
  singleThreat: SingleThreatDetailsType | undefined;
}) => {
  const { getHistoryStatuses, historyStatusesData, historyStatusLoading } =
    useSingleThreatContext();

  useEffect(() => {
    if (
      singleThreat?.threat_id &&
      singleThreat?.protected_asset_id
    ) {
      getHistoryStatuses({
        d: singleThreat.protected_asset_id,
        t: singleThreat.threat_id,
      });
    }
  }, [singleThreat?.threat_id, singleThreat?.protected_asset_id]);

  const renderHistoryStatuses = () => {
    if (historyStatusesData && historyStatusesData.length > 0) {
      return historyStatusesData
        .sort(
          (a: HistoryStatues, b: HistoryStatues) =>
            new Date(b.Created).getTime() - new Date(a.Created).getTime(),
        )
        .map((item: HistoryStatues) => (
          <TableRow
            key={item.Created}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              height: '50px',
            }}>
            <TableCell align="left">
              <span
                style={{
                  fontWeight: 500,
                  textTransform: 'capitalize',
                }}>
                {item.Status}
              </span>
            </TableCell>
            <TableCell align="left">
              {item.Username ||
                (['d', 'o'].includes(item.Process ?? '') ? 'system' : ' ')}
            </TableCell>
            <TableCell align="left">
              {new Date(item?.Created).toLocaleString()} UTC
            </TableCell>
          </TableRow>
        ));
    } 
    return (
      <TableRow>
        <TableCell colSpan={5} align="center" sx={{ border: 0 }}>
          <NoRecordFound />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <ThreatCard>
      <StyledHeader>
        <Title size={'large'} title="Status History" />
      </StyledHeader>
      <HorizontalLine />
      <Box sx={{ mt: 2, overflow: 'auto' }}>
        {historyStatusLoading ? (
          <CardWrapper>
            <Loader size={20} />
          </CardWrapper>
        ) : (
          <Table stickyHeader size="small" sx={{ border: 0 }}>
            <TableHead>
              <TableRow>
                {StatusHistoryHeader.map((headerItems) => (
                  <TableHeaderCell key={headerItems} align="left">
                    {headerItems}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderHistoryStatuses()}
            </TableBody>
          </Table>
        )}
      </Box>
    </ThreatCard>
  );
};

export default StatusHistory;
