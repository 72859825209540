import React from 'react';
import { Box } from '@mui/material';
import { INDUSTRIES_DATA } from '../../utils';
import {
  Container,
  Content,
  ContentWrapper,
  HorizontalLine,
  InfoWrapper,
  StyledNormal,
} from './Style/CompanyCreationV2.style';
import Title from '../../components/Title/Title';
import { IndustriesProps } from '../../helpers/types';
import MultiSelectDropdown from '../../components/MultiselectDropDown/MultiSelectDropdown';

const Industries: React.FC<IndustriesProps> = ({
  selectedIndustries,
  handleIndustriesChange,
}) => {
  const items = INDUSTRIES_DATA.map((industry) => industry.label);
  return (
    <Container>
      <Content sx={{height: 135}}>
        <ContentWrapper>
          <Title size={'large'} title="Industries" />
        </ContentWrapper>
        <HorizontalLine />
        <InfoWrapper>
            <StyledNormal>
              The industries you select will be used behind the scenes to assign relevant industry keywords automatically.
            </StyledNormal>
          <MultiSelectDropdown
            items={items} 
            placeholder="Industries"
            selectedItems={selectedIndustries} 
            onSelectionChange={handleIndustriesChange} 
            multiple={true}
            width={170}
          />
        </InfoWrapper>
      </Content>
    </Container>
  );
};

export default Industries;
