import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Auth0Provider as RealAuth0Provider } from '@auth0/auth0-react';
import { ErrorBoundary } from 'react-error-boundary';
import { Auth0Provider as MockAuth0Provider } from './__mocks__/@auth0/auth0-react';

import store, { persistor } from './store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';
import { providerConfig } from './helpers/auth0Config';
import { MyFallbackComponent } from './helpers/ErrorBoundary';
import ToastMui from './components/TosaterMui/TosaterMui';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

import { theme } from './styles/theme';

// Conditionally mock Auth0Provider during tests
const AuthProvider = process.env.REACT_APP_MOCK_AUTH0 === 'true'
  ? MockAuth0Provider
  : RealAuth0Provider;
  
root.render(
  <ThemeProvider theme={theme}>
    <ErrorBoundary FallbackComponent={MyFallbackComponent}>
      <AuthProvider 
        {...providerConfig}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastMui />
            <App />
          </PersistGate>
        </Provider>
      </AuthProvider>
    </ErrorBoundary>
  </ThemeProvider>,
);

reportWebVitals();
