import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationModal } from '../../components';
import { deleteCompanyAction } from '../../store/company/action';
import { formatedDate, hasCompanyV2Access, LoaderWrapper } from '../../utils';
import {
  CustomTableBodyCell,
  CustomTableCell,
  StyledLink,
} from './Style/CompaniesListV2.style';
import { NoRecordFound } from '../../components/NoRecordFound/NoRecordFound';
import DeleteIcon from '../../assets/Images/Delete.svg';

const CompaniesTableV2 = ({ searchCompanyName, companyCurrentPage }: any) => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [isUserHasCompanyV2Access, setIsUserHasCompanyV2Access] =
    useState<boolean>(false);

  const { getCompanyList } = useSelector((state: any) => state.company);
  const userEmail = useSelector(
    (state: any) => state?.user?.userData?.data?.Email,
  );
  const companies = getCompanyList?.data?.Companies;
  const dispatch = useDispatch();

  // TODO: Temporary: company access handled in frontend, to be removed before production.
  useEffect(() => {
    const hasPermission = hasCompanyV2Access(userEmail);
    setIsUserHasCompanyV2Access(hasPermission);
  }, [userEmail]);

  const openDeleteModal = (companyName: string, companyId: string) => {
    setSelectedCompanyName(companyName);
    setSelectedCompanyId(companyId);
    setOpenConfirmModal(true);
  };

  const handleDeleteCompany = () => {
    dispatch(
      deleteCompanyAction({
        id: selectedCompanyId,
        h: searchCompanyName,
        pn: companyCurrentPage,
      }),
    );
    setOpenConfirmModal(false);
  };

  const getCompanyDetailLink = (companyId: string) =>
    `/companies-temp/${encodeURIComponent(companyId)}/view`;

  const renderCompanyList = () => {
    if (getCompanyList?.loading) {
      return <LoaderWrapper size={25} />;
    }

    if (companies?.length === 0) {
      return (
        <TableRow>
          <CustomTableBodyCell colSpan={5} align="center">
            <NoRecordFound />
          </CustomTableBodyCell>
        </TableRow>
      );
    }

    return (
      <TableBody>
        {companies?.map((company: any) => (
          <TableRow
            key={company?.Id}
           >
            <CustomTableBodyCell>
              <StyledLink to={getCompanyDetailLink(company?.Id)}>
                {company?.Name}
              </StyledLink>
            </CustomTableBodyCell>
            <CustomTableBodyCell>
              {company?.Services?.length
                ? company?.Services?.map((item: any) => item?.Name).join(', ')
                : '-'}
            </CustomTableBodyCell>
            <CustomTableBodyCell>
              {formatedDate(company?.Created)} UTC
            </CustomTableBodyCell>
            <CustomTableBodyCell>
              <Box>
                <IconButton
                  type="button"
                  onClick={() => openDeleteModal(company?.Name, company?.Id)}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  aria-label="Delete company" 
                >
                  <img
                    src={DeleteIcon}
                    alt="deleteIcon"
                    style={{ color: 'red' }} 
                  />
                </IconButton>
              </Box>
            </CustomTableBodyCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <>
      <TableContainer
        data-testid="companies-table-container"
        component={Paper}
        sx={{
          boxShadow: 'none',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}>
        <Table size="small" sx={{ width: '100%', minHeight: '70vh', borderSpacing: '8px' }}>
          <TableHead>
            <TableRow>
              <CustomTableCell>Name</CustomTableCell>
              <CustomTableCell>Services</CustomTableCell>
              <CustomTableCell>Creation Date</CustomTableCell>
              <CustomTableCell>Actions</CustomTableCell>
            </TableRow>
          </TableHead>
          {renderCompanyList()}
        </Table>
      </TableContainer>
      <ConfirmationModal
        title={`Are you sure you want to delete ${selectedCompanyName} ?`}
        subTitle="You won't be able to revert this !"
        confirmLabel="Yes, delete it !"
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
        handleUpdate={handleDeleteCompany}
      />
    </>
  );
};

export default CompaniesTableV2;
