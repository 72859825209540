import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { fetchGSBHistoryApi } from '../../store/gsbHistory';
import {
  GetGSBParms,
  GSBContextType,
  GSBDataSubmitType,
  GSBResponse,
  NetworkDataType,
  RegionCodeType,
} from './GsbHistoryType';
import {
  fetchLabelApi,
  fetchNetworkCheckThreatApi,
  fetchPlatformApi,
  fetchRegionCodesApi,
  submitToGsbApi,
} from '../../store/singleThreat';
import { showToast } from '../../store/toaster/action';
import { apiErrorMessage } from '../../utils';

const GSBContext = createContext<GSBContextType | undefined>(undefined);

interface GsbProviderProps {
  children: ReactNode;
}
export const useGSBHistoryContext = () => {
  const context = useContext(GSBContext);
  if (!context) {
    throw new Error('useGSBHistoryContext must be used within a GsbProvider');
  }
  return context;
};

export const GsbProvider: React.FC<GsbProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const [regionCodesData, setRegionCodesData] = useState<RegionCodeType[]>([]);
  const [platformsData, setPlatformsData] = useState<NetworkDataType[]>([]);
  const [networkCheckThreat, setNetworkCheckThreat] = useState<
    NetworkDataType[]
  >([]);
  const [labelsData, setLabelsData] = useState<NetworkDataType[]>([]);

  const [gsbHistoryData, setGSBHistoryData] = useState<GSBResponse>();
  const [gsbLoading, setGSBLoading] = useState<boolean>(false);
  const [gsbSubmitLoading, setGSBSubmitLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const fetchRegionCodes = async () => {
    setError('');
    try {
      const { data } = await fetchRegionCodesApi();
      setRegionCodesData(data?.items);
    } catch (err) {
      console.error(err)
    }
  };

  const fetchPlatforms = async () => {
    setError('');
    try {
      const { data } = await fetchPlatformApi();
      setPlatformsData(data);
    } catch (err) {
      console.error(err)
    }
  };

  const fetchNetworkThreats = async () => {
    setError('');
    try {
      const { data } = await fetchNetworkCheckThreatApi();
      setNetworkCheckThreat(data);
    } catch (err) {
      console.error(err)
    }
  };

  const fetchLabels = async () => {
    setError('');
    try {
      const { data } = await fetchLabelApi();
      setLabelsData(data);
    } catch (err) {
      console.error(err)
    }
  };

  const getGSBHistory = async (params: any) => {
    setGSBLoading(true);
    setError('');
    try {
      const { data } = await fetchGSBHistoryApi(params);
      setGSBHistoryData(data);
    } catch (err) {
      console.error(err)
    } finally {
      setGSBLoading(false);
    }
  };

  const submitToGsbAction = async (data: GSBDataSubmitType, getGsbParams: GetGSBParms) => {

    setError('');
    setGSBSubmitLoading(true);
    try {
      const res = await submitToGsbApi(data);
      dispatch(showToast('GSB Submitted successfully', 'success'));
      getGSBHistory({
        page: 1,
        size: 100,
        threat_name: getGsbParams?.threatName,
        start_date: getGsbParams?.startDate,
        end_date: getGsbParams?.endDate
      })
      return res;
    } catch (err: any) {
      const errorMessage = apiErrorMessage(err)
      dispatch(showToast(errorMessage, 'error'));
    } finally {
      setGSBSubmitLoading(false);
    }
  };

  const contextValue = useMemo(
    () => ({
      fetchRegionCodes,
      regionCodesData,
      fetchPlatforms,
      platformsData,
      fetchNetworkThreats,
      networkCheckThreat,
      fetchLabels,
      labelsData,
      gsbHistoryData,
      gsbLoading,
      error,
      getGSBHistory,
      submitToGsbAction,
      gsbSubmitLoading,
    }),
    [
      regionCodesData,
      platformsData,
      networkCheckThreat,
      labelsData,
      gsbHistoryData,
      gsbLoading,
      error,
      gsbSubmitLoading,
    ]
  );

  return <GSBContext.Provider value={contextValue}>{children}</GSBContext.Provider>;
};
