import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Input } from '../../../components';
import {
  CustomTableCell,
  StyledLink,
} from '../../CompaniesList/Style/CompaniesList.style';
import {
  getCompanyDetailAction,
  getCompanyListAction,
  unsetParentCompanyAction,
} from '../../../store/company/action';
import { LoaderWrapper, NoDataMessage, formatedDate } from '../../../utils';
import { ActionsButton } from './Style/Tabs.style';
import { PaginationStyle } from '../../ThreadsDetails/Style/SingleThreat.style';
import AddChildModal from '../AddChildModal';

const PaperStyle = {
  backgroundColor: 'white',
  height: 'auto',
  p: 2.5,
};

const ChildCompaniesTab = () => {
  const [searchChildCompany, setSearchChildCompany] = useState<string>('');
  const [openAddChildModal, setOpenAddChildModal] = useState<boolean>(false);
  const [openActionMenu, setOpenActionMenu] = useState<null>(null);
  const [selectedChild, setSelectedChild] = useState<any>(null);
  const [childCurrentPage, setChildCurrentPage] = useState<number>(1);

  const open = Boolean(openActionMenu);
  const { id }: any = useParams();
  const location = useLocation();
  const companyId = parseInt(id, 10);

  const dispatch = useDispatch();
  const { getCompanyList } = useSelector((state: any) => state.company);
  const getChildCompany = getCompanyList?.data?.Companies;

  const isCompanyNewRoute = location?.pathname?.includes('companies-temp')

  useEffect(() => {
    dispatch(
      getCompanyListAction({
        d: 'n',
        s: 'y',
        u: 'n',
        h: searchChildCompany,
        ipp: 10,
        pn: childCurrentPage,
        c: companyId,
      }),
    );
  }, [searchChildCompany, companyId, childCurrentPage]);

  const filteredChildCompanies = getChildCompany?.filter(
    (item: any) => item?.Id !== companyId,
  );
  const childCount = Math.ceil(getCompanyList?.data?.Total / 10);

  const handleSearchChildName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchChildCompany(e.target.value);
  };

  const handleChildActions = (event: any, item: any) => {
    setOpenActionMenu(event.currentTarget);
    setSelectedChild(item.Id);
  };

  const handleChildCompany = (companyId: string) => {
    if (companyId) {
      dispatch(getCompanyDetailAction({ id: companyId }));
    }
  };

  const handleUnsetParent = (companyId: string) => {
    dispatch(unsetParentCompanyAction({ companyId: companyId, parentId: id }));
    setOpenActionMenu(null);
  };

  const handleChangeChildPage = (event: any, value: number) => {
    setChildCurrentPage(value);
  };

  const handleOpenAddChild = () => {
    setOpenAddChildModal(true);
  };

  const handleCloseAddChild = () => {
    setOpenAddChildModal(false);
  };

  const renderTableBody = () => {
    if (getCompanyList?.loading) {
      return <LoaderWrapper size={25} />;
    }
    if (filteredChildCompanies?.length > 0) {
      return (
        <TableBody>
          {filteredChildCompanies?.map((item: any) => (
            <React.Fragment key={item?.Id}>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <StyledLink
                    to={`/companies/${encodeURIComponent(item?.Id)}/view`}
                    onClick={() => handleChildCompany(item?.Id)}>
                    {item?.Name}
                  </StyledLink>
                </TableCell>
                <TableCell align="left">{item?.Status || '-'}</TableCell>
                <TableCell align="left">
                  {item.Services?.map((service: any) => service.Name).join(', ') || '-'}
                </TableCell>
                <TableCell align="left">{formatedDate(item?.Created) || '-'}</TableCell>
                <TableCell align="left">
                  <ActionsButton
                    size="small"
                    onClick={(event) => handleChildActions(event, item)}
                    variant="contained"
                    endIcon={<KeyboardArrowDownIcon />}>
                    Actions
                  </ActionsButton>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      );
    }
    return <NoDataMessage message="No records found" />;
  };
  
  

  return (
    <div>
      <Paper elevation={1} sx={PaperStyle}>
        <Box sx={{ mb: '24px', display:'flex', justifyContent:'space-between' }}>
          <Input
            data-testid="search-child-company-input"
            size="small"
            placeholder="search for name"
            onChange={handleSearchChildName}
            value={searchChildCompany}
          />
          {isCompanyNewRoute && (
            <Button
              data-testid="add-child-entity-button"
              variant="contained"
              onClick={handleOpenAddChild}>
              Add Child Entity
            </Button>
          )}
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <CustomTableCell>COMPANY NAME</CustomTableCell>
              <CustomTableCell align="left">STATUS</CustomTableCell>
              <CustomTableCell align="left">SERVICES</CustomTableCell>
              <CustomTableCell align="left">CREATED DATE</CustomTableCell>
              <CustomTableCell align="left">ACTIONS</CustomTableCell>
            </TableRow>
          </TableHead>
          {renderTableBody()}
        </Table>
        {getCompanyList?.data?.Total > 10 && !getCompanyList?.loading && (
          <PaginationStyle>
            <Pagination
              data-testid="child-companies-pagination"
              count={childCount}
              page={childCurrentPage}
              onChange={handleChangeChildPage}
              color="primary"
            />
          </PaginationStyle>
        )}
        <Menu
          anchorEl={openActionMenu}
          open={open}
          onClose={() => setOpenActionMenu(null)}>
          <StyledLink
            to={`/companies/${encodeURIComponent(selectedChild)}/view`}>
            <MenuItem onClick={() => handleChildCompany(selectedChild)}>
              View
            </MenuItem>
          </StyledLink>
          <StyledLink
            to={`/companies/${encodeURIComponent(selectedChild)}/view`}>
            <MenuItem onClick={() => handleChildCompany(selectedChild)}>
              Update
            </MenuItem>
          </StyledLink>
          <StyledLink to="">
            <MenuItem onClick={() => handleUnsetParent(selectedChild)}>
              Unset Parent
            </MenuItem>
          </StyledLink>
          <MenuItem>Delete</MenuItem>
        </Menu>
      </Paper>
      <AddChildModal
        handleClose={handleCloseAddChild}
        open={openAddChildModal}
      />
    </div>
  );
};

export default ChildCompaniesTab;
