import { styled } from '@mui/material/styles';
import { Box, Typography, Divider as MuiDivider, Paper } from '@mui/material';
import { theme } from '../../../styles/theme';
import { TabPanel } from '../../../components';

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '15px',
  gap: 13
});

// Header
export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
});

export const HorizontalLine = styled(MuiDivider)(({ theme }) => ({
  margin: '10px 0',
  backgroundColor: theme.palette.background.paper,
}));

export const StyledButton = {
  width: 50,
  height: 40,
  border: `2px solid #E4E7EC`, // TODO
  borderRadius: '8px',
  backgroundColor: theme.palette.background.default,
  boxShadow: 'none'
}

// Divider
export const Divider = styled(Box)(({ theme }) => ({
  height: '30px',
  width: '4px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '9px',
}));

// Company Name
export const CompanyName = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontFamily: theme.typography.fontFamily,
  fontWeight: 'bold',
  color: theme.palette.text.primary
}));

// Top Section
export const CustomTabPanel = styled(TabPanel)(() => ({
  marginTop: '13px',
  borderRadius: '15px',
  borderWidth: 0,
}));

export const TopSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '15px',
});

// Services Container
export const ServicesContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
});

// ServiceBadge
export const ServiceBadge = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  color: theme.palette.secondary.main,
  padding: '5px 8px',
  borderRadius: '15px',
  fontSize: '12px',
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500
}));

// CompanyInfo Container
export const CompanyInfoContainer = styled(Box)({
  display: 'flex',
  gap: 8,
});

// CompanyInfoContent Box
export const CompanyInfoContent = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  border: `1px solid ${theme.palette.border?.main}`,
  padding: '10px 15px',
  borderRadius: '10px',
  textAlign: 'center',
  minWidth: 200
}));

export const CompanyInfoTitle = styled('span')(({ theme }) => ({
  fontSize: '12px',
  textWrap: 'nowrap',
  lineHeight: '22px',
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.inputPlaceholder,
  fontWeight: 500,
}));

export const CompanyInfoValue = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  textWrap: 'nowrap',
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.text.primary,
  fontWeight: '700',
}));

export const Content = styled(Box)(({ theme }) =>({
  background: theme.palette.background.paper,
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '13px',
  marginTop: '10px',
  boxShadow: '0px 20px 32px 15px rgba(193, 193, 193, 0.16)'

}));

export const TabsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 8
}));

export const Tab = styled('div', {
  shouldForwardProp: prop => prop !== 'isActive',
})<{ isActive: boolean }>(({ isActive, theme }) => ({
  padding: '8px 25px',
  cursor: 'pointer',
  fontSize: '14px',
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  color: isActive ? theme.palette.secondary.main : theme.palette.primary.dark,
  background: isActive ? theme.palette.background.paper : 'transparent',
  borderRadius: '6px',
  transition: 'all 0.3s ease-in-out',
  textAlign: 'center',
  justifyContent: 'center',
  border: `1px solid ${isActive ? theme.palette.border?.main : 'transparent'}`
}));


export const CompanyInfoLoader = styled(Paper)({
  display: 'flex',
  justifyContent:'center',
  alignItems: 'center',
  height: '70vh',
  borderRadius: '15px'
})