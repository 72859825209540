import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ConfirmationModal, Loader } from '../../components';
import {
  ActionWrapper,
  CompaniesLoader,
  CustomTableCell,
  StyledLink,
} from './Style/CompaniesList.style';
import { deleteCompanyAction } from '../../store/company/action';

const CompaniesTable = ({ searchCompanyName, companyCurrentPage }: any) => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');

  const { getCompanyList } = useSelector((state: any) => state.company);
  const userEmail = useSelector(
    (state:any) => state?.user?.userData?.data?.Email,
  );
  const companies = getCompanyList?.data?.Companies;
  const dispatch = useDispatch();

  const openDeleteModal = (companyName: string, companyId: string) => {
    setSelectedCompanyName(companyName);
    setSelectedCompanyId(companyId);
    setOpenConfirmModal(true);
  };

  const handleDeleteCompany = () => {
    dispatch(
      deleteCompanyAction({
        id: selectedCompanyId,
        h: searchCompanyName,
        pn: companyCurrentPage,
      }),
    );
    setOpenConfirmModal(false);
  };

  const formatDate = (dateString: string | number | Date) => {
    return new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(new Date(dateString));
  };

  const getCompanyDetailLink = (companyId: string) =>
    `/companies/${encodeURIComponent(companyId)}/view`;

  return (
    <>
      <TableContainer 
        data-testid="companies-table-container"
        component={Paper} 
        sx={{ border: '1px solid lightgray' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <CustomTableCell>Company Name</CustomTableCell>
              <CustomTableCell align="left">Status</CustomTableCell>
              <CustomTableCell align="left">Services</CustomTableCell>
              <CustomTableCell align="left">Created Date</CustomTableCell>
              <CustomTableCell align="left">Actions</CustomTableCell>
            </TableRow>
          </TableHead>
          {getCompanyList?.loading ? (
            <CompaniesLoader>
              <Loader size={25} />
            </CompaniesLoader>
          ) : companies?.length === 0 ? (
            <TableCell colSpan={5} align="center" sx={{ border: 0 }}>
              <Typography variant="subtitle1" sx={{ color: 'black', m: 6 }}>
                No Company
              </Typography>
            </TableCell>
          ) : (
            <TableBody>
              {companies?.map((companies: any) => (
                <TableRow
                  key={companies?.Id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>
                    <StyledLink
                      to={getCompanyDetailLink(companies?.Id)}>
                      {companies?.Name}
                    </StyledLink>
                  </TableCell>
                  <TableCell align="left">{companies?.status || '-'}</TableCell>
                  <TableCell align="left">
                    {companies?.Services?.length
                      ? companies.Services.map((item: any) => item.Name).join(
                          ', ',
                        )
                      : '-'}
                  </TableCell>
                  <TableCell align="left">
                    {formatDate(companies.Created)}
                  </TableCell>
                  <TableCell align="left">
                    <ActionWrapper>
                      <StyledLink
                        to={getCompanyDetailLink(companies?.Id)}>
                        <VisibilityIcon fontSize="small" />
                      </StyledLink>
                      <StyledLink
                        to={getCompanyDetailLink(companies?.Id)}>
                        <ModeEditOutlineIcon fontSize="small" />
                      </StyledLink>
                      <DeleteOutlineIcon
                        data-testid="delete-company-button"
                        fontSize="small"
                        onClick={() =>
                          openDeleteModal(companies?.Name, companies?.Id)
                        }
                        sx={{ cursor: 'pointer' }}
                      />
                    </ActionWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <ConfirmationModal
        title={`Are you sure you want to delete ${selectedCompanyName} ?`}
        subTitle="You won't be able to revert this !"
        confirmLabel="Yes, delete it !"
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
        handleUpdate={handleDeleteCompany}
      />
    </>
  );
};

export default CompaniesTable;
