import React from 'react';
import {
  Container,
  Content,
  ContentWrapper,
  HorizontalLine,
  InfoWrapper,
  StyledNormal,
  StyledParagraph,
  StyledStrong,
} from './Style/CompanyCreationV2.style';
import Title from '../../components/Title/Title';
import { TakedownModeProps } from '../../helpers/types';
import MultiSelectDropdown from '../../components/MultiselectDropDown/MultiSelectDropdown';

const TakedownMode: React.FC<TakedownModeProps> = ({
  takedownItems,
  selectedTakedownMode,
  handleTakedownModeChange,
}) => {
  const items = takedownItems.map((item: { label: string; }) => item.label);

  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Title size={'large'} title="Takedown Mode" />
        </ContentWrapper>
        <HorizontalLine />
        <InfoWrapper sx={{ gap: '8px' }}>
          <StyledParagraph>
            <StyledStrong>Manual Takedown:</StyledStrong>
            <StyledNormal sx={{ paddingLeft: '5px' }}>
              Customers request takedowns manually via the Client Panel.
            </StyledNormal>
          </StyledParagraph>
          <StyledParagraph>
            <StyledStrong>Semi-Automated Takedown:</StyledStrong>
            <StyledNormal sx={{ paddingLeft: '5px' }}>
              Run automatically outside working hours.
            </StyledNormal>
          </StyledParagraph>
          <StyledParagraph>
            <StyledStrong>Automated Takedown:</StyledStrong>
            <StyledNormal sx={{ paddingLeft: '5px' }}>
              Takedowns start automatically for alerted threats—no customer action needed.
            </StyledNormal>
          </StyledParagraph>
          <MultiSelectDropdown
            items={items}
            multiple={false}
            placeholder="Select Takedown Mode"
            selectedItems={selectedTakedownMode}
            onSelectionChange={handleTakedownModeChange}
            width={170}
          />
        </InfoWrapper>
      </Content>
    </Container>
  );
};

export default TakedownMode;
