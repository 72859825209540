import React from 'react'
import { Typography } from '@mui/material'
import { CardWrapper } from '../Wrapper/Wrapper.style';

export const NoRecordFound = () => {
  return (
    <CardWrapper>
       <Typography>No records found</Typography>
    </CardWrapper>
  )
}
