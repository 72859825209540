import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dropdown, GradientButton } from '../../../components';
import { ButtonWrapper } from '../../CompaniesList/Style/CompaniesList.style';
import {
  AddDomainDialog,
  CloseIconStyle,
  DomainLabel,
} from './Style/Tabs.style';
import {
  AddProtectedDomainModalType,
  CompaniesType,
} from '../../../helpers/types';
import { addProtectedDomainAction } from '../../../store/company/action';
import MultiDomainInput from '../../../components/MultiDomainInput/MultiDomainInput';

const AddProtectedDomainModal = ({
  handleClose,
  open,
}: AddProtectedDomainModalType) => {
  const [protectedDomainName, setProtectedDomainName] = useState<string[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [isOwnedDomain, setIsOwnedDomain] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { id } = useParams();
  const { getCompany } = useSelector((state: any) => state.company);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getCompany?.data) {
      const company = getCompany.data.find(
        (company: any) => String(company.Id) === id,
      );
      if (company) {
        setSelectedCompany({ label: company.Name, id: company.Id });
      }
    }
  }, [getCompany, id]);

  const resetAddProtectedModalData = () => {
    setProtectedDomainName([]);
    setIsOwnedDomain(false);
    setError('');
  };

  const handleAddProtectedModalClose = () => {
    resetAddProtectedModalData();
    handleClose();
  };

  const handleAddProtectedDomain = async () => {
    setError('');

    if (!selectedCompany) {
      setError('Please select a company.');
      return;
    }

    const domainList = protectedDomainName?.filter(domain => domain !== '');

    if (domainList?.length === 0) {
      setError('Please enter at least one domain.');
      return;
    }

    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    for (const domain of domainList) {
      if (!domainRegex.test(domain)) {
        setError('Please enter a valid domain.');
        return;
      }
    }

    const protectedDomains = domainList?.map(domain => ({
      name: domain,
      companyId: selectedCompany?.id,
      ownDomain: isOwnedDomain,
    }));

    dispatch(addProtectedDomainAction(protectedDomains));

    resetAddProtectedModalData();
    handleClose();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOwnedDomain(event.target.checked);
  };

  const companiesData = useCallback(
    getCompany?.data?.map((company: CompaniesType) => ({
      label: company?.Name,
      id: company?.Id,
    })),
    [getCompany?.data],
  );

  return (
    <AddDomainDialog onClose={handleAddProtectedModalClose} open={open}>
      <DialogTitle sx={{ m: 0, px: 3, fontWeight: 600 }}>
        Set Protection On Domain
      </DialogTitle>
      <IconButton onClick={handleAddProtectedModalClose} sx={CloseIconStyle}>
        <CloseIcon />
      </IconButton>
      <DialogContent
        dividers
        sx={{ '&.MuiDialogContent-root': { p: '16px 32px' } }}>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
            Company :
          </Typography>
          <Dropdown
            items={companiesData}
            size="small"
            placeholder="company list"
            label="company list"
            onChange={(e: any, value: any) => setSelectedCompany(value)}
            selectedValues={selectedCompany}
            multiple={false}
          />
        </Box>
        <DomainLabel>
          <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
            Domains :
          </Typography>
          <div style={{ width: '100%' }}>
            <MultiDomainInput
              data-testid="protected-domain-input"
              value={protectedDomainName}
              onChange={(newDomains: React.SetStateAction<string[]>) =>
                setProtectedDomainName(newDomains)
              }
              placeholder="Enter protected domains, separated by commas or spaces"
              error={error}
              sx={{ width: '100%' }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={isOwnedDomain}
                  onChange={handleCheckboxChange}
                />
              }
              label="Is owned domain?"
            />
          </div>
        </DomainLabel>
      </DialogContent>
      <ButtonWrapper sx={{ mt: 2 }}>
        <Button
          variant="outlined"
          onClick={handleAddProtectedModalClose}
          color="secondary">
          Cancel
        </Button>
        <GradientButton
          data-testid="submit-protected-domain-button"
          variant="contained"
          onClick={handleAddProtectedDomain}>
          Submit
        </GradientButton>
      </ButtonWrapper>
    </AddDomainDialog>
  );
};

export default AddProtectedDomainModal;
