import { ADD_NOTIFICATION } from '../constant';

const initialState = {
  data: []
};

export const notificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        data: [action.payload, ...state.data].slice(0, 50)
      };
    case 'CLEAR_ALL_NOTIFICATIONS':
      return {
        ...state,
        data: []
      };
    default:
      return state;
  }
};
