import React, { useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { TableHeaderCell, TakedownTable } from './Style/SingleThreat.style';
import { formatedDate, TakedownListHeader } from '../../utils';
import { Loader } from '../../components';
import { ThreatTypes } from '../../helpers/types';
import {
  getStatusTakedown,
  getTakedownDetail,
} from '../../store/takedown/action';
import { NoRecordFound } from '../../components/NoRecordFound/NoRecordFound';

const TakedownDetails = ({
  singleThreat,
}: {
  singleThreat: ThreatTypes | null;
}) => {
  const dispatch = useDispatch();

  const { takeDownDetails, getStatustakeDown } = useSelector(
    (state: any) => state.takedown,
  );
  const takeDownItems = takeDownDetails?.data?.items || [];

  useEffect(() => {
    if (singleThreat?.identification_status?.value === 'confirmed') {
      dispatch(
        getTakedownDetail({
          until: '',
          since: '',
          asset_id: singleThreat.protected_asset_id,
          threat_id: singleThreat.threat_id,
          sort: 'created',
          order: 'descending',
          page: 1,
          size: 50,
        }),
      );
    }
  }, [singleThreat?.identification_status?.value]);

  useEffect(() => {
    dispatch(getStatusTakedown());
  }, []);

  const findStatusNameById = (id: number) => {
    const status = getStatustakeDown?.data?.find(
      (status: any) => status?.id === id,
    );
    return status ? status.name : 'Unknown';
  };

  const renderTakeDownDetailsContent = () => {
    if (takeDownDetails?.loading) {
      // Show loading indicator if data is being fetched
      return (
        <TableCell colSpan={5} align="center" sx={{ border: 0 }}>
          <Box sx={{ m: 3 }}>
            <Loader size={25} />
          </Box>
        </TableCell>
      );
    }
  
    if (!takeDownItems || takeDownItems?.length === 0) {
      // Show 'No records found' if no items are available
      return (
        <TableCell colSpan={5} align="center" sx={{ border: 0 }}>
          <NoRecordFound />
        </TableCell>
      );
    }
  
    // Render table rows if items are available
    return takeDownItems?.map((item: any, index: number) => (
      <TableRow
        key={index}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          height: '50px',
        }}
      >
        <TableCell>{item?.user_id}</TableCell>
        <TableCell align="left">{formatedDate(item?.created)}</TableCell>
        <TableCell align="left">{formatedDate(item?.updated_at)}</TableCell>
        <TableCell align="left">{findStatusNameById(item?.status_id)}</TableCell>
        <TableCell align="left">{item.description || '-'}</TableCell>
      </TableRow>
    ));
  }

  return (
    <Box>
      <Typography variant="h6" sx={{ color: 'black' }}>
        Takedown Details
      </Typography>
      <TakedownTable>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader size="small" sx={{ border: 0 }}>
            <TableHead>
              <TableRow>
                {TakedownListHeader.map((item) => (
                  <TableHeaderCell key={item} align="left">
                    {item}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderTakeDownDetailsContent()}
            </TableBody>
          </Table>
        </TableContainer>
      </TakedownTable>
    </Box>
  );
};

export default TakedownDetails;
