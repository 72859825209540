import {
  Box,
  TableCell,
  styled,
  Divider as MuiDivider,
  IconButton,
} from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { theme } from '../../../styles/theme';

export const FilterWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const CompanyInputStyle = {
  width: 845,
};

export const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: 24,
});

export const ContentWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const Content = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  display: 'flex',
  padding: 16,
  gap: 13,
  flexDirection: 'column',
  borderRadius: 13,
  boxShadow: '0 18px 32px 16px #C1C1C129',
}));

export const CardWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  paddingBottom: 12,
}));

export const HorizontalLine = styled(MuiDivider)(({ theme }) => ({
  backgroundColor: theme.palette.border?.main,
}));

export const SearchInputAdornment = styled('img')({
  padding: '10px 0px 10px 6px',
  width: 21,
  height: 21,
});

export const ClearAdornment = styled(ClearIcon)(({ theme }) => ({
  color: theme.palette.text.inputPlaceholder,
  width: 21,
  height: 21,
}));

export const CloseIconButton = styled(IconButton)({
  padding: '10px 0px',
  width: 21,
  height: 21,
});

export const CustomTableCell = styled(TableCell)({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontWeight: 700,
  fontSize: 16,
  padding: '14px 24px',
  textAlign: 'left',
  lineHeight: '20px',
  borderBottom: `1px solid ${theme.palette.border?.main}`,
  backgroundColor: 'transparent',

  '&:last-child': {
    textAlign: 'end',
    width: 100,
  },
});

export const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border?.main}`,
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  lineHeight: '20px',
  padding: '8px 24px',
  fontSize: 16,
  textAlign: 'left',
  color: theme.palette.text.primary,
  '&:first-child': {
    minWidth: 250,
  },
  '&:last-child': {
    textAlign: 'end',
    width: 100,
  },
  '&:nth-last-child(2)': {
    whiteSpace: 'nowrap',
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none !important',
  color: 'black',
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));
