import {
  Box,
  Dialog,
  DialogTitle,
  LinearProgress,
  Typography,
  styled,
} from '@mui/material';
import { TimelineItem, TimelineOppositeContent } from '@mui/lab';
import { Button } from '../../../../components';

export const PaperStyle = {
  backgroundColor: 'white',
  height: 'auto',
  width: '50%',
  p: 2.5,
};

export const OverviewTitle = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
}));

export const TimelineCreated = styled(TimelineOppositeContent)(() => ({
  flex: 0,
  fontSize: '11px',
  fontWeight: 600,
  minWidth: '70px',
}));

export const LatestThreatWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '20px 0',
  paddingBottom: '6px',
  borderBottom: '1px solid gray',
  borderBottomStyle: 'dashed',
}));

export const ViewAllButton = styled(Button)(() => ({
  backgroundColor: '#f5f8fa',
  color: 'black',
  padding: '4px 8px',
  height: 'fit-content',
  '&:hover': {
    color: 'white',
  },
}));

export const StatusLabel = styled(Typography)(() => ({
  backgroundColor: '#fff5f8',
  color: '#f1416c',
  padding: '3px 10px',
  borderRadius: '.675rem',
  fontWeight: 600,
  fontSize: '12px',
}));

export const AddDomainDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
  },
  '& .MuiDialog-paper': {
    width: '600px',
  },
}));

export const CloseIconStyle = {
  position: 'absolute',
  right: 8,
  top: 8,
};

export const DomainLabel = styled(Box)(() => ({
  display: 'flex',
  margin: '24px 0 14px',
}));

export const DialogDate = styled(DialogTitle)(() => ({
  fontWeight: 600,
  fontSize: '16px',
  alignItems: 'center',
  display: 'flex',
  gap: '10px',
}));

export const TimelineItemStyle = styled(TimelineItem)(() => ({
  '&.MuiTimelineItem-root:before': {
    flex: 0,
    padding: 0,
  },
}));

export const LoaderWrapper = styled(Box)({
  alignItems: 'center',
  height: '150px',
  display: 'flex',
  justifyContent: 'center',
});

export const InformationTitle = styled(DialogTitle)(() => ({
  fontWeight: 600,
  fontSize: '16px',
  alignItems: 'center',
  display: 'flex',
  gap: '10px',
}));

export const LogoStyle = {
  height: 130,
  width: 130,
  borderRadius: '6px',
  border: '3px solid #fff',
  boxShadow: '0 .5rem 1.5rem .5rem rgba(0, 0, 0, .075)',
};

export const InformationButtonWrapper = styled(Box)(() => ({
  justifyContent: 'end',
  gap: '14px',
  display: 'flex',
  padding: '24px',
}));

export const AddUserDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3, 5),
  },
  '& .MuiDialog-paper': {
    width: '600px',
  },
}));

export const UserLabel = styled(Box)(() => ({
  display: 'flex',
  margin: '24px 0 14px',
  marginBottom: 1,
}));

export const FilterWrapper = styled(Box)(() => ({
  display: 'flex',
  marginBottom: '24px',
  justifyContent: 'space-between',
}));

export const ActionsButton = styled(Button)(() => ({
  background: '#f5f8fa',
  color: 'black',
  '&:hover': {
    backgroundColor: '#f5f8fa',
  },
}));

export const DomainNameStyle = styled(Box)(() => ({
  textDecoration: 'none !important',
  cursor: 'pointer',
  color: 'black',
  '&:hover': {
    color: '#00b2ff',
  },
}));

export const DomainDetailsStyle = styled(Box)(() => ({
  paddingBottom: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid gray',
  borderBottomStyle: 'dashed',
}));

export const KeywordsLabel = styled(Box)(() => ({
  backgroundColor: '#e4e6ef',
  color: '#3f4254',
  display: 'inline-table',
  borderRadius: '6px',
  padding: '5px 9px',
  margin: '2px',
  fontSize: '11px',
  fontWeight: 600,
}));

export const ProgressWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
}));

export const ProgressText = styled(Typography)(({ theme }) => ({
  minWidth: '120px',
  fontWeight: 'bold',
  marginRight: '10px',
}));

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  width: '100%',
  height: 12,
  borderRadius: 5,
  backgroundColor: '#e0e0e0',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#0fd6c2',
    borderRadius: 5,
  },
}));

export const ProgressCount = styled(Typography)(({ theme }) => ({
  minWidth: '50px',
  fontWeight: 'bold',
  marginLeft: '10px',
  textAlign: 'center',
}));
