import {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_CLIENTID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_REDIRECTURL,
} from '../utils/envVar';

export const providerConfig: any = {
  domain: REACT_APP_AUTH0_DOMAIN,
  clientId: REACT_APP_AUTH0_CLIENTID,
  authorizationParams: {
    redirect_uri: REACT_APP_AUTH0_REDIRECTURL,
    audience: REACT_APP_AUTH0_AUDIENCE,
  },
  cacheLocation: "localstorage",
};
