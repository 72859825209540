import { CompanyReducerType } from '../../helpers/types';
import { defaultServiceFilter, defaultSourcesFilter } from '../../utils';
import {
  ADD_CHILD_COMPANY_BEGIN,
  ADD_CHILD_COMPANY_FAILURE,
  ADD_CHILD_COMPANY_SUCCESS,
  ADD_COMPANY_BEGIN,
  ADD_COMPANY_FAILURE,
  ADD_COMPANY_SUCCESS,
  ADD_PROTECTED_DOMAINS_BEGIN,
  ADD_PROTECTED_DOMAINS_FAILURE,
  ADD_PROTECTED_DOMAINS_SUCCESS,
  DELETE_COMPANY_BEGIN,
  DELETE_COMPANY_FAILURE,
  DELETE_COMPANY_SUCCESS,
  GET_COMPANYDETAIL_BEGIN,
  GET_COMPANYDETAIL_FAILURE,
  GET_COMPANYDETAIL_SUCCESS,
  GET_COMPANYLIST_BEGIN,
  GET_COMPANYLIST_FAILURE,
  GET_COMPANYLIST_SUCCESS,
  GET_COMPANY_BEGIN,
  GET_COMPANY_FAILURE,
  GET_PROTECTED_DOMAINS_BEGIN,
  GET_PROTECTED_DOMAINS_FAILURE,
  GET_PROTECTED_DOMAINS_SUCCESS,
  GET_COMPANY_SUCCESS,
  GET_KEYWORDS_BEGIN,
  GET_KEYWORDS_FAILURE,
  GET_KEYWORDS_SUCCESS,
  GET_SERVICE_BEGIN,
  GET_SERVICE_FAILURE,
  GET_SERVICE_SUCCESS,
  UPDATE_COMPANY_DETAILS_BEGIN,
  UPDATE_COMPANY_DETAILS_FAILURE,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPDATE_COMPANY_INDUSTRY_BEGIN,
  UPDATE_COMPANY_INDUSTRY_FAILURE,
  UPDATE_COMPANY_INDUSTRY_SUCCESS,
  UPDATE_COMPANY_SERVICE_BEGIN,
  UPDATE_COMPANY_SERVICE_FAILURE,
  UPDATE_COMPANY_SERVICE_SUCCESS,
  DOMAIN_UNPROTECT_BEGIN,
  DOMAIN_UNPROTECT_SUCCESS,
  DOMAIN_UNPROTECT_FAILURE,
  CHANGE_STATUS_DOMAIN_BEGIN,
  CHANGE_STATUS_DOMAIN_SUCCESS,
  CHANGE_STATUS_DOMAIN_FAILURE,
  GET_DOMAIN_KEYWORDS_FAILURE,
  GET_DOMAIN_KEYWORDS_SUCCESS,
  GET_DOMAIN_KEYWORDS_BEGIN,
  ADD_DOMAIN_KEYWORDS_FAILURE,
  ADD_DOMAIN_KEYWORDS_SUCCESS,
  ADD_DOMAIN_KEYWORDS_BEGIN,
  UNSET_PARENT_COMPANY_BEGIN,
  UNSET_PARENT_COMPANY_SUCCESS,
  UNSET_PARENT_COMPANY_FAILURE,
  UPDATE_DOMAIN_PROGRESS,
} from '../constant';

const initialState = {
  getCompany: {
    loading: false,
    data: [],
    error: null,
  },
  getCompanyList: {
    loading: false,
    data: [],
    error: null,
  },
  deleteCompany: {
    loading: false,
    data: [],
    error: null,
  },
  addCompany: {
    loading: false,
    data: [],
    error: null,
  },
  addChildCompany: {
    loading: false,
    data: [],
    error: null,
  },
  unsetParentCompany: {
    loading: false,
    data: [],
    error: null,
  },
  getCompanyDetail: {
    loading: false,
    data: [],
    error: null,
  },
  getProtectedDomains: {
    loading: false,
    data: [],
    error: null,
  },
  addProtectedDomains: {
    loading: false,
    data: [],
    error: null,
  },
  domainUnprotect: {
    loading: false,
    data: [],
    error: null,
  },
  changeStatusDomain: {
    loading: false,
    data: [],
    error: null,
  },
  getDomainKeywords: {
    loading: false,
    data: [],
    error: null,
  },
  addDomainKeywords: {
    loading: false,
    data: [],
    error: null,
  },
  getService: {
    loading: false,
    data: [],
    error: null,
  },
  updateCompanyDetails: {
    loading: false,
    data: [],
    error: null,
  },
  updateCompanyIndustry: {
    loading: false,
    data: [],
    error: null,
  },
  updateCompanyService: {
    loading: false,
    data: [],
    error: null,
  },
  getKeywords: {
    loading: false,
    data: [],
    error: null,
  },
  domainProgress: { processed: 0, total: 0 },
};

export const companyReducer = (
  state = initialState,
  action: CompanyReducerType,
) => {
  switch (action.type) {
    case GET_COMPANY_BEGIN:
      return {
        ...state,
        getCompany: {
          ...state.getCompany,
          loading: true,
        },
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        getCompany: {
          ...state.getCompany,
          loading: false,
          data: action.payload,
        },
      };
    case GET_COMPANY_FAILURE:
      return {
        ...state,
        getCompany: {
          ...state.getCompany,
          loading: false,
          error: action.payload,
        },
      };
    case GET_COMPANYLIST_BEGIN:
      return {
        ...state,
        getCompanyList: {
          ...state.getCompanyList,
          loading: true,
        },
      };
    case GET_COMPANYLIST_SUCCESS:
      return {
        ...state,
        getCompanyList: {
          ...state.getCompanyList,
          loading: false,
          data: action.payload,
        },
      };
    case GET_COMPANYLIST_FAILURE:
      return {
        ...state,
        getCompanyList: {
          ...state.getCompanyList,
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_COMPANY_BEGIN:
      return {
        ...state,
        deleteCompany: {
          ...state.deleteCompany,
          loading: true,
        },
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        deleteCompany: {
          ...state.deleteCompany,
          loading: false,
          data: action.payload,
        },
      };
    case DELETE_COMPANY_FAILURE:
      return {
        ...state,
        deleteCompany: {
          ...state.deleteCompany,
          loading: false,
          error: action.payload,
        },
      };
    case ADD_COMPANY_BEGIN:
      return {
        ...state,
        addCompany: {
          ...state.addCompany,
          loading: true,
        },
      };
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        addCompany: {
          ...state.addCompany,
          loading: false,
          data: action.payload,
        },
      };
    case ADD_COMPANY_FAILURE:
      return {
        ...state,
        addCompany: {
          ...state.addCompany,
          loading: false,
          error: action.payload,
        },
      };
    case ADD_CHILD_COMPANY_BEGIN:
      return {
        ...state,
        addChildCompany: {
          ...state.addChildCompany,
          loading: true,
        },
      };
    case ADD_CHILD_COMPANY_SUCCESS:
      return {
        ...state,
        addChildCompany: {
          ...state.addChildCompany,
          loading: false,
          data: action.payload,
        },
      };
    case ADD_CHILD_COMPANY_FAILURE:
      return {
        ...state,
        addChildCompany: {
          ...state.addChildCompany,
          loading: false,
          error: action.payload,
        },
      };
    case UNSET_PARENT_COMPANY_BEGIN:
      return {
        ...state,
        unsetParentCompany: {
          ...state.unsetParentCompany,
          loading: true,
        },
      };
    case UNSET_PARENT_COMPANY_SUCCESS:
      return {
        ...state,
        unsetParentCompany: {
          ...state.unsetParentCompany,
          loading: false,
          data: action.payload,
        },
      };
    case UNSET_PARENT_COMPANY_FAILURE:
      return {
        ...state,
        unsetParentCompany: {
          ...state.unsetParentCompany,
          loading: false,
          error: action.payload,
        },
      };
    case GET_COMPANYDETAIL_BEGIN:
      return {
        ...state,
        getCompanyDetail: {
          ...state.getCompanyDetail,
          loading: true,
        },
      };
    case GET_COMPANYDETAIL_SUCCESS:
      return {
        ...state,
        getCompanyDetail: {
          ...state.getCompanyDetail,
          loading: false,
          data: action.payload,
        },
      };
    case GET_COMPANYDETAIL_FAILURE:
      return {
        ...state,
        getCompanyDetail: {
          ...state.getCompanyDetail,
          loading: false,
          error: action.payload,
        },
      };
    case GET_PROTECTED_DOMAINS_BEGIN:
      return {
        ...state,
        getProtectedDomains: {
          ...state.getProtectedDomains,
          loading: true,
        },
      };
    case GET_PROTECTED_DOMAINS_SUCCESS:
      return {
        ...state,
        getProtectedDomains: {
          ...state.getProtectedDomains,
          loading: false,
          data: action.payload,
        },
      };
    case GET_PROTECTED_DOMAINS_FAILURE:
      return {
        ...state,
        getProtectedDomains: {
          ...state.getProtectedDomains,
          loading: false,
          error: action.payload,
        },
      };
    case ADD_PROTECTED_DOMAINS_BEGIN:
      return {
        ...state,
        addProtectedDomains: {
          ...state.addProtectedDomains,
          loading: true,
        },
      };
    case ADD_PROTECTED_DOMAINS_SUCCESS:
      return {
        ...state,
        addProtectedDomains: {
          ...state.addProtectedDomains,
          loading: false,
          data: action.payload,
        },
      };
    case ADD_PROTECTED_DOMAINS_FAILURE:
      return {
        ...state,
        addProtectedDomains: {
          ...state.addProtectedDomains,
          loading: false,
          error: action.payload,
        },
      };
    case DOMAIN_UNPROTECT_BEGIN:
      return {
        ...state,
        domainUnprotect: {
          ...state.domainUnprotect,
          loading: true,
        },
      };
    case DOMAIN_UNPROTECT_SUCCESS:
      return {
        ...state,
        domainUnprotect: {
          ...state.domainUnprotect,
          loading: false,
          data: action.payload,
        },
      };
    case DOMAIN_UNPROTECT_FAILURE:
      return {
        ...state,
        domainUnprotect: {
          ...state.domainUnprotect,
          loading: false,
          error: action.payload,
        },
      };
    case CHANGE_STATUS_DOMAIN_BEGIN:
      return {
        ...state,
        changeStatusDomain: {
          ...state.changeStatusDomain,
          loading: true,
        },
      };
    case CHANGE_STATUS_DOMAIN_SUCCESS:
      return {
        ...state,
        changeStatusDomain: {
          ...state.changeStatusDomain,
          loading: false,
          data: action.payload,
        },
      };
    case CHANGE_STATUS_DOMAIN_FAILURE:
      return {
        ...state,
        changeStatusDomain: {
          ...state.changeStatusDomain,
          loading: false,
          error: action.payload,
        },
      };

    case GET_DOMAIN_KEYWORDS_BEGIN:
      return {
        ...state,
        getDomainKeywords: {
          ...state.getDomainKeywords,
          loading: true,
        },
      };
    case GET_DOMAIN_KEYWORDS_SUCCESS:
      return {
        ...state,
        getDomainKeywords: {
          ...state.getDomainKeywords,
          loading: false,
          data: action.payload,
        },
      };
    case GET_DOMAIN_KEYWORDS_FAILURE:
      return {
        ...state,
        getDomainKeywords: {
          ...state.getDomainKeywords,
          loading: false,
          error: action.payload,
        },
      };
    case ADD_DOMAIN_KEYWORDS_BEGIN:
      return {
        ...state,
        addDomainKeywords: {
          ...state.addDomainKeywords,
          loading: true,
        },
      };
    case ADD_DOMAIN_KEYWORDS_SUCCESS:
      return {
        ...state,
        addDomainKeywords: {
          ...state.addDomainKeywords,
          loading: false,
          data: action.payload,
        },
      };
    case ADD_DOMAIN_KEYWORDS_FAILURE:
      return {
        ...state,
        addDomainKeywords: {
          ...state.addDomainKeywords,
          loading: false,
          error: action.payload,
        },
      };
    case GET_SERVICE_BEGIN:
      return {
        ...state,
        getService: {
          ...state.getService,
          loading: true,
        },
      };
    case GET_SERVICE_SUCCESS:
      return {
        ...state,
        getService: {
          ...state.getService,
          loading: false,
          data: action.payload,
        },
      };
    case GET_SERVICE_FAILURE:
      return {
        ...state,
        getService: {
          ...state.getService,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_COMPANY_DETAILS_BEGIN:
      return {
        ...state,
        updateCompanyDetails: {
          ...state.updateCompanyDetails,
          loading: true,
        },
      };
    case UPDATE_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        updateCompanyDetails: {
          ...state.updateCompanyDetails,
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        updateCompanyDetails: {
          ...state.updateCompanyDetails,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_COMPANY_INDUSTRY_BEGIN:
      return {
        ...state,
        updateCompanyIndustry: {
          ...state.updateCompanyIndustry,
          loading: true,
        },
      };
    case UPDATE_COMPANY_INDUSTRY_SUCCESS:
      return {
        ...state,
        updateCompanyIndustry: {
          ...state.updateCompanyIndustry,
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_COMPANY_INDUSTRY_FAILURE:
      return {
        ...state,
        updateCompanyIndustry: {
          ...state.updateCompanyIndustry,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_COMPANY_SERVICE_BEGIN:
      return {
        ...state,
        updateCompanyService: {
          ...state.updateCompanyService,
          loading: true,
        },
      };
    case UPDATE_COMPANY_SERVICE_SUCCESS:
      return {
        ...state,
        updateCompanyService: {
          ...state.updateCompanyService,
          loading: false,
          data: action.payload,
        },
      };
    case UPDATE_COMPANY_SERVICE_FAILURE:
      return {
        ...state,
        updateCompanyService: {
          ...state.updateCompanyService,
          loading: false,
          error: action.payload,
        },
      };
    case GET_KEYWORDS_BEGIN:
      return {
        ...state,
        getKeywords: {
          ...state.getKeywords,
          loading: true,
        },
      };
    case GET_KEYWORDS_SUCCESS:
      return {
        ...state,
        getKeywords: {
          ...state.getKeywords,
          loading: false,
          data: action.payload,
        },
      };
    case GET_KEYWORDS_FAILURE:
      return {
        ...state,
        getKeywords: {
          ...state.getKeywords,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_DOMAIN_PROGRESS:
      return {
        ...state,
        domainProgress: action.payload,
      };
    default:
      return state;
  }
};
