import React, { useEffect, useState } from 'react';
import { Box, Typography, Modal } from '@mui/material';
import { CreateThreatModelType } from '../../helpers/types';
import { Button, Input, SelectOption } from '../../components';
import { buttonContainerStyles, GSBStyle, modalStyles } from './Style/SingleThreat.style';
import { useGSBHistoryContext } from '../../context/GsbHistory/GsbHistoryContext';
import { GetGSBParms, GSBDataSubmitType, NetworkDataType, RegionCodeType } from '../../context/GsbHistory/GsbHistoryType';
import { isValidUrl } from '../../utils';

const GSBModel = ({
  open,
  handleClose,
  startDate,
  endDate,
  threatName,
  isSubmitForUrl = false
}: CreateThreatModelType) => {
  const [comments, setComments] = useState<string>('');
  const [regionCode, setRegionCode] = useState<string>('');
  const [platform, setPlatform] = useState<string>('');
  const [abuseType, setAbuseType] = useState<string>('SOCIAL_ENGINEERING');
  const [label, setLabel] = useState<string>('MANUAL_VERIFICATION');
  const [url, setUrl] = useState<string>('');
  const [errors, setErrors] = useState<any>({});

  const {
    fetchRegionCodes,
    regionCodesData,
    fetchPlatforms,
    platformsData,
    fetchNetworkThreats,
    networkCheckThreat,
    fetchLabels,
    labelsData,
    submitToGsbAction,
  } = useGSBHistoryContext();

  const mappedRegionCodes: { label: string; value: string }[] =
    regionCodesData?.map(({ name, code }: RegionCodeType) => ({
      label: name,
      value: code,
    })) || [];

  const mappedLabels: { label: string; value: string }[] =
    labelsData?.map(({ name, value }: NetworkDataType) => ({
      label: name,
      value,
    })) || [];

  const mappedPlatforms: { label: string; value: string }[] =
    platformsData?.map(({ name, value }: NetworkDataType) => ({
      label: name,
      value,
    })) || [];

  const abuseOptions: { label: string; value: string }[] =
    networkCheckThreat?.map((threat: NetworkDataType) => ({
      label: threat.name,
      value: threat.value,
    })) || [];

  useEffect(() => {
    if (!regionCodesData?.length) fetchRegionCodes();
    if (!platformsData?.length) fetchPlatforms();
    if (!networkCheckThreat?.length) fetchNetworkThreats();
    if (!labelsData?.length) fetchLabels();
  }, [regionCodesData, platformsData, networkCheckThreat, labelsData]);

  const validateForm = () => {
    const formErrors: { [key: string]: string } = {};

    if (isSubmitForUrl) {
      if (!url) formErrors.url = 'URL is required';
      else if (!isValidUrl(url)) formErrors.url = 'Please provide a valid URL!';
    }
    if (!regionCode) formErrors.regionCode = 'Region code is required';
    if (!platform) formErrors.platform = 'Platform is required';
    if (!abuseType) formErrors.abuseType = 'Abuse type is required';
    if (!label) formErrors.label = 'Label is required';
    if (!comments) formErrors.comments = 'Comments are required';
    setErrors(formErrors);
    return formErrors;
  };
  const handleSubmitGSB = async () => {
    const formErrors = validateForm();
  

    if (Object.values(formErrors).some(error => error !== '')) {
      return;
    }
    const data: GSBDataSubmitType = {
      url: isSubmitForUrl ? url: `https://${threatName}`,
      region_codes: regionCode,
      platform,
      abuse_type: abuseType,
      comments,
      labels: [label],
    };
    const getGsbParams: GetGSBParms ={
      startDate,
      endDate,
      threatName
    }
    try {
      handleClose();
      resetForm();
      submitToGsbAction(data, getGsbParams);
    } catch (error) {
      console.error('Error submitting GSB Data:', error);
    }
  };

  const resetForm = () => {
    setComments('');
    setRegionCode('');
    setPlatform('');
    setAbuseType('');
    setLabel('');
    setUrl('');
  };

  const handleCancel = () => {
    resetForm();
    setErrors({});
    handleClose();
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUrl = e.target.value;
    setUrl(newUrl);
  };

  const isSubmitButtonDisabled = () => {
    if (
      (isSubmitForUrl && (!url || !isValidUrl(url))) ||
      !regionCode ||
      !platform ||
      !abuseType ||
      !label ||
      !comments
    ) {
      return true;
    }
    return false;
  };

  const handleUrlBlur = () => {
    const formErrors = { ...errors };
    if (url.trim() === '') {
      formErrors.url = 'URL is required';
    } else if (!isValidUrl(url)) {
      formErrors.url = 'Please provide a valid URL!';
    } else {
      formErrors.url = '';
    }
    setErrors(formErrors);
  };

  const renderGSBLabel = (label: string) => {
    return (
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
        {label}
      </Typography>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...modalStyles, maxHeight: '90vh', overflowY: 'auto' }}>
        <Box>
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: 1, fontWeight: 'bold' }}>
            GSB
          </Typography>

          {/* Information Section */}
          <Typography variant="body2">
            All GSB submissions should be carefully verified as Google has
            minimal tolerance for inaccuracies. Submissions are sent with the
            Confidence Level automatically set to High or Level 3. Please
            consult the Google Safe Browsing{' '}
            <GSBStyle
              href="https://cloud.google.com/web-risk/docs/reference/rest/v1/projects.uris/submit#ConfidenceLevel"
              target="_blank"
              rel="noopener noreferrer">
              documentation
            </GSBStyle>{' '}
            for more information.
          </Typography>
        </Box>

        {/* Submission Section */}
        <Box>
          {isSubmitForUrl && (
            <>
              {renderGSBLabel('Submission')}
              <Input
                size="small"
                placeholder="Enter a valid URL"
                label="URL"
                value={url}
                sx={{ width: '100%', borderRadius: 1 }}
                onChange={handleUrlChange}
                onBlur={handleUrlBlur}
                error={!!errors.url}
                helperText={errors.url || ''}
              />
            </>
          )}
        </Box>

        {/* Threat Information Section */}
        <Box>
          {renderGSBLabel('Threat Information')}
          <SelectOption
            label="Abuse Type"
            size="small"
            onChange={event => setAbuseType(event.target.value)}
            value={abuseType}
            options={abuseOptions}
            width="100%"
            error={!!errors.abuseType}
            helperText={errors.abuseType || ''}
          />
        </Box>

        {/* Threat Justification Section */}
        <Box>
          {renderGSBLabel('Threat Justification')}
          <SelectOption
            label="Labels"
            size="small"
            onChange={event => setLabel(event.target.value)}
            value={label}
            options={mappedLabels}
            width="100%"
            error={!!errors.label}
            helperText={errors.label || ''}
          />
          <Input
            size="small"
            placeholder="Write a comment"
            label="Comments"
            value={comments}
            sx={{ width: '100%', borderRadius: 1, mt: 2 }}
            onChange={e => setComments(e.target.value)}
            error={!!errors.comments}
            helperText={errors.comments || ''}
          />
        </Box>

        {/* Threat Discovery Section */}
        <Box>
          {renderGSBLabel('Threat Discovery')}
          <Box>
            <SelectOption
              label="Platform"
              size="small"
              onChange={event => setPlatform(event.target.value)}
              value={platform}
              options={mappedPlatforms}
              width="100%"
              error={!!errors.platform}
              helperText={errors.platform || ''}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <SelectOption
              label="Region Code"
              size="small"
              onChange={event => setRegionCode(event.target.value)}
              value={regionCode}
              options={mappedRegionCodes}
              width="100%"
              error={!!errors.regionCode}
              helperText={errors.regionCode || ''}
            />
          </Box>
        </Box>

        {/* Buttons Section */}
        <Box sx={buttonContainerStyles}>
          <Button
            onClick={handleCancel}
            variant="outlined"
            color="secondary"
            sx={{
              flex: 1,
              borderRadius: 1,
              '&:hover': { borderColor: '#ff4081' },
            }}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmitGSB}
            label="Submit"
            variant="contained"
            color="primary"
            disabled={isSubmitButtonDisabled()}
            sx={{ flex: 1, borderRadius: 1 }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default GSBModel;
