import {
  styled,
  Box,
  Grid,
  Typography,
  Button,
  TableCell,
} from '@mui/material';

export const GSBHistoryHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const GSBHistoryTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: 'black',
}));

export const GSBHistoryFilters = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '16px',
  marginBottom: '24px',
  gap: '8px',
}));

export const GSBHistoryGridContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}));

export const GSBHistoryInput = styled(Grid)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

export const GSBHistoryGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ResetButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const GradientSearchButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const PaginationStyle = styled(Box)({
  display: 'flex',
  justifyContent: 'right',
  margin: '12px 0',
});

export const CustomTableCell = styled(TableCell)(() => ({
  background: 'rgb(249, 250, 252)',
  color: 'rgba(58, 53, 65, 0.87)',
  fontWeight: 'bold',
}));
