import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CompanySectionTabs from './CompanyDetailsTabsV2';
import { Button } from '../../components';
import {
  ServiceBadge,
  ServicesContainer,
  CompanyName,
  Container,
  Content,
  Divider,
  Header,
  HorizontalLine,
  CompanyInfoContent,
  CompanyInfoContainer,
  CompanyInfoTitle,
  CompanyInfoValue,
  TopSection,
  StyledButton,
} from './style/CompanyDetailsV2.style';
import AddChildModal from '../CompanyDetails/AddChildModal';
import { getCompanyDetailAction } from '../../store/company/action';
import { theme } from '../../styles/theme';
import { hasCompanyV2Access, renderSkeleton } from '../../utils';

const CompanyDetailsV2: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [openAddChildModal, setOpenAddChildModal] = useState<boolean>(false);
  const [domainCurrentPage, setDomainCurrentPage] = useState<number>(1);
  const [searchDomainName, setSearchDomainName] = useState<string>('');
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { id: companyId } = useParams();

  const { getCompanyDetail } = useSelector((state: any) => state.company);
  const companyDetail = getCompanyDetail.data;
  const userEmail = useSelector(
    (state:any) => state?.user.userData?.data?.Email,
  );
  const domainCount = Math.ceil(companyDetail?.Total / 10);
  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetailAction({ id: companyId }));
    }
  }, [companyId]);

  // TODO: Temporary: company access handled in frontend, to be removed before production.
   useEffect(()=>{
     const hasPermission = hasCompanyV2Access(userEmail)
     if(!hasPermission) {
      navigate(`/companies/${companyId}/view`)
     }
   },[userEmail])

  const handleCloseAddChild = () => {
    setOpenAddChildModal(false);
  };

  const handleChangeDomainPage = (event: any, value: number) => {
    setDomainCurrentPage(value);
  };

  const handleChangeDomainName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchDomainName(e.target.value);
  };

  const tabs = [
    { name: 'Service and Information', testId: 'company-service-info-tab' },
    { name: `Protected Domains (${companyDetail?.Domains?.length || 0})`, testId: 'company-protected-domains-tab' },
    { name: 'Keywords', testId: 'company-keywords-tab' },
    { name: 'Users', testId: 'company-users-tab' },
    { name: 'Historical Scan?', testId: 'company-historical-scan-tab' },
    { name: 'Child Companies?', testId: 'company-child-companies-tab' },
  ];

  return (
    <Container>
      <Content>
        <Header>
          <Button size="small" sx={StyledButton} onClick={() => navigate(-1)} data-test-id="company-back-button">
            <ChevronLeftIcon sx={{ color: theme.palette.primary.dark }} />
          </Button>
          <Divider />
          {getCompanyDetail?.loading ? (
            renderSkeleton('20%')
          ) : (
            <CompanyName>{companyDetail?.Name}</CompanyName>
          )}
        </Header>
        <HorizontalLine />
        <TopSection>
          {getCompanyDetail.loading ? (
            renderSkeleton('20%')
          ) : (
            <ServicesContainer>
              {companyDetail?.Services?.map(
                (service: { Id: number; Name: string }) => (
                  <ServiceBadge key={service?.Id}>{service?.Name}</ServiceBadge>
                ),
              )}
            </ServicesContainer>
          )}
          <CompanyInfoContainer>
            <CompanyInfoContent>
              <CompanyInfoTitle>Attack cost savings</CompanyInfoTitle>
              <br />
              {getCompanyDetail.loading ? (
                renderSkeleton('100%')
              ) : (
                <CompanyInfoValue>20,0000$</CompanyInfoValue>
              )}
            </CompanyInfoContent>
            <CompanyInfoContent>
              <CompanyInfoTitle>Created on</CompanyInfoTitle>
              <br />
              {getCompanyDetail?.loading ? (
                renderSkeleton('100%')
              ) : (
                <CompanyInfoValue>12/01/2023, 14:34 UTC</CompanyInfoValue>
              )}
            </CompanyInfoContent>
            <CompanyInfoContent>
              <CompanyInfoTitle>Service until</CompanyInfoTitle>
              <br />
              {getCompanyDetail?.loading ? (
                renderSkeleton('100%')
              ) : (
                <CompanyInfoValue>24/02/2024, 4:39 UTC</CompanyInfoValue>
              )}
            </CompanyInfoContent>
          </CompanyInfoContainer>
        </TopSection>
      </Content>
      <CompanySectionTabs
        tabs={tabs}
        activeTab={activeTab}
        handleChangeDomainPage={handleChangeDomainPage}
        domainCurrentPage={domainCurrentPage}
        domainCount={domainCount}
        searchDomainName={searchDomainName}
        handleChangeDomainName={handleChangeDomainName}
        onTabChange={setActiveTab}
      />
      <AddChildModal
        handleClose={handleCloseAddChild}
        open={openAddChildModal}
      />
    </Container>
  );
};

export default CompanyDetailsV2;
