import { Box, styled } from '@mui/material';

export const PaginationStyle = styled(Box)({
  display: 'flex',
  justifyContent: 'right',
  margin: '12px 0',
});

export const CustomPaginationStyle = styled(PaginationStyle)(({ theme }) => ({
  '.MuiPaginationItem-root': {
    height: 20,
    '&.Mui-selected': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      position: 'relative',
      color: theme.palette.text.primary,
      border: 2,
      width: 14,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '5',
        width: 14,
        height: 2,
        borderRadius: 10,
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  '.MuiPaginationItem-previousNext': {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.border?.main}`,
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.background.default,
    width: 34,
    height: 34,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  '.MuiPaginationItem-page': {
    color: theme.palette.text.inputPlaceholder,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    width: 14,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '.MuiPaginationItem-ellipsis': {
    color: theme.palette.text.inputPlaceholder,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
  },
}));
