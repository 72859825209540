import * as React from 'react';
import { Theme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  StyledCheckIcon,
  StyledMenuItem,
  StyledOutlinedInput,
  StyledPlaceholder,
  StyledSelect,
  StyledSelected,
} from './Style/MultiSelectDropdown.style';
import { theme } from '../../styles/theme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      borderRadius: '8px',
      marginTop: '4px',
      boxShadow: '0px 20px 32px 15px #C1C1C129',
      color: theme.palette.text.tertiary,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      lineHeight: 16,
    },
  },
};

function getStyles(item: string, selectedItems: string[], theme: Theme) {
  return {
    fontWeight: selectedItems.includes(item)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

interface MultiSelectDropdownProps {
  items: string[];
  placeholder?: string;
  selectedItems: string[];
  onSelectionChange: (selectedItems: string[]) => void;
  multiple?: boolean;
  width?: string | number;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  items,
  placeholder,
  selectedItems,
  onSelectionChange,
  multiple = true,
  width = 300,
}) => {
  const handleChange = (event: SelectChangeEvent<typeof selectedItems>) => {
    const {
      target: { value },
    } = event;
    onSelectionChange(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl sx={{ width: width }}>
      <Select
        id="multiple-select"
        multiple={multiple}
        value={selectedItems}
        onChange={handleChange}
        input={<StyledOutlinedInput />}
        MenuProps={MenuProps}
        sx={StyledSelect}
        displayEmpty
        renderValue={selected => {
          if (selected?.length === 0) {
            return <StyledPlaceholder>{placeholder}</StyledPlaceholder>;
          }
          return <StyledSelected>{selected?.join(', ')}</StyledSelected>;
        }}>
        {items?.map(item => (
          <StyledMenuItem key={item} value={item}>
            {item}
            {selectedItems.includes(item) && (
              <StyledCheckIcon fontSize="small" />
            )}
          </StyledMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectDropdown;
