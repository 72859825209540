import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { useSelector } from 'react-redux';
import { BenignText, GSBLiveInfoTypography, HorizontalLine, MaliciousText, NoMaliciousText, StyledHeader, SummaryTable, SuspiciousText, UnknownText } from './Style/SingleThreat.style';
import { Button, Loader } from '../../components';
import GSBModel from './GSBModel';
import { SummaryDetailProps } from '../../context/SingleThreat/SingleThreatType';
import { useSingleThreatContext } from '../../context/SingleThreat/SingleThreatContext';
import { useGSBHistoryContext } from '../../context/GsbHistory/GsbHistoryContext';
import Title from '../../components/Title/Title';


const SummaryDetail = ({
  threatDetail,
  isGSBLiveDataLoading,
  gsbLiveInfo,
}: SummaryDetailProps) => {

  
  const { dnsData } = useSingleThreatContext();
  const { gsbHistoryData, gsbLoading, gsbSubmitLoading } =
    useGSBHistoryContext();
  const [openGsbThreatModal, setOpenGsbThreatModal] = useState<boolean>(false);

  const { takeDownDetails, getStatustakeDown } = useSelector(
    (state: any) => state.takedown,
  );
  const getTakeDownStatus = useCallback(() => {
    return findStatusNameById(takeDownDetails?.data?.items?.[0]?.status_id);
  }, [takeDownDetails]);

  const findStatusNameById = (id: number) => {
    const status = getStatustakeDown?.data?.find(
      (status: any) => status?.id === id,
    );
    return status ? status.name : 'NA';
  };

  const filteredRecordsA = dnsData?.items?.[0]?.data.find(
    (item: any) => item.record_type === 'A',
  );

  const getNetworkLabel = useMemo(() => {
    const score = threatDetail?.score?.value ?? 0;
    const labels = [
      { threshold: 0.8, component: <MaliciousText>Malicious</MaliciousText> },
      { threshold: 0.7, component: <SuspiciousText>Suspicious</SuspiciousText> },
      { threshold: 0.5, component: <UnknownText>Unknown</UnknownText> },
      { threshold: 0, component: <BenignText>Benign</BenignText> }
    ];
  
    return labels.find(({ threshold }) => score >= threshold)?.component;
  }, [threatDetail?.score?.value]);

  const handleGsbThreatModal = (url: boolean) => {
    setOpenGsbThreatModal(true);
  };

  const renderGSBLiveData = () => {
    const gsbLiveData = gsbLiveInfo?.threat?.threatTypes || [];
    const isMalicious = gsbLiveData.length > 0;
    const threatTypes = gsbLiveData.join(', ');
  
    const gsbStatus = isMalicious ? (
      <MaliciousText>Malicious</MaliciousText>
    ) : (
      <NoMaliciousText>No Classification</NoMaliciousText>
    );
  
    return (
      <>
        <GSBLiveInfoTypography variant="subtitle1">
          Google Safe Browsing:{' '}
          {isGSBLiveDataLoading ? (
            <Skeleton variant="text" width="100px" />
          ) : (
            gsbStatus
          )}
        </GSBLiveInfoTypography>
  
        {/* Show threat type if the status is malicious */}
        {!isGSBLiveDataLoading && isMalicious && (
          <GSBLiveInfoTypography variant="subtitle1" sx={{ display: 'flex', gap: 1 }}>
            Google Safe Browsing Threat Type: <span>{threatTypes}</span>
          </GSBLiveInfoTypography>
        )}
      </>
    );
  };
  
  return (
    <>
      <StyledHeader>
      <Title size={'large'} title="Summary" />
      </StyledHeader>
      <HorizontalLine />
      <SummaryTable>
        {threatDetail?.identification_status?.value === 'confirmed' && (
          <Typography variant="subtitle1">
            Takedown:&nbsp;
            {getTakeDownStatus()}
          </Typography>
        )}
        <Typography variant="subtitle1">
          PreCrime Network:&nbsp;
          {threatDetail?.identification_status?.value === 'confirmed' ? (
            getNetworkLabel
          ) : (
            <NoMaliciousText>No Classification</NoMaliciousText>
          )}
        </Typography>
        <hr style={{ margin: '12px 0' }} />
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Live Information</Typography>
            <Button
              onClick={() => handleGsbThreatModal(false)}
              variant="contained"
              startIcon={<GoogleIcon />}
              disabled={gsbSubmitLoading  || gsbLoading || gsbHistoryData?.items?.length > 0}>
              {gsbSubmitLoading ? <Loader size={24} /> : 'Submit to GSB'}
            </Button>
          </Box>
          {renderGSBLiveData()}
          <Typography variant="subtitle1" sx={{ lineHeight: 2 }}>
            Current DNS A Record: {filteredRecordsA?.address ?? 'NA'}
          </Typography>
        </Box>

        <GSBModel
          threatName={threatDetail?.threat_name}
          open={openGsbThreatModal}
          handleClose={() => {
            setOpenGsbThreatModal(false);
          }}
        />
      </SummaryTable>
    </>
  );
};

export default SummaryDetail;
