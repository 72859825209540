import { styled } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

export const FallbackContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '16px', 
  boxShadow: '0px 21px 34px 16px rgba(193, 193, 193, 0.16)',
  padding: '15px', 
  textAlign: 'center',
  maxWidth: '450px',
  margin: '200px auto',
}));

export const ErrorIcon = styled(WarningAmberOutlinedIcon)(({ theme }) => ({
  marginRight: '2px',
  color: theme.palette.warning.main,
  borderRadius:'16px',
  height: '18px'
}))

export const Title = styled('p')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: '14px',
  alignItems: 'center',
  color: theme.palette.warning.main,
  display: 'flex',
  margin: 0,
}));

export const Description = styled('p')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: '14px',
  color: 'black',
  margin: '0',
}));

export const ErrorLink = styled('a')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  display: 'inline-block',
  marginTop: '10px',
  fontSize: '14px',
  color: theme.palette.primary.dark,
  textDecoration: 'underline',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: theme.palette.text.primary,
  },
}));

