import { styled } from '@mui/system';
import { TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DatePicker } from 'rsuite';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    cursor: 'pointer',
    height: 34,
    padding: '9px 10px 9px 14px',
    '& fieldset': { borderColor: theme.palette.border?.main },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    ' &.Mui-focused fieldset': {
      borderWidth: 2
    }
  },
  '& .MuiInputBase-input': {
    fontSize: 12,
    fontWeight: 700,
    textAlign: 'center',
  },

  '& .MuiOutlinedInput-input': {
    padding: 0,
    margin: 'auto',
  },
  '& .MuiInputAdornment-root': {
    marginLeft: 0,
  },
  '& input::placeholder': {
    color: theme.palette.text.primary,
    opacity: 1,
  },
}));

export const StyledDatePicker = styled(DatePicker)({
  visibility: 'hidden',
  position: 'absolute',
});

export const StyledArrow = styled(ArrowDropDownIcon)(({ theme }) => ({
  color: theme.palette.primary.dark,
  cursor: 'pointer',
  width: 24,
  height: 24,
}));
