import React from 'react';
import { Box } from '@mui/material';
import {
  Container,
  Content,
  ContentWrapper,
  DatePickerWrapper,
  HorizontalLine,
  InfoWrapper,
  StyledNormal,
  StyledStrong,
} from './Style/CompanyCreationV2.style';
import Title from '../../components/Title/Title';
import { ServicesProps } from '../../helpers/types';
import DatePickers from '../../components/SingleDatePicker/SingleDatePicker';
import { disableBforeToday } from '../../utils';
import MultiSelectDropdown from '../../components/MultiselectDropDown/MultiSelectDropdown';

const Services: React.FC<ServicesProps> = ({
  selectedServices,
  handleServicesChange,
  serviceData,
  selectedDate,
  handleDateChange,
}) => {
  const items = serviceData.map((service: { label: string }) => service.label);
  return (
    <Container>
      <Content>
        <ContentWrapper>
          <Title size={'large'} title="Services" />
        </ContentWrapper>
        <HorizontalLine />
        <InfoWrapper>
          <StyledNormal>
            Add services by selecting them from the list.
          </StyledNormal>
          <MultiSelectDropdown
            items={items}
            placeholder="Select Industries"
            selectedItems={selectedServices}
            onSelectionChange={handleServicesChange}
            width={325}
          />
          <StyledStrong size="small">Service Until</StyledStrong>
          <DatePickerWrapper>
            <DatePickers
              selectedDate={selectedDate}
              onDateChange={handleDateChange}
              disableDate={disableBforeToday}
            />
          </DatePickerWrapper>
          <StyledNormal>
            This field is for informational purposes only and does not impact
            the PoV or the provisioning of services.
          </StyledNormal>
        </InfoWrapper>
      </Content>
    </Container>
  );
};

export default Services;
