/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Avatar, Typography, Box, Grid, IconButton } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Button,
  ConfirmationModal,
  ImageSkeleton,
  Loader,
} from '../../components';
import { useSingleThreatContext } from '../../context/SingleThreat/SingleThreatContext';
import {
  SingleThreatLoaderWrapper,
  StatusButtonWrapper,
  ImageStyle,
  ScreenshotWrapper,
  SyncIconStyle,
  MainContainer,
  ThreatNameStyled,
  ThreatCard,
  HorizontalLine,
} from './Style/SingleThreat.style';
import Avtar from '../../assets/Images/Avtar.png';
import { changeStatusLabelAction } from '../../store/threats/action';
import ScreenShotModal from '../../components/ScreenShotModal/ScreenShotModal';
import StatusHistory from './StatusHistory';
import SummaryDetail from './SummaryDetail';
import DomainInformation from './DomainInformation';
import ExternalToolList from './ExternalToolList';
import TakedownDetails from './TakedownDetails';
import NotesComments from './NotesComments';
import { PAGE_NAME } from '../../utils/analytic';
import { setAnalyticObject } from '../../store/analytic/action';
import { getNotesValuesAction } from '../../store/noteComments/action';
import GSBStatusDetails from '../GSBHistory/GSBStatus';
import RefreshButton from '../../components/RefreshButton/RefreshButton';
import Title from '../../components/Title/Title';

const SingleThreatDetail = () => {
  const [statusLabelThreat, setStatusLabelThreat] = useState('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openScreenShotModal, setOpenScreenShotModal] =
    useState<boolean>(false);
  const [singleThreatStatus, setSingleThreatStatus] = useState<boolean>(true);
  const [copied, setCopied] = useState<boolean>(false);
  const [companyDetail, setCompanyDetail] = useState<any>();

  const user = JSON.parse(window.localStorage.getItem('authUserSub') || '{}');

  const { userData } = useSelector((state: any) => state.user);

  const dispatch = useDispatch();
  const { getCompany } = useSelector((state: any) => state.company);

  const [searchParams] = useSearchParams();
  const { threatName, domainName, companyId } = useParams();
  const protectedAssetId = searchParams.get('protected_id');
  const threatId = searchParams.get('threat_id');
  const {
    singleThreatDetails,
    loading,
    fetchSingleThreatList,
    fetchThreatScreenshot,
    imageData,
    imageLoading,
    updateThreatStatus,
    fetchGSBLiveInfo,
    gsbLiveInfo,
    isGSBLiveDataLoading
  } = useSingleThreatContext();

  useEffect(() => {
    if (threatName && domainName && companyId && protectedAssetId && threatId) {
      const params = {
        protected_id: protectedAssetId,
        threat_id: threatId,
      };
      fetchSingleThreatList(params);
    }
  }, [threatName, domainName, companyId, protectedAssetId, threatId, dispatch]);

  useEffect(() => {
    const companies = getCompany?.data?.find(
      (c: any) => c?.Id === singleThreatDetails?.company_id,
    );
    setCompanyDetail(companies);

    // Update page
    if (singleThreatDetails?.threat_name) {
      document.title = `${singleThreatDetails.threat_name} - Threat Details`;
    }

    // Cleanup - restore original title when component unmounts
    return () => {
      document.title = 'Single threat';
    };
  }, [singleThreatDetails]);

  useEffect(() => {
    if (singleThreatDetails?.threat_name) {
      dispatch(getNotesValuesAction());
    }
  }, [singleThreatDetails?.threat_name]);

  useEffect(() => {
    if (singleThreatDetails?.threat_name) {
      fetchThreatScreenshot(singleThreatDetails?.threat_name);
      fetchGSBLiveInfo(singleThreatDetails?.threat_name)
    }
  }, [singleThreatDetails?.threat_name]);

  const handleSyncIconClick = async () => {
    if (singleThreatDetails?.threat_name) {
      fetchThreatScreenshot(singleThreatDetails?.threat_name);
    }
  };

  const handleOpenSingleModal = (value: string) => {
    setStatusLabelThreat(value);
    setOpenModal(true);
  };

  const updateStatusDetailScreen = () => {
    if (singleThreatDetails) {
      setOpenModal(false);
      const analytic = {
        pageName: PAGE_NAME.SINGLE_THREAT_PAGE,
        user: { email: user?.email, name: user?.name },
        isBulkUpdate: false,
      };
      dispatch(setAnalyticObject(analytic));
      const data = {
        threat_name: singleThreatDetails?.threat_name,
        status: statusLabelThreat,
        user_id: userData?.data?.Id,
        company_id: companyId,
        threat_id: singleThreatDetails?.threat_id,
        protected_asset_id: singleThreatDetails?.protected_asset_id,
        protected_asset_name: singleThreatDetails?.protected_asset_name,
        process: 'w',
        priority: 1,
        singleThreatStatus,
        currentStatus: singleThreatDetails?.identification_status?.value,
      };
      updateThreatStatus(data);
    }
  };

  const handleCopyThreatName = () => {
    try {
      navigator.clipboard.writeText(singleThreatDetails?.threat_name || '');
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Clipboard write failed", error);
    }
  };

  const handleCloseScreenShot = () => {
    setOpenScreenShotModal(false);
  };

  if (loading) {
    return (
      <SingleThreatLoaderWrapper>
        <Loader size={30} />
      </SingleThreatLoaderWrapper>
    );
  }

  const detectionDate = new Date(singleThreatDetails?.detection_date || '');
  const options: any = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = detectionDate.toLocaleString('en-US', options);

  return (
    <MainContainer container spacing={2}>
      <Grid item xs={6}>
        {/* Top header */}
        <ThreatNameStyled>
          <Box sx={{ display: 'flex', gap: '18px' }}>
            <Typography variant="h5" fontWeight="bold">
              {singleThreatDetails?.threat_name}
            </Typography>
            <IconButton
              data-testid="copy-threat-name-button"
              onClick={handleCopyThreatName}
              size="small"
              sx={{ padding: '0' }}
              disabled={copied}>
              <ContentCopyIcon />
            </IconButton>
          </Box>

          <Typography variant="h6" sx={{ lineHeight: 1.1 }}>
            {singleThreatDetails?.protected_asset_name} - {companyDetail?.Name}
          </Typography>

          <Typography variant="subtitle1" sx={{ lineHeight: 2 }}>
            <b>Detection:</b> {`On ${formattedDate}`} <br />
          </Typography>
          <StatusButtonWrapper>
            <Button
              data-testid="confirm-threat-button"
              variant="contained"
              onClick={() => {
                handleOpenSingleModal('confirmed');
              }}
              startIcon={<CheckCircleIcon />}
              disabled={
                singleThreatDetails?.identification_status?.value === 'confirmed'
              }>
              Confirm
            </Button>
            <Button
              data-testid="discard-threat-button"
              variant="contained"
              onClick={() => {
                handleOpenSingleModal('discarded');
              }}
              startIcon={<CancelIcon />}
              disabled={
                singleThreatDetails?.identification_status?.value === 'discarded'
              }>
              Discard
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleOpenSingleModal('trademark');
              }}
              startIcon={<OfflinePinIcon />}
              disabled={
                singleThreatDetails?.identification_status?.value === 'trademark'
              }>
              Trademark
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleOpenSingleModal('owned');
              }}
              startIcon={<PlaylistAddCircleIcon />}
              disabled={
                singleThreatDetails?.identification_status?.value === 'owned'
              }>
              Owned
            </Button>
            <Button
              data-testid="pre-confirmed-threat-button"
              variant="contained"
              onClick={() => {
                handleOpenSingleModal('pre-confirmed');
              }}
              startIcon={<AssignmentTurnedInIcon />}
              disabled={
                singleThreatDetails?.identification_status?.value === 'pre-confirmed'
              }>
              Pre-Confirmed
            </Button>
          </StatusButtonWrapper>
        </ThreatNameStyled>
        {/* Summary */}
        <ThreatCard>
          <SummaryDetail
            threatDetail={singleThreatDetails}
            gsbLiveInfo={gsbLiveInfo}
            isGSBLiveDataLoading={isGSBLiveDataLoading}
          />
        </ThreatCard>
        {/* GSB */}
        <ThreatCard>
          <GSBStatusDetails threatDetail={singleThreatDetails} />
        </ThreatCard>
        {/* Takedowns */}
        {singleThreatDetails?.identification_status?.value === 'confirmed' && (
          <ThreatCard>
            <TakedownDetails singleThreat={singleThreatDetails} />
          </ThreatCard>
        )}
        {/* Domain info */}
        <ThreatCard>
          <DomainInformation
            singleThreat={singleThreatDetails}
          />
        </ThreatCard>
      </Grid>

      <Grid item xs={6}>
        <ExternalToolList
          singleThreat={singleThreatDetails}
          imageData={imageData}
          threatName={singleThreatDetails?.threat_name ?? ''}
        />
        <ScreenshotWrapper>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: '8px',
            }}>
            <Title size={'large'} title="Screenshot" />
            <RefreshButton
              handleSyncIconClick={handleSyncIconClick}
              isDisabled={imageLoading}
              SyncIconStyle={SyncIconStyle}
            />
          </Box>
          <HorizontalLine sx={{marginBottom: 1}} />
          {imageLoading ? (
            <ImageSkeleton height={300} />
          ) : (
            <Avatar
              variant={'square'}
              src={
                imageData === undefined
                  ? Avtar
                  : `data:image/jpeg;base64,${imageData}`
              }
              onClick={() => setOpenScreenShotModal(true)}
              sx={ImageStyle}
            />
          )}
        </ScreenshotWrapper>
        <StatusHistory singleThreat={singleThreatDetails} />
        <NotesComments singleThreat={singleThreatDetails} />
      </Grid>
      <ScreenShotModal
        imageData={imageData}
        handleCloseScreenShot={handleCloseScreenShot}
        openScreenShotModal={openScreenShotModal}
      />
      <ConfirmationModal
        title="Confirm status"
        subTitle="Do you want to change the status?"
        confirmLabel="Update"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleUpdate={() => updateStatusDetailScreen()}
      />
    </MainContainer>
  );
};

export default SingleThreatDetail;
