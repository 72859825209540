import { Box, Stack, styled, Card, IconButton} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

const lateralPaddingCard = '12px';

export const StyledCard = styled(Card)({
  boxShadow: '0px 18px 32px 16px rgba(193, 193, 193, 0.16)',
  borderRadius: '16px',
  position: 'relative',
  '&:hover': {
    backgroundColor: '#F9FAFB',
  },
});

export const LoaderWrapper = {
  border: '1px solid #e2e2e2',
  borderRadius: '10px',
  padding: '14px',
  pointerEvents: 'none',
  opacity: 0.4,
};

export const CardTitle = {
  display: 'flex',
  alignItems: 'center',
  '& .MuiFormControlLabel-label': { 
    fontWeight: 600, 
    padding: '0 4px',
  },
  '& .MuiCheckbox-root': { 
    paddingY: 0,
    marginRight: 0 
  },
};

export const TableTitle = {
  '& .MuiFormControlLabel-label': { padding: '0 4px' },
  '& .MuiCheckbox-root': { paddingY: 0 },
};

export const StatusWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledCompanyData = styled(Box)(({ theme }) =>({
  marginLeft: lateralPaddingCard,
  marginTop: '8px',
  backgroundColor: "#FFFFFF", 
  width: 'fit-content',
  borderRadius: '4px',
  fontFamily: theme.typography.fontFamily,
  fontSize: '12px',
  padding: '2px 4px'
}));

export const CardActions = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '8px',
  padding: '0 12px'
});

export const StyledDiscardConfirmWrapper = styled(Box)({
  display: 'flex',
  flex: 1,
  gap: '8px',
  marginRight: '12px',
  '& .MuiButton-root': {
    flex: 1
  }
});

export const StyledTrademarkOwnedWrapper = styled(Box)({
  display: 'flex',
  gap: '8px',
  width: 'fit-content'
});

export const SyncIconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '8px',
});

export const LinkStyle = {
  textDecoration: 'none',
};

export const DomainInformation = styled('p')(({ theme }) => ({
  display: 'flex',
  fontSize: '13px',
  color: '#000000',
  width: '100%',
  gap: '5px',
  marginTop: '0',
  '& span': {
    color: theme.palette.grey[600]
  }
}));

export const CtiScore = styled('span')<{ score?: number | null }>(({ theme, score }) => ({
  fontSize: '12px',
  fontFamily: theme.typography.fontFamily,
  color: '#FFFFFF',
  background: score === null || score === undefined
    ? theme.palette.grey[500] // grey for no value
    : score >= 0.8
    ? '#FF0000' // red for >= 0.7
    : score > 0.4
    ? '#FFA500' // orange for > 0.4
    : theme.palette.success.main, // green for <= 0.4
  minWidth: '18px',
  height: '18px',
  display: 'block',
  lineHeight: '18px',
  textAlign: 'center',
  borderRadius: "50%",
  padding: '4px'
}));

export const Span = styled('span')({
  fontStyle: 'italic',
  color: '#aaa',
});

export const StackContainer = styled(Stack)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '8px 12px'
}));


export const ThreatNameWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 12px',
});

export const ThreatNameContent = styled(Box)({
  flex: 1,
  minWidth: 0,
  marginRight: '8px'
});

export const StyledCopyButton = styled(IconButton)({
  padding: '0'
});


export const ImageBackground = styled(Box)<{ screenshot?: string }>(({ screenshot }) => ({
  backgroundImage: `url(${screenshot})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

export const ImageBottomContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '8px 12px'
});

export const ScoreStatusWrapper = styled(Box)({
  display: 'flex',
  gap: '8px'
});

export const ErrorMessageBox = styled(Box)<{ isFullError?: boolean }>(({ isFullError }) => ({
  backgroundColor: 'rgba(255, 0, 0, 0.1)',
  color: '#ff0000',
  padding: '8px',
  marginBottom: '8px',
  borderRadius: '4px',
  fontSize: '14px',
  width: '100%',
  transition: 'all 0.3s ease',
  ...(!isFullError && {
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.2)',
      color: '#cc0000',
      textDecoration: 'underline'
    }
  })
}));
