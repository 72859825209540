import { styled } from '@mui/material/styles';
import { OutlinedInput, MenuItem } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { theme } from '../../../styles/theme';

export const StyledSelect = {
  height: 34,
  fontSize: 12,
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.border?.main,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.dark,
    right: 12
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 16px'
  }
};

export const StyledOutlinedInput = styled(OutlinedInput)({
  borderRadius: 8,
});

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between', 
  alignItems: 'center', 
  gap: 20,
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  fontSize: 12,
  borderRadius: 8,
  color: '#676D7C',
  textWrap: 'inherit',
  width: '100%',
  '&:hover': {
    borderRadius: 0,
    backgroundColor: "rgba(24, 205, 205, 0.08)",
  },
  '&.Mui-selected': {
    fontWeight: 500,
    borderRadius: 0,
    backgroundColor: 'transparent !important',
  },
  "&.Mui-selected:hover": {
    backgroundColor: "rgba(24, 205, 205, 0.08) !important",
  },
}));

export const StyledPlaceholder = styled('span')(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontWeight: 700,
  borderRadius: 0,
}));

export const StyledSelected = styled('span')(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  fontFamily: theme.typography.fontFamily,
}));

export const StyledCheckIcon = styled(CheckCircleOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: 18,
  alignSelf: 'flex-end',
  height: 17.5,
}));
