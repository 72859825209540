import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, GradientButton, Input } from '../../components';
import { InformationButtonWrapper, LogoStyle } from './Tabs/Style/Tabs.style';
import Avtar from '../../assets/Images/NoCompanyLogo.png';
import {
  updateCompanyDetailsAction,
  updateCompanyIndustryAction,
} from '../../store/company/action';

const CompanyDetailsForm = ({
  formValues,
  handleChangeInput,
  handleAutocompleteChange,
}: any) => {
  const [errors, setErrors] = useState<any>({ companyName: '' });

  const { getCompany, getCompanyDetail } = useSelector(
    (state: any) => state.company,
  );
  const dispatch = useDispatch();
  const { id } = useParams();

  const validateForm = (): boolean => {
    const requiredFields: string[] = ['companyName'];
    const errors: Record<string, string> = requiredFields.reduce(
      (acc, field) => {
        if (!formValues[field]) {
          acc[field] = `${field.replace(/([A-Z])/g, ' $1')} is required *`;
        }
        return acc;
      },
      {} as Record<string, string>,
    );

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCompanyUpdate = () => {
    if (validateForm()) {
      const currentHubSpotId = getCompanyDetail?.data?.Properties?.hubSpotId;

      const hasHubSpotIdChanged = formValues.hubSpotId !== currentHubSpotId;
      if (hasHubSpotIdChanged) {
        dispatch(
          updateCompanyIndustryAction({
            company: id,
            key: 'hubSpotId',
            value: formValues.hubSpotId,
          }),
        );
      } 
      dispatch(
        updateCompanyDetailsAction({
          id: id,
          logo: formValues.logo,
          name: formValues.companyName,
          vat: formValues.vat,
          address: formValues.address,
          zip: formValues.postCode,
          phone: formValues.contactPhone,
          email: formValues.email,
          parent:formValues.parent,
          nbEmployee:formValues.nbEmployee,
        }),
      );
    }
  };

  const handleCancel = () => {
    setErrors({ companyName: '' });
  };

  return (
    <div>
      <DialogContent dividers>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ minWidth: '30%' }} variant="body2">
            Logo URL
          </Typography>
          <Input
            data-testid="company-logo-url-input"
            size="small"
            name="logo"
            value={formValues.logo}
            onChange={handleChangeInput}
            placeholder="logo url"
            sx={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ minWidth: '30%' }} variant="body2">
            Logo Display
          </Typography>
          <img
            src={formValues.logo ? formValues.logo : Avtar}
            alt="logo"
            style={LogoStyle}
          />
          <Typography variant="body2" sx={{ ml: 2, color: 'gray' }}>
            Allowed file types: png, jpg, jpeg.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 2,
          }}>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ minWidth: '30%' }} variant="body2">
              Company Name *
            </Typography>
            <Box sx={{ width: '100%' }}>
              <Input
                data-testid="company-name-input"
                size="small"
                name="companyName"
                value={formValues.companyName}
                onChange={handleChangeInput}
                placeholder="Company Name"
                sx={{ width: '100%' }}
              />
              {errors.companyName && (
                <Typography color="error" sx={{ fontSize: '0.75rem', mt: 0.5 }}>
                  {errors.companyName}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ minWidth: '30%' }} variant="body2">
            VAT
          </Typography>
          <Input
            size="small"
            placeholder="Vat"
            sx={{ width: '100%' }}
            name="vat"
            value={formValues.vat}
            onChange={handleChangeInput}
          />
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ minWidth: '30%' }} variant="body2">
            HubSpot ID
          </Typography>
          <Input
            size="small"
            name="hubSpotId"
            value={formValues.hubSpotId}
            onChange={handleChangeInput}
            placeholder="hubSpotId"
            sx={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ minWidth: '30%' }} variant="body2">
            Address
          </Typography>
          <Input
            size="small"
            name="address"
            value={formValues.address}
            onChange={handleChangeInput}
            placeholder="address"
            sx={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ minWidth: '30%' }} variant="body2">
            Post Code
          </Typography>
          <Input
            size="small"
            name="postCode"
            value={formValues.postCode}
            onChange={handleChangeInput}
            placeholder="Zip"
            sx={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ minWidth: '30%' }} variant="body2">
            Contact Phone
          </Typography>
          <Input
            size="small"
            name="contactPhone"
            value={formValues.contactPhone}
            onChange={handleChangeInput}
            placeholder="Contact Phone"
            sx={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ minWidth: '30%' }} variant="body2">
            Email
          </Typography>
          <Input
            size="small"
            name="email"
            value={formValues.email}
            onChange={handleChangeInput}
            placeholder="Email"
            sx={{ width: '100%' }}
          />
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ minWidth: '30%' }} variant="body2">
            Parent
          </Typography>
          <Autocomplete
            options={getCompany?.data ?? []}
            getOptionLabel={(option: any) => option.Name || ''}
            value={
              getCompany?.data?.find(
                (item: any) => item?.Name === formValues.parent?.Name,
              ) || null
            }
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, 'parent')
            }
            sx={{ width: '100%' }}
            renderInput={params => (
              <TextField {...params} label="Parent" size="small" />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.id || option.label}>
                {option.Name}
              </Box>
            )}
          />
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography sx={{ minWidth: '30%' }} variant="body2">
            Nb employees
          </Typography>
          <Input
            size="small"
            name="nbEmployee"
            value={formValues.nbEmployee}
            onChange={handleChangeInput}
            placeholder="Number of employees"
            sx={{ width: '100%' }}
          />
        </Box>
      </DialogContent>
      <InformationButtonWrapper>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <GradientButton
          data-testid="save-company-changes-button"
          variant="contained"
          onClick={handleCompanyUpdate}>
          Save Changes
        </GradientButton>
      </InformationButtonWrapper>
    </div>
  );
};

export default CompanyDetailsForm;
