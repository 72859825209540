import React, { useEffect, useState } from 'react';
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Loader } from 'rsuite';
import CloseIcon from '@mui/icons-material/Close';
import { Button, GradientButton } from '..';
import {
  AddDomainDialog,
  CloseIconStyle,
} from '../../pages/CompanyDetails/Tabs/Style/Tabs.style';
import { ButtonWrapper } from '../../pages/CompaniesList/Style/CompaniesList.style';
import MultiDomainInput from '../MultiDomainInput/MultiDomainInput';

const LoadDomainModal = ({
  title,
  handleClose,
  open,
  isLoading,
  handleSubmit,
}: any) => {
  const [domainList, setDomainList] = useState<string[]>([]);

  const handleChangeDomains = (newDomains: string[]) => {
    setDomainList(newDomains);
  };

  const handleCloseLoadInput = () => {
    setDomainList([]);
    handleClose();
  };

  useEffect(() => {
    setDomainList([]);
  }, [open]);

  return (
    <div>
      <AddDomainDialog onClose={handleCloseLoadInput} open={open}>
        <DialogTitle sx={{ m: 0, px: 3, fontWeight: 600 }}>{title}</DialogTitle>
        <IconButton
          disabled={isLoading}
          onClick={handleCloseLoadInput}
          sx={CloseIconStyle}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
            Domains :
          </Typography>
          <div style={{ width: '100%' }}>
            <MultiDomainInput
              value={domainList}
              onChange={handleChangeDomains}
              placeholder="Enter domains, separated by commas or spaces"
            />
          </div>
        </DialogContent>
        <ButtonWrapper sx={{ mt: 2 }}>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={handleCloseLoadInput}
            color="secondary">
            Cancel
          </Button>
          <GradientButton
            variant="contained"
            disabled={isLoading || domainList?.length === 0}
            onClick={() => {
              handleSubmit(domainList);
            }}>
            {isLoading ? <Loader /> : 'Submit'}
          </GradientButton>
        </ButtonWrapper>
      </AddDomainDialog>
    </div>
  );
};

export default LoadDomainModal;
