import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Tooltip,
} from '@mui/material';
import {
  formatedDate,
  getFormattedDescription,
  GSBHistoryHeader,
  LoaderWrapper,
  NoDataMessage,
} from '../../utils';
import { getStatusTakedown } from '../../store/takedown/action';
import { CustomTableCell } from './Style/GSBHistory.style';
import { GSBHistoryItemType } from '../../context/GsbHistory/GsbHistoryType';
import { useGSBHistoryContext } from '../../context/GsbHistory/GsbHistoryContext';

const GSBHistoryTable = () => {
  const dispatch = useDispatch();

  const { gsbHistoryData, gsbLoading } = useGSBHistoryContext();

  useEffect(() => {
    dispatch(getStatusTakedown());
  }, [dispatch]);

  return (
    <TableContainer
      data-testid="gsb-history-table"
      component={Paper}
      sx={{ border: '1px solid lightgray' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {GSBHistoryHeader.map(header => (
              <CustomTableCell key={header} align="left">
                {header}
              </CustomTableCell>
            ))}
          </TableRow>
        </TableHead>

        {gsbLoading ? (
          <LoaderWrapper size={25} />
        ) : gsbHistoryData?.items?.length === 0 ? (
          <NoDataMessage message="No records found" />
        ) : (
          <TableBody>
            {gsbHistoryData?.items?.map((gsbItem: GSBHistoryItemType) => (
              <TableRow key={gsbItem.id}>
                <TableCell align="left">{gsbItem?.id}</TableCell>
                <TableCell align="left">{gsbItem?.domain_id}</TableCell>
                <TableCell align="left">{gsbItem?.url}</TableCell>
                <TableCell align="left">{gsbItem?.platform}</TableCell>
                <TableCell align="left">{gsbItem?.region_codes}</TableCell>
                <TableCell align="left">
                  {gsbItem?.labels?.join(', ') || '-'}
                </TableCell>
                <TableCell align="left">
                  <Tooltip title={gsbItem?.comments || '-'}>
                    <span>{getFormattedDescription(gsbItem?.comments)}</span>
                  </Tooltip>
                </TableCell>
                <TableCell align="left">{gsbItem?.abuse_type}</TableCell>
                <TableCell align="left">
                  {formatedDate(gsbItem?.created_at) || '-'}
                </TableCell>
                <TableCell align="left">{gsbItem?.operation_id}</TableCell>
                <TableCell align="left">{gsbItem?.status || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default GSBHistoryTable;
