import { Box, Chip, styled, TextField } from '@mui/material';

export const StyledTextField = styled(TextField)`
  height: 40px;
  border-radius: 4px;
  background-color: white;
  border-color: #ccc;
  .MuiInputBase-root {
    padding: 8px;
  }
`;

export const ChipsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;


export const StyledChip = styled(Chip)`
  font-size: 0.7rem;
  height: 25px;
`;
