import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { Domain, CompanyDetailsTabsProps } from "../../helpers/types";
import ViewDomainDetails from "../CompanyDetails/Tabs/ViewDomainDetails";
import ProtectedDomainsTab from "../CompanyDetails/Tabs/ProtectedDomainsTab";
import ActivityLogsTab from "../CompanyDetails/Tabs/ActivityLogsTab";
import ChildCompaniesTab from "../CompanyDetails/Tabs/ChildCompaniesTab";
import UsersTab from "../CompanyDetails/Tabs/UsersTab";
import KeywordsTab from "../CompanyDetails/Tabs/KeywordsTab";
import { CustomTabPanel, Tab, TabsContainer } from './style/CompanyDetailsV2.style';
import InformationTabV2 from './InformationTabV2';

const PaperStyle = {
  backgroundColor: 'white',
  height: 'auto',
  p: 2.5,
};

const CompanyDetailsTabs: React.FC<CompanyDetailsTabsProps> = ({
  tabs,
  onTabChange,
  activeTab,
  handleChangeDomainPage,
  domainCurrentPage,
  domainCount,
  handleChangeDomainName,
  searchDomainName,
}) => {
  const [selectedDomain, setSelectedDomain] = useState<Domain | null>(null);
  const { getCompanyDetail } = useSelector((state: any) => state.company);

  useEffect(() => {
    if (selectedDomain) {
      const updatedDomain = getCompanyDetail?.data?.Domains?.find(
        (item: {Id: number}) => item?.Id === selectedDomain?.Id
      );

      if (updatedDomain && updatedDomain?.Status !== selectedDomain?.Status) {
        setSelectedDomain((prev) => ({
          ...prev!,
          Status: updatedDomain?.Status,
        }));
      }
    }
  }, [getCompanyDetail, selectedDomain]);

  const handleDomainClick = (item: Domain) => {
    setSelectedDomain(item);
  };

  return (
    <div>
      <TabsContainer>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            isActive={activeTab === index}
            onClick={() => onTabChange(index)}
            data-test-id={tab?.testId} 
          >
            {tab?.name}
          </Tab>
        ))}
      </TabsContainer>

      <CustomTabPanel value={activeTab} index={0}>
        <InformationTabV2/>
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
        <Paper elevation={1} sx={PaperStyle}>
          {selectedDomain ? (
            <ViewDomainDetails selectedDomain={selectedDomain} onBack={() => setSelectedDomain(null)} />
          ) : (
            <ProtectedDomainsTab
              searchDomainName={searchDomainName}
              handleChangeDomainName={handleChangeDomainName}
              domainCurrentPage={domainCurrentPage}
              onDomainClick={handleDomainClick}
              domainCount={domainCount}
              handleChangeDomainPage={handleChangeDomainPage}
            />
          )}
        </Paper>
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={2}>
        <KeywordsTab />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={3}>
        <UsersTab />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={4}>
        <ActivityLogsTab />
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={5}>
        <ChildCompaniesTab />
      </CustomTabPanel>
    </div>
  );
};

export default CompanyDetailsTabs;
