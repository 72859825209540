import React from 'react';
import { Pagination, PaginationProps } from '@mui/material';
import { CustomPaginationStyle } from './Style/Pagination.style';

const CustomPagination = ({ count, page, onChange }: PaginationProps) => {
  return (
    <CustomPaginationStyle>
      <Pagination
        data-testid={'table-pagination'}
        count={count}
        page={page}
        onChange={onChange}
      />
    </CustomPaginationStyle>
  );
};

export default CustomPagination;
