import React, { useState } from 'react';
import { Box } from '@mui/material';
import {
  StyledAutocomplete,
  StyledEllipsisBox,
  StyledListItem,
  StyledTagBox,
  StyledTextField,
} from './Style/SearchableDropDownV2.style';
import { theme } from '../../styles/theme';
import {
  OptionType,
  SearchableDropDownPropsWithOption,
} from '../../helpers/types';

const SearchableDropDownV2 = ({
  items,
  size,
  placeholder,
  label,
  onChange,
  selectedValues,
  multiple = false,
  dataTestid,
  clearIcon = false,
  minWidth = 150,
  maxWidth = 400,
}: SearchableDropDownPropsWithOption & {
  minWidth?: number | string;
  maxWidth?: number | string;
}) => {
  const [open, setOpen] = useState(false);

  const renderCommaSeparatedValues = (values: OptionType[]) => {
    return values.map((option: OptionType) => option.label).join(', ');
  };

  return (
    <Box sx={{ display: 'inline-block', minWidth, maxWidth, width: '100%' }}>
      <StyledAutocomplete
        sx={{
          width: '100%',
          minWidth,
          maxWidth,
        }}
        multiple={multiple}
        data-testid={dataTestid}
        options={items}
        getOptionLabel={option => option?.label}
        disableCloseOnSelect
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={onChange}
        value={selectedValues}
        isOptionEqualToValue={(option: OptionType, value) =>
          option?.label === value?.label
        }
        freeSolo={false}
        renderOption={(props, option: OptionType) => {
          const isDisabled = option?.disabled;
          return (
            <StyledListItem
              {...props}
              key={`${option?.id}${option?.label}`}
              style={{
                pointerEvents: isDisabled ? 'none' : 'auto',
                color: isDisabled ? 'gray' : theme.palette.text.primary,
                minWidth,
                maxWidth,
              }}
              aria-disabled={isDisabled}
              disableGutters>
              {option?.label}
            </StyledListItem>
          );
        }}
        renderInput={params => (
          <StyledTextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={placeholder}
            size={size}
            onClick={() => setOpen(true)}
          />
        )}
        renderTags={value => {
          if (multiple) {
            return (
              <StyledTagBox>{renderCommaSeparatedValues(value)}</StyledTagBox>
            );
          } else {
            if (value.length <= 1) {
              return value?.map((option: OptionType) => (
                <StyledTagBox key={option?.value ?? option?.label}>
                  {option?.label}
                </StyledTagBox>
              ));
            } else {
              return (
                <Box
                  sx={{
                    display: 'inline-flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                  }}>
                  <StyledTagBox key={value[0]?.value ?? value[0]?.label}>
                    {value[0]?.label}
                  </StyledTagBox>
                  <StyledEllipsisBox>...</StyledEllipsisBox>
                </Box>
              );
            }
          }
        }}
        clearIcon={clearIcon}
      />
    </Box>
  );
};

SearchableDropDownV2.defaultProps = {
  multiple: false,
  minWidth: 150,
  maxWidth: 400,
};

export default SearchableDropDownV2;
