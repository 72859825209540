import React, { useRef } from 'react';
import { Box, InputAdornment } from '@mui/material';
import CalenderIcon from '../../assets/Images/CalenderIcon.svg';
import {
  StyledArrow,
  StyledDatePicker,
  StyledTextField,
} from './Style/SingleDatePicker.style';

interface DatePickerProps {
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
  disableDate?: (date: Date) => boolean;
}

const DatePickers: React.FC<DatePickerProps> = ({
  selectedDate,
  onDateChange,
  disableDate,
}) => {
  const datePickerRef = useRef<{ open: () => void } | null>(null);

  return (
    <Box position="relative">
      <StyledDatePicker
        ref={datePickerRef}
        value={selectedDate}
        format="dd/MM/yyyy"
        oneTap
        cleanable={false}
        placement="bottomStart"
        shouldDisableDate={disableDate}
        onChange={onDateChange}
      />

      <StyledTextField
        fullWidth
        variant="outlined"
        placeholder="dd/MM/yyyy"
        value={selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''}
        onClick={() => datePickerRef.current?.open()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ margin: 'auto'}}>
              <img
                src={CalenderIcon}
                alt="Calendar Icon"
                width={21}
                height={21}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <StyledArrow 
                data-testid="date-picker-arrow"
                onClick={() => datePickerRef.current?.open()} 
              />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default DatePickers;
