import { useAuth0 as realUseAuth0 } from '@auth0/auth0-react';

const user = {
    sub: 'auth0|12345',
    nickname: 'testuser',
    name: 'Test User',
    picture: 'https://example.com/avatar.png',
    email: 'test@example.com',
    email_verified: true,
  };

const mockUseAuth0 = () => ({
  isAuthenticated: true,
  user,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loginWithRedirect: ()=>{},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: ()=>{},
  getAccessTokenSilently: ()=>('fake-access-token'),
  getIdTokenClaims: ()=>({ email: user.email, name: user.name }),
  isLoading: false,
  error: null,
});

export const useAuth0 = process.env.REACT_APP_MOCK_AUTH0 === 'true'
  ? mockUseAuth0
  : realUseAuth0;

export const Auth0Provider = ({ children }: { children: React.ReactNode }) => <>{children}</>;