import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button } from '../../components';
import Title from '../../components/Title/Title';
import { theme } from '../../styles/theme';
import TakedownMode from './TakedownMode';
import CompanyRelationship from './CompanyRelationship';
import Industries from './Industries';
import CompanyInformation from './CompanyInformation';
import Services from './Services';
import {
  Content,
  ContentWrapper,
  LayoutConatainer,
  MainContainer,
} from './Style/CompanyCreationV2.style';
import { OptionType } from '../../helpers/types';
import { hasCompanyV2Access, TAKEDOWN_MODE_ITEMS } from '../../utils';
import { StyledButton } from '../CompanyDetailsV2/style/CompanyDetailsV2.style';

const CompanyCreationV2 = () => {
  const navigate = useNavigate();
  const attackCostSavingValue = 200000;
  const userEmail = useSelector(
    (state: any) => state?.user.userData?.data?.Email,
  );
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [selectedTakedownMode, setSelectedTakedownMode] = useState<string[]>(['Manual']);

  const [selectedValues, setSelectedValues] = useState<{
    parent: OptionType | null; 
  }>({ parent: null });

  const [companyName, setCompanyName] = useState('');
  const [hubspotId, setHubspotId] = useState<number | null>(null);
  const [attackCostSavings, setAttackCostSavings] = useState<number | null>(
    null,
  );
  const [errors, setErrors] = React.useState({
    companyName: '',
    attackCostSavings: '',
    hubspotId: '',
  });

  const { getService, getCompany } = useSelector((state: any) => state.company);

  const isPreCrimeBrand = selectedServices?.some((item: any) => {
    return item?.includes('Precrime Brand');
  });

  const serviceData = useCallback(
    () =>
      getService?.data?.items?.map((service: { name: string; id: number }) => ({
        label: service.name,
        value: service.id.toString(),
        id: service.id,
      })) || [],
    [getService?.data?.items],
  );

  const parentFilterData = useMemo(() => {
    return (
      getCompany?.data?.map((item: { Name: string; Id: number }) => ({
        label: item.Name,
        id: item.Id,
      })) || []
    );
  }, [getCompany?.data]);

  useEffect(() => {
    const preCrimeService = serviceData().find(
      (service: { label: string }) =>
        service.label === 'Precrime Brand - POC - High Priority',
    );

    if (selectedServices.length === 0 && preCrimeService) {
      setSelectedServices([preCrimeService.label]);
    }

    if (isPreCrimeBrand && !attackCostSavings) {
      setAttackCostSavings(attackCostSavingValue); // TODO: It'll change when API Integrate
    }
  }, [selectedServices, serviceData, attackCostSavings, isPreCrimeBrand]);

   // TODO: Temporary: company access handled in frontend, to be removed before production.
    useEffect(() => {
      const hasPermission = hasCompanyV2Access(userEmail);
      if (!hasPermission) {
        navigate(`/companies`);
      }
    }, [userEmail]);

  const handleServicesChange = (newSelectedItems: string[]) => {
    setSelectedServices(newSelectedItems);
  };

  const handleIndustriesChange = (newSelectedItems: string[]) => {
    setSelectedIndustries(newSelectedItems);
  };

  const handleAutocompleteChange = (
    event: any,
    value: OptionType | null,
    name: string,
  ) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [name]: value || null,
    }));
  };

  const handleRemoveParentCompany = () => {
    setSelectedValues(prevState => ({ ...prevState, parent: null }));
  };

  const handleTakedownModeChange = (newSelectedItems: string[]) => {
    if (newSelectedItems === null) return;
    setSelectedTakedownMode(newSelectedItems);
  };

  const validateForm = () => {
    const newErrors = {
      companyName: '',
      attackCostSavings: '',
      hubspotId: '',
    };

    if (!companyName) {
      newErrors.companyName = 'Company name is required';
    }


    if (isPreCrimeBrand) {
      if (
        !attackCostSavings ||
        isNaN(Number(attackCostSavings)) ||
        Number(attackCostSavings) <= 0
      ) {
        newErrors.attackCostSavings =
          'Attack cost savings is mandatory when Precrime Brand service is selected';
      }
    } else {
      if (
        attackCostSavings &&
        (isNaN(Number(attackCostSavings)) || Number(attackCostSavings) <= 0)
      ) {
        newErrors.attackCostSavings =
          'Attack cost savings must be a number greater than 0';
      }
    }

    setErrors(newErrors);
    return Object.values(newErrors).every(error => error === '');
  };

  const handleBlur = (field: string) => {
    if (field === 'companyName' && !companyName) {
      setErrors(prevErrors => ({
        ...prevErrors,
        companyName: 'Company name is required',
      }));
    } else if (field === 'attackCostSavings') {
      if (isPreCrimeBrand) {
        if (
          !attackCostSavings ||
          isNaN(Number(attackCostSavings)) ||
          Number(attackCostSavings) <= 0
        ) {
          setErrors(prevErrors => ({
            ...prevErrors,
            attackCostSavings:
              'Attack cost savings is mandatory when PreCrime Brand service is selected',
          }));
        } else {
          setErrors(prevErrors => ({ ...prevErrors, attackCostSavings: '' }));
        }
      } else {
        if (
          attackCostSavings &&
          (isNaN(Number(attackCostSavings)) || Number(attackCostSavings) <= 0)
        ) {
          setErrors(prevErrors => ({
            ...prevErrors,
            attackCostSavings:
              'Attack cost savings must be a number greater than 0',
          }));
        } else {
          setErrors(prevErrors => ({ ...prevErrors, attackCostSavings: '' }));
        }
      }
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [field]: '' }));
    }
  };

  const handleFormSubmit = (e: any) => {
    if (validateForm()) {
      // TODO: Add API call for company creation
    }
  };

  return (
    <MainContainer>
      <Content>
        <ContentWrapper>
          <Button
            size="small"
            sx={StyledButton}
            onClick={() => navigate(-1)}
            data-test-id="company-back-button">
            <ChevronLeftIcon sx={{ color: theme.palette.primary.dark }} />
          </Button>
          <Title size="small" title="Company creation" />
        </ContentWrapper>
      </Content>
      <LayoutConatainer>
        <Services
          selectedServices={selectedServices}
          handleServicesChange={handleServicesChange}
          serviceData={serviceData()}
          selectedDate={selectedDate}
          handleDateChange={setSelectedDate}
        />
        <TakedownMode
          takedownItems={TAKEDOWN_MODE_ITEMS}
          selectedTakedownMode={selectedTakedownMode}
          handleTakedownModeChange={handleTakedownModeChange}
        />
      </LayoutConatainer>
      <LayoutConatainer>
        <Industries
          selectedIndustries={selectedIndustries}
          handleIndustriesChange={handleIndustriesChange}
        />
        <CompanyRelationship
          selectedValues={selectedValues}
          handleAutocompleteChange={handleAutocompleteChange}
          handleRemoveParentCompany={handleRemoveParentCompany}
          dropdown={parentFilterData}
        />
      </LayoutConatainer>
      <LayoutConatainer sx={{ flexDirection: 'column' }}>
        <CompanyInformation
          companyName={companyName}
          setCompanyName={setCompanyName}
          hubspotId={hubspotId}
          setHubspotId={setHubspotId}
          attackCostSavings={attackCostSavings}
          setAttackCostSavings={setAttackCostSavings}
          errors={errors}
          handleBlur={handleBlur}
          handleSubmit={handleFormSubmit}
          isPreCrimeBrand={isPreCrimeBrand}
        />
      </LayoutConatainer>
    </MainContainer>
  );
};

export default CompanyCreationV2;
