import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Box, Paper, Skeleton, Typography } from '@mui/material';
import {  useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GradientButton, Loader } from '../../components';
import Avtar from '../../assets/Images/NoCompanyLogo.png';
import CompanyDetailsTabs from './CompanyDetailsTabs';
import { getCompanyDetailAction } from '../../store/company/action';
import ContactsIcon from '../../assets/Images/ContactsIcon.svg';
import AddressIcon from '../../assets/Images/AddressIcon.svg';
import EmailIcon from '../../assets/Images/EmailIcon.svg';
import {
  ServiceLabel,
  CompanyNameWrapper,
  LogoStyle,
  PaperStyle,
  ThreatNumberStyle,
  ThreatNumberWrapper,
  OverviewLabel,
} from './Style/CompanyDetails.style';
import AddChildModal from './AddChildModal';
import { renderSkeleton } from '../../utils';

const CompanyDetails = () => {
  const [openAddChildModal, setOpenAddChildModal] = useState<boolean>(false);
  const [domainCurrentPage, setDomainCurrentPage] = useState<number>(1);
  const [searchDomainName, setSearchDomainName] = useState<string>('');

  const dispatch = useDispatch();
  const { id: companyId } = useParams();

  const { getCompanyDetail } = useSelector((state: any) => state.company);
  const companyDetail = getCompanyDetail.data;
  const domainCount = Math.ceil(companyDetail?.Total / 10);

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetailAction({ id: companyId }));
    }
  }, [companyId]);

  const handleOpenAddChild = () => {
    setOpenAddChildModal(true);
  };

  const handleCloseAddChild = () => {
    setOpenAddChildModal(false);
  };

  const handleChangeDomainPage = (event: any, value: number) => {
    setDomainCurrentPage(value);
  };

  const handleChangeDomainName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchDomainName(e.target.value);
  };


  const uniqueCompanyService = useMemo(() => {
    const data = companyDetail.Services?.filter((service: { Id: number; }, index: any, self: any[]) =>
      index === self.findIndex((obj: { Id: number; }) => obj.Id === service.Id)
    );
    return data
  }, [companyDetail.Services])

  return (
    <>
      <Box style={{ padding: '24px', backgroundColor: '#f6f7f87f' }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'black' }}>
          Companies Overview
        </Typography>
        <Paper elevation={1} sx={PaperStyle}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', gap: '20px' }}>
              <img
                src={companyDetail.Logo ? companyDetail.Logo : Avtar}
                alt="logo"
                style={LogoStyle}
              />
              <Box>
                <CompanyNameWrapper>
                  {getCompanyDetail?.loading ? (
                    renderSkeleton('40%')
                  ) : (
                    <Typography variant="h6" fontWeight={600}>
                      {companyDetail?.Name}
                    </Typography>
                  )}

                  {getCompanyDetail?.loading ? (
                    renderSkeleton('100%')
                  ) : uniqueCompanyService?.length ? (
                    uniqueCompanyService?.map(
                      ({ Name, Id }: { Name: string; Id: number }) => (
                        <ServiceLabel variant="body2" key={Id}>
                          {Name}
                        </ServiceLabel>
                      )
                    )
                  ) : (
                    <ServiceLabel variant="body2">NAN</ServiceLabel>
                  )}
                </CompanyNameWrapper>
                <div style={{ display: 'flex' }}>
                  <OverviewLabel variant="body2">
                    <img src={ContactsIcon} alt="" />
                    {companyDetail.Users?.length} {'Contacts'}
                  </OverviewLabel>
                  <OverviewLabel variant="body2">
                    <img src={EmailIcon} alt="" />
                    {companyDetail?.Address || 'No Addresss'}
                  </OverviewLabel>
                  <OverviewLabel variant="body2">
                    <img src={AddressIcon} alt="" />
                    {companyDetail?.Email || 'No Email'}
                  </OverviewLabel>
                </div>
                <ThreatNumberWrapper>
                  <ThreatNumberStyle>
                    {getCompanyDetail?.loading ? (
                      renderSkeleton()
                    ) : (
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {companyDetail?.Domains?.length}
                      </Typography>
                    )}
                    <Typography variant="body2">Protected Domains</Typography>
                  </ThreatNumberStyle>
                </ThreatNumberWrapper>
              </Box>
            </Box>
            <GradientButton
              data-testid="add-child-entity-button"
              variant="contained"
              onClick={handleOpenAddChild}>
              Add Child Entity
            </GradientButton>
          </Box>
        </Paper>
        <CompanyDetailsTabs
          handleChangeDomainPage={handleChangeDomainPage}
          domainCurrentPage={domainCurrentPage}
          domainCount={domainCount}
          searchDomainName={searchDomainName}
          handleChangeDomainName={handleChangeDomainName}
        />
      </Box>
      <AddChildModal
        handleClose={handleCloseAddChild}
        open={openAddChildModal}
      />
    </>
  );
};

export default CompanyDetails;
