import {
  Box,
  styled,
  TableContainer,
  TextField,
  Typography,
} from '@mui/material';
import { Button } from '../../../components';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

export const InfoTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '1rem',
  marginBottom: '2px',
  fontStyle: 'italic',
  color: '#667085',
  marginTop: 0,
}));

export const InfoBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  left: 0,
  width: '100%',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: 200,
  overflowY: 'auto',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
