import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '../__mocks__/@auth0/auth0-react';


const PublicRoute = ({ element }: any) => {
  const { isAuthenticated } = useAuth0();

  return !isAuthenticated ? element : <Navigate to="/dashboard" />;
};

export default PublicRoute;
