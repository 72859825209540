import React, { useCallback, useEffect } from 'react';
import { Box, Typography, Paper, TableContainer } from '@mui/material';
import DNSRecordsTab from './ThreadsMoreDetails/DNSRecordsTab';
import SSLCertificateTab from './ThreadsMoreDetails/SSLCertificateTab';
import WhoisTab from './ThreadsMoreDetails/WhoisTab';
import SubDomainsTab from './ThreadsMoreDetails/SubDomainsTab ';
import {
  HorizontalLine,
  StyledHeader,
  SubTitleWrapper,
  SyncIconStyle,
  TabsLoaderWrapper,
  WhoisLoaderWrapper,
} from './Style/SingleThreat.style';
import { Loader } from '../../components';
import RefreshButton from '../../components/RefreshButton/RefreshButton';
import { useSingleThreatContext } from '../../context/SingleThreat/SingleThreatContext';
import { SingleThreatDetailsType } from '../../context/SingleThreat/SingleThreatType';
import { NETWORK_DATA_TYPE } from '../../utils';
import { NoRecordFound } from '../../components/NoRecordFound/NoRecordFound';
import Title from '../../components/Title/Title';
const threatDetailLoader = () => {
  return (
    <TabsLoaderWrapper>
      <Loader size={25} />
    </TabsLoaderWrapper>
  );
};

const whoisLoader = () => {
  return (
    <WhoisLoaderWrapper>
      <Loader size={25} />
    </WhoisLoaderWrapper>
  );
};

const DomainInformation = ({
  singleThreat,
}: {
  singleThreat: SingleThreatDetailsType | undefined;
}) => {
  const {
    subDomainsData,
    getSubDomains,
    subDomainsLoading,
    getWhoisRecords,
    whoisData,
    whoisDataLoading,
    getSSLRecords,
    sslData,
    sslDataLoading,
    getDnsRecords,
    dnsLoading,
    dnsData,
  } = useSingleThreatContext();

  const getWhoisData = useCallback(() => {
    if (singleThreat?.threat_id) {
      const params = {
        asset_id: singleThreat?.threat_id,
        type: NETWORK_DATA_TYPE.WHOIS,
      };
      getWhoisRecords(params);
    }
  }, [singleThreat?.threat_id]);

  const getSSLData = useCallback(() => {
    if (singleThreat?.threat_id) {
      const params = {
        asset_id: singleThreat?.threat_id,
        type: NETWORK_DATA_TYPE.CERTIFICATE,
      };
      getSSLRecords(params);
    }
  }, [singleThreat?.threat_id]);

  const getDNSData = useCallback(() => {
    if (singleThreat?.threat_id) {
      const params = {
        asset_id: singleThreat?.threat_id,
        type: NETWORK_DATA_TYPE.RECORDS,
      };
      getDnsRecords(params);
    }
  }, [singleThreat?.threat_id]);

  useEffect(() => {
    if (singleThreat?.threat_id) {
      getSubDomains(singleThreat?.threat_id);
      getWhoisData();
      getSSLData();
      getDNSData();
    }
  }, [singleThreat?.threat_id]);

  const handleRefreshDomain = (type: NETWORK_DATA_TYPE.WHOIS | NETWORK_DATA_TYPE.CERTIFICATE | NETWORK_DATA_TYPE.RECORDS) => {
    if (type === NETWORK_DATA_TYPE.WHOIS) {
      getWhoisData();
    }
    if (type === NETWORK_DATA_TYPE.CERTIFICATE) {
      getSSLData();
    }
    if (type === NETWORK_DATA_TYPE.RECORDS) {
      getDNSData();
    }
  };

  const renderContent = () => {
    if (subDomainsLoading) {
      return threatDetailLoader(); // Show loading indicator if data is loading
    } 
    if(subDomainsData?.length > 0) {
      return <SubDomainsTab subDomains={subDomainsData} />; // Render subdomains if data is available
    } 
    return <NoRecordFound />; // Display 'No records found' if no data is available
  }
  return (
    <>
      <StyledHeader>
        <Title size={'large'} title="Domain Information" />
      </StyledHeader>
      <HorizontalLine />
      <Box sx={{ mt: 1 }}>
        <SubTitleWrapper>
          <Typography variant="subtitle1">SSL Certificate</Typography>
          <RefreshButton
            handleSyncIconClick={() => handleRefreshDomain(NETWORK_DATA_TYPE.CERTIFICATE)}
            isDisabled={sslDataLoading}
            SyncIconStyle={SyncIconStyle}
          />
        </SubTitleWrapper>
        <SSLCertificateTab
          sslCertificate={sslData}
          sslDataLoading={sslDataLoading}
          threatDetailLoader={threatDetailLoader}
        />
      </Box>
      <Box sx={{mt: 1.5 }}>
        <SubTitleWrapper>
          <Typography variant="subtitle1">Whois</Typography>
          <RefreshButton
            handleSyncIconClick={() => handleRefreshDomain(NETWORK_DATA_TYPE.WHOIS)}
            isDisabled={whoisDataLoading}
            SyncIconStyle={SyncIconStyle}
          />
        </SubTitleWrapper>
        {whoisDataLoading ? (
          whoisLoader()
        ) : (
          <WhoisTab whois={whoisData?.items?.[0]} />
        )}
      </Box>
      <Box sx={{ mt: 2 }}>
        <SubTitleWrapper>
          <Typography variant="subtitle1" sx={{ color: 'black' }}>
            Records
          </Typography>
          <RefreshButton
            handleSyncIconClick={() => handleRefreshDomain(NETWORK_DATA_TYPE.RECORDS)}
            isDisabled={dnsLoading}
            SyncIconStyle={SyncIconStyle}
          />
        </SubTitleWrapper>
        <DNSRecordsTab dnsRecord={dnsData?.items?.[0]?.data} threatDetailLoader={threatDetailLoader} loading={dnsLoading}/>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" sx={{ color: 'black' }}>
          Subdomains
        </Typography>
        <TableContainer sx={{ mt: 0.5, p: 2, width: 'auto' }} component={Paper}>
          {renderContent()}
        </TableContainer>
      </Box>
    </>
  );
};

export default DomainInformation;
