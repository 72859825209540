import { createTheme } from '@mui/material/styles';

export const DRAWER_WIDTH = 210;
// Extend theme to include custom colors
declare module '@mui/material/styles' {
  interface Palette {
    border?: Palette['primary'];
  }
  interface PaletteOptions {
    border?: PaletteOptions['primary'];
  }
  interface TypeText {
    tertiary?: string;
    button?: string;
    inputPlaceholder?: string
  }
  interface TypeBackground { 
    tertiary?: string;
    tooltipBackground?: string;
  }
}

// Color palette
export const colors = {
  primary: {
    main: '#18cdcd',
    dark: '#018184'
  },
  secondary: {
    main: '#885EC0',
  },
  error: {
    main: '#f44336',
  },
  warning: {
    main: '#F94D4D',
  },
  info: {
    main: '#2196f3',
  },
  success: {
    main: '#5AA311',
  },
  background: {
    default: '#F9FAFB',
    paper: '#ffffff',
    tertiary: '#F2F4F7',
    tooltipBackground: '#344054'
  } as const,
  text: {
    primary: '#0C111D',
    secondary: '#666666',
    tertiary: '#667085',
    disabled: '#aaa',
    button: '#042B2F',
    inputPlaceholder: '#98A2B3'
  },
  divider: '#e2e2e2',
  icons: {
    primary: '#0C111D'
  },
  border: {
    main: '#E4E7EC'
  }
};

export const theme = createTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  palette: colors,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
});
