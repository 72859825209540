import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TableHeaderCell, TakedownTable } from '../Style/SingleThreat.style';
import { formatedDate, SSLCertificateHeader } from '../../../utils';
import { NoRecordFound } from '../../../components/NoRecordFound/NoRecordFound';
import { NoNetworksDataFound } from './NoNetworksDataFound';

const SSLCertificateTab = ({ sslCertificate, sslDataLoading, threatDetailLoader}: any) => {
  const sslData = sslCertificate?.items?.[0]?.data;

  const renderSSLDataContent = () => {
    if (sslDataLoading) {
      // Show loading indicator if data is being fetched
      return (
        <TableRow>
          <TableCell colSpan={SSLCertificateHeader.length} align="center" sx={{ border: 0 }}>
            {threatDetailLoader()}
          </TableCell>
        </TableRow>
      );
    }
  
    if (!sslCertificate || sslCertificate?.items?.length === 0) {
      // Display 'No records found' if there are no SSL certificate items
      return (
        <TableRow>
          <TableCell colSpan={SSLCertificateHeader.length} align="center" sx={{ border: 0 }}>
            <NoRecordFound />
          </TableCell>
        </TableRow>
      );
    }
  
    if (sslCertificate?.items?.length > 0 && sslData) {
      // Render SSL certificate data if available
      return (
        <TableRow>
          <TableCell>{sslCertificate?.items?.[0]?.asset_name || <NoNetworksDataFound />}</TableCell>
          <TableCell>{sslData?.issuer || <NoNetworksDataFound />}</TableCell>
          <TableCell>{sslData?.organisation || <NoNetworksDataFound />}</TableCell>
          <TableCell>
            {sslData?.not_before ? formatedDate(sslData?.not_before) : <NoNetworksDataFound />}
          </TableCell>
          <TableCell>
            {sslData?.not_after ? formatedDate(sslData?.not_after) : <NoNetworksDataFound />}
          </TableCell>
        </TableRow>
      );
    }
  
    // Return null if no conditions match (this line is just a fallback)
    return null;
  }

  return (
    <TakedownTable>
      <TableContainer
        data-testid="ssl-certificate-table"
        sx={{ maxHeight: 400 }}>
        <Table stickyHeader size="small" sx={{ border: 0 }}>
          <TableHead>
            <TableRow>
              {SSLCertificateHeader.map((item, index) => (
                <TableHeaderCell key={index} align="left">
                  {item}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderSSLDataContent()}
          </TableBody>
        </Table>
      </TableContainer>
    </TakedownTable>
  );
};

export default SSLCertificateTab;
